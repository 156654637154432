import React, { useMemo, useCallback, useRef, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { DNABox, DNAButton, DNAIcon, Tabs, luxColors, util } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useAppSettings } from 'src/state/context/AppSettings';
import { usePresentationSelector } from './PresentationSelectorStateProvider';
import InputComponent from 'src/components/Publishers/InputComponent';
import debounce from 'lodash/debounce';
import { Input } from '@ui-kitten/components';
import { useCanPerformSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';

type TabTitle = 'Bookmarks' | 'My Folders' | 'Library' | 'My Uploads' | 'Search results';

interface TabProps {
  isSelected: boolean;
  option: {
    title: TabTitle,
  }
  onTabSelected: (tab: string) => void,
}

interface CloseIconProps {
  localSearchText: string;
  onSearchTextChange: (text: string) => void;
  inputRef: React.RefObject<Input>;
}

interface HeaderLogicReturn {
  localSearchText: string;
  selectedTab: string;
  filteredTabs: TabProps['option'][];
  inputRef: React.RefObject<Input>;
  tabSelectedBeforeSearch: React.MutableRefObject<string>;
  handleTabSelect: (tab: TabTitle) => void;
  onSearchTextChange: (text: string) => void;
  MemoizedTabs: JSX.Element;
  setLocalSearchText: (text: string) => void;
  setSearchText: (text: string) => void;
  setSelectedTab: (tab: string) => void;
}

const desktopStyles = StyleSheet.create({
  searchBarContainerStyle: {
    minWidth: 80,
    marginHorizontal: 16,
    flex: 1,
  },
  searchBarStyle: {
    backgroundColor: luxColors.info.primary,
    borderColor: luxColors.disabled.secondary,
    maxWidth: 450,
    color: colors['gray-300'],
    borderRadius: 4,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  headerTabsWrapper: {
    marginTop: '10px',
  },
  headerWrapper: {
    height: 56,
    paddingLeft: 64,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
});

const tabletStyles = StyleSheet.create({
  searchBarContainerExpanded: {
    flex: 1,
    backgroundColor: colors['color-text-white'],
    zIndex: 1,
    paddingLeft: 8,
    paddingRight: 8,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  backButton: {
    marginRight: 8,
    alignSelf: 'center',
    height: 48,
    minHeight: 48,
    justifyContent: 'center',
  },
  headerWrapper: {
    height: 56,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    paddingRight: 8,
    paddingHorizontal: 8,
  },
  headerTabsWrapper: {
    marginTop: '10px',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchIconContainer: {
    padding: 8,
    marginRight: 16,
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBarStyle: {
    ...desktopStyles.searchBarStyle,
    flex: 1,
    width: '100%',
    maxWidth: 'none',
  },
});

const tabs: TabProps['option'][] = [
  { title: 'Bookmarks' },
  { title: 'My Folders' },
  { title: 'Library' },
  { title: 'My Uploads' },
];

const SEARCH_RESULTS_TAB: TabTitle = 'Search results';
const SEARCH_PLACEHOLDER = 'Search files...';
const DEBOUNCE_DELAY = 1000;
const FOCUS_DELAY = 100;

const MagnifyingIcon:React.FC = () => {
  return (
    <TouchableOpacity disabled>
      <DNAIcon.Styled name="magnify" appearance="ghost" status="tertiary" />
    </TouchableOpacity>
  );
}

const CloseIcon: React.FC<CloseIconProps> = ({ localSearchText, onSearchTextChange, inputRef }) => {
  const handleClear = useCallback(() => {
    onSearchTextChange('');
    inputRef.current?.focus();
  }, [onSearchTextChange, inputRef]);

  if (!localSearchText) return null;

  return (
    <TouchableOpacity onPress={handleClear}>
      <DNAIcon.Styled name="close" appearance="ghost" status="tertiary" />
    </TouchableOpacity>
  );
};

/**
 * Custom hook that manages the header logic for the Presentation Selector component
 *
 * @returns {Object} An object containing:
 * - localSearchText: Current search text value
 * - selectedTab: Currently selected tab
 * - filteredTabs: Array of tabs filtered based on feature flags
 * - inputRef: Reference to the search input component
 * - tabSelectedBeforeSearch: Reference to store the last selected tab before search
 * - handleTabSelect: Function to handle tab selection
 * - onSearchTextChange: Function to handle search text changes
 * - MemoizedTabs: Memoized Tabs component
 * - setLocalSearchText: Function to update local search text
 * - setSearchText: Function to update global search text
 * - setSelectedTab: Function to update selected tab
 */
const useHeaderLogic = (): HeaderLogicReturn => {
  const {
    localSearchText,
    selectedTab,
    setLocalSearchText,
    setSelectedTab,
    setSearchText,
  } = usePresentationSelector();

  const enableMyUploads = useFeatureFlags('enableMyUploads');
  const filteredTabs = useMemo(() =>
    enableMyUploads ? tabs : tabs.filter((item) => item.title !== 'My Uploads'),
  [enableMyUploads],
  );

  const canPerformSearch = useCanPerformSearch();
  // Used to return to that tab if user clears the search input
  const tabSelectedBeforeSearch = useRef(tabs[0].title);
  const inputRef = useRef<Input>(null);

  const handleTabSelect = (tab: TabTitle) => {
    setLocalSearchText('');
    setSearchText('');
    setSelectedTab(tab);
  };

  const debouncedSearchText = useCallback(
    debounce((text: string) => {
      setSearchText(text)
    }, DEBOUNCE_DELAY),
    [setSearchText],
  );

  const onSearchTextChange = (text: string) => {
    const canTextBeSearchable = canPerformSearch(text);
    setLocalSearchText(text);

    if (text) {
      debouncedSearchText(text);
    }

    if (canTextBeSearchable && selectedTab && selectedTab !== SEARCH_RESULTS_TAB) {
      tabSelectedBeforeSearch.current = selectedTab as TabTitle;
      setSelectedTab(SEARCH_RESULTS_TAB);
    } else if (!canTextBeSearchable && selectedTab === SEARCH_RESULTS_TAB) {
      setSelectedTab(tabSelectedBeforeSearch.current as TabTitle);
    }
  };

  const MemoizedTabs = useMemo(() => (
    <Tabs
      selectedTab={selectedTab}
      onSelectTab={(tab: string) => handleTabSelect(tab as TabTitle)}
      tabs={filteredTabs}
      selectedTabVariant="primary"
    />
  ), [selectedTab]);

  return {
    localSearchText,
    selectedTab,
    filteredTabs,
    inputRef,
    tabSelectedBeforeSearch,
    handleTabSelect,
    onSearchTextChange,
    MemoizedTabs,
    setLocalSearchText,
    setSearchText,
    setSelectedTab,
  };
};

const HeaderDesktop: React.FC = () => {
  const {
    localSearchText,
    inputRef,
    onSearchTextChange,
    MemoizedTabs,
  } = useHeaderLogic();

  // Memoized Components
  const MemoizedMagnifyingIcon = useMemo(() => MagnifyingIcon, []);

  return (
    <DNABox
      alignY="center"
      style={util.mergeStyles(
        undefined,
        desktopStyles.headerWrapper,
      )}
    >
      <DNABox testID="tab-header" style={desktopStyles.headerTabsWrapper}>
        {MemoizedTabs}
      </DNABox>
      <DNABox fill style={desktopStyles.searchBarContainerStyle}>
        <InputComponent
          testID="search-build-presentation"
          ref={inputRef}
          getRightIconFunction={() => (
            <CloseIcon
              localSearchText={localSearchText}
              onSearchTextChange={onSearchTextChange}
              inputRef={inputRef}
            />
          )}
          getLeftIconFunction={() => <MemoizedMagnifyingIcon/>}
          hideLabel={true}
          inputStyle={desktopStyles.searchBarStyle}
          onChangeText={onSearchTextChange}
          placeholderTextColor={luxColors.contentPanelBackground.quinary}
          removeMarginPadding={true}
          title={SEARCH_PLACEHOLDER}
          value={localSearchText}
        />
      </DNABox>
    </DNABox>
  );
}

const HeaderTablet: React.FC = () => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const {
    localSearchText,
    inputRef,
    onSearchTextChange,
    MemoizedTabs,
    tabSelectedBeforeSearch,
    setLocalSearchText,
    setSearchText,
    setSelectedTab,
  } = useHeaderLogic();

  const toggleSearch = () => {
    setIsSearchExpanded(!isSearchExpanded);
    if (!isSearchExpanded) {
      // Focus input when expanding
      setTimeout(() => inputRef.current?.focus(), FOCUS_DELAY);
    } else {
      // Clear search when collapsing
      setLocalSearchText('');
      setSearchText('');
      setSelectedTab(tabSelectedBeforeSearch.current);
    }
  };

  const SearchButton = useMemo(() => () => (
    <DNAButton
      onPress={toggleSearch}
      appearance="outline"
      status="tertiary"
      size="md"
      iconLeft="magnify"
      style={tabletStyles.searchIconContainer}
    />
  ), [toggleSearch]);

  return (
    <DNABox
      alignY="center"
      style={tabletStyles.headerWrapper}
    >
      {!isSearchExpanded ? (
        <DNABox testID="tab-header" style={tabletStyles.headerTabsWrapper}>
          {MemoizedTabs}
          <SearchButton />
        </DNABox>
      ) : (
        <DNABox fill appearance="row" childFill={1} testID="search-tablet-bar-container">
          <DNAButton
            onPress={toggleSearch}
            appearance="outline"
            status="tertiary"
            size="sm"
            iconLeft="chevron-left"
            style={tabletStyles.backButton}
          />
          <DNABox fill testID="search-tablet-input-container">
            <InputComponent
              testID="search-build-presentation"
              ref={inputRef}
              getRightIconFunction={() => (
                <CloseIcon
                  localSearchText={localSearchText}
                  onSearchTextChange={onSearchTextChange}
                  inputRef={inputRef}
                />
              )}
              getLeftIconFunction={() => (
                <DNAIcon.Styled
                  name="magnify"
                  size="md"
                  appearance="ghost"
                  status="gray"
                />
              )}
              hideLabel={true}
              inputStyle={[desktopStyles.searchBarStyle, tabletStyles.searchBarStyle]}
              onChangeText={onSearchTextChange}
              placeholderTextColor={luxColors.contentPanelBackground.quinary}
              removeMarginPadding={true}
              title="Search files..."
              value={localSearchText}
            />
          </DNABox>
        </DNABox>
      )}
    </DNABox>
  );
}

const Header: React.FC = () => {
  const { deviceMode } = useAppSettings()
  return deviceMode === 'desktop' ? <HeaderDesktop/> : <HeaderTablet/>
}

export default Header
