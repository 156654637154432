import React from 'react'
import { useDispatch } from 'src/state/redux'

import { DNABox, DNAModal, DNAText } from '@alucio/lux-ui'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { DocumentVersionORM, FolderItemORM, VERSION_UPDATE_STATUS } from 'src/types/types'
import { isCustomDeckORM, isDocumentVersionORM } from 'src/types/typeguards'
import { customDeckActions } from 'src/state/redux/slice/customDeck'
import { isSharedEditableFolder } from 'src/utils/foldersHelpers'
import { UpdateType, sharedFolderActions } from 'src/state/redux/slice/sharedFolder'
import { upgradeFolderDocuments } from 'src/state/redux/saga'

export const DNADocumentVersionUpdateModal: DNAModalVariant<{
  folderItemORM: FolderItemORM,
}> = (props) => {
  const { folderItemORM, toggleModal } = props
  let documentVersionORM: DocumentVersionORM | undefined

  if (isCustomDeckORM(folderItemORM.relations.itemORM)) {
    const customDeckORM = folderItemORM.relations.itemORM
    const updatedGroup = customDeckORM.meta.customDeckGroups.find(groupORM =>
      groupORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MINOR,
    )
    if (updatedGroup) {
      documentVersionORM = updatedGroup.pages[0].documentVersionORM
    }
  }
  else if (isDocumentVersionORM(folderItemORM.relations.itemORM)) {
    documentVersionORM = folderItemORM.relations.itemORM
  }
  const dispatch = useDispatch()

  const onAction = () => {
    if (isCustomDeckORM(folderItemORM.relations.itemORM)) {
      // [NOTE] - We toggle the modal to have compatibility with the debug modal
      //        - Otherwise the toggle will remove the debug modal immediately
      // [TODO] - Better control the DNAModal to not blindly pop items off the top of the component stack
      //          but rather to pop by a given ID that should be assigned to each item in the array instead
      toggleModal()
      dispatch(customDeckActions.upgradeCustomDecks({ customDeckORMs: [folderItemORM.relations.itemORM] }))
    }
    else {
      const isSharedEditFolder = folderItemORM.relations.parentORM &&
       isSharedEditableFolder(folderItemORM.relations.parentORM)

      const isSharedFolderAction = (
        isSharedEditFolder &&
        folderItemORM.relations.parentORM
      )

      if (isSharedFolderAction) {
        toggleModal()
        dispatch(sharedFolderActions.updateItems({
          folder: folderItemORM.relations.parentORM!,
          folderItems: [folderItemORM],
          action: UpdateType.UPDATE_DOCUMENT_VERSION,
        }))
      }
      else {
        toggleModal()
        upgradeFolderDocuments(
          dispatch,
          {
            folderItemId: folderItemORM.model.id,
            skipGracePeriod: true
          }
        )
      }
    }
  }

  return (
    <DNAModal>
      <DNAModal.Header>Update File</DNAModal.Header>
      <DNAModal.Body>
        <DNABox appearance="col" spacing="md">
          <DNAText bold>
            {documentVersionORM?.model.title}
          </DNAText>
          <DNABox style={{ maxWidth: 560 }} appearance="col" spacing="sm">
            <DNAText>RELEASE NOTES</DNAText>
            <DNAText>
              {/* eslint-disable-next-line max-len */}
              {documentVersionORM?.relations.documentORM.relations.version.latestPublishedDocumentVersionORM?.model.releaseNotes}
            </DNAText>
          </DNABox>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation
        onCancel={toggleModal}
        onAction={onAction}
        actionLabel="Update"
        actionStatus="success"
      />
    </DNAModal>
  )
}

export default DNADocumentVersionUpdateModal
