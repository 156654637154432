import { createMachine, spawn } from 'xstate';
import { assign } from '@xstate/immer';
import { HubSectionItemStatus } from '@alucio/aws-beacon-amplify/src/models';
import { NOTATION_TYPE } from '@alucio/aws-beacon-amplify/src/API';
import type * as CustomNote from './customNote.types';
import { StateTags } from './customNote.types';
import {
  HIGHLIGHT_AVAILABLE_DOC_TYPE,
  TEXT_INSERTION_AVAILABLE_DOC_TYPE,
  presentationStateObserver,
} from './customNoteUtils';
import * as logger from 'src/utils/logger'

export const customNoteSM = createMachine(
  {
    id: 'CustomNoteSM',
    tsTypes: {} as import('./customNote.machine.typegen').Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
    schema: {
      context: {} as CustomNote.SMContext,
      events: {} as CustomNote.SMEvents,
      services: {} as CustomNote.SMServices,
    },
    context: {
      presentableState: undefined,
      presentationStateChannel: undefined!,
      currentActiveNotationId: undefined,
      meetingId: 'withConfigMe',
      notationDraft: undefined,
      isReadOnlyMode: undefined!,
      isTextInsertion: false,
    },
    initial: 'syncPresentable',
    entry: 'spawnPresentationStateObserver',
    states: {
      syncPresentable: {
        description: 'Sync presentable for the first time',
        tags: [StateTags.INITIALIZING],
        entry: 'syncPresentable',
        on: {
          PRESENTATION_STATE_SYNC: {
            actions: 'setPresentableState',
          },
          IFRAME_READY: {
            target: 'idle',
          },
        },
      },
      idle: {
        description: 'Waiting for user actions',
        entry: 'toggleNotations',
        on: {
          PRESENTATION_STATE_SYNC: {
            actions: 'setPresentableState',
          },
          IFRAME_READY: {
            actions: 'toggleNotations',
          },
          ENTER_SET_COORDINATE_MODE: {
            description: 'User click on "Note with highlight" button',
            cond: 'canAddHighlight',
            actions: 'setCurrentActiveNotationId',
            target: 'setCoordinateMode',
          },
          CREATE_NOTE: {
            description: 'User click on "Note" button',
            cond: 'canPerformCRUD',
            actions: ['setCurrentActiveNotationId', 'createNotationDraft'],
            target: 'creatingNote',
          },
          EDIT_NOTE: {
            description: 'User click on "Edit" option in context menu',
            cond: 'canPerformCRUD',
            actions: ['setCurrentActiveNotationId', 'createNotationDraft'],
            target: 'editingNote',
          },
          DELETE_NOTE: {
            actions: 'createNotationDraft',
            cond: 'canPerformCRUD',
            target: 'deletingNote',
          },
          SET_CURRENT_ACTIVE_NOTATION: {
            actions: 'setCurrentActiveNotationId',
          },
        },
      },
      setCoordinateMode: {
        description: 'Waiting for user to set the coordinate',
        entry: 'toggleOnCoordinateMode',
        on: {
          SET_COORDINATE: {
            description: 'When user click on playerWrapper',
            actions: 'createNotationDraft',
            target: 'creatingNote',
          },
          BACK_TO_IDLE: {
            target: 'idle',
          },
        },
        exit: 'toggleOffCoordinateMode',
      },
      creatingNote: {
        description: 'Waiting for user to add description to note',
        tags: [StateTags.NEW_NOTE],
        on: {
          PRESENTATION_STATE_SYNC: {
            actions: 'setPresentableState',
          },
          BACK_TO_IDLE: {
            actions: 'clearNotationDraft',
            target: 'idle',
          },
          SAVE_NOTE: {
            actions: 'addDescription',
            target: 'savingToUserNotations',
          },
          DELETE_NOTE: {
            actions: 'createNotationDraft',
            cond: 'canPerformCRUD',
            target: 'deletingNote',
          },
        },
      },
      editingNote: {
        description: 'User editing existing note',
        on: {
          PRESENTATION_STATE_SYNC: {
            actions: 'setPresentableState',
          },
          BACK_TO_IDLE: {
            actions: ['clearNotationDraft', 'setCurrentActiveNotationId'],
            target: 'idle',
          },
          SAVE_NOTE: {
            actions: ['addDescription', 'setCurrentActiveNotationId'],
            target: 'savingToUserNotations',
          },
          DELETE_NOTE: {
            actions: 'createNotationDraft',
            cond: 'canPerformCRUD',
            target: 'deletingNote',
          },
        },
      },
      savingToUserNotations: {
        description: 'Save note to data store',
        on: {
          RECORD_SAVED: {
            actions: 'clearNotationDraft',
            target: 'idle',
          },
        },
      },
      deletingNote: {
        description: 'Delete note from data store',
        on: {
          RECORD_SAVED: {
            actions: 'clearNotationDraft',
            target: 'idle',
          },
        },
      },
    },
    on: {
      SYNC_MACHINE_INFO: {
        actions: 'setMachineInfo',
      },
    },
  },
  {
    guards: {
      canAddHighlight: (ctx) => {
        if (!ctx.presentableState || !!ctx.isReadOnlyMode) return false
        else { return ctx.isTextInsertion ? TEXT_INSERTION_AVAILABLE_DOC_TYPE.includes(ctx.presentableState.contentType)
          : HIGHLIGHT_AVAILABLE_DOC_TYPE.includes(ctx.presentableState.contentType) }
      },
      canPerformCRUD: (ctx) => !ctx.isReadOnlyMode,
    },
    actions: {
      spawnPresentationStateObserver: assign((ctx) => {
        logger.userNotations.info(`Entering custom note state machine. 
          Initialize presentation state observer, meetingId: ${ctx.meetingId}`)

        if (!ctx.presentationStateObserver) {
          const actor = spawn(
            presentationStateObserver(ctx.presentationStateChannel, ctx.meetingId),
            'presentationStateObserverActor',
          )
          ctx.presentationStateObserver = actor
        }
      }),
      syncPresentable: assign((ctx) => {
        logger.userNotations
          .debug(`Sending SYNC_PRESENTABLE signal to presentationStateChannel, meetingId: ${ctx.meetingId}`)
        ctx.presentationStateChannel.postMessage({
          type: 'SYNC_PRESENTABLE',
          meetingId: ctx.meetingId,
        })
      }),
      toggleNotations: assign((ctx) => {
        logger.userNotations.debug(`Toggle on notations, meetingId: ${ctx.meetingId}`)
        ctx.presentationStateChannel.postMessage({
          type: 'TOGGLE_NOTATIONS',
          meetingId: ctx.meetingId,
          notationType: ctx.isTextInsertion ? NOTATION_TYPE.TEXT_INSERTION : NOTATION_TYPE.CALLOUT,
          toggleOn: true,
        })
      }),
      setPresentableState: assign((ctx, evt) => {
        logger.userNotations.debug(`setPresentableState, meetingId: ${ctx.meetingId}`)
        ctx.presentableState = evt.payload
      }),
      setCurrentActiveNotationId: assign((ctx, event) => {
        const evt = event as CustomNote.EVT_SET_CURRENT_ACTIVE_NOTATION

        if (evt.type === 'SET_CURRENT_ACTIVE_NOTATION' && ctx.currentActiveNotationId === evt.notationId) {
          logger.userNotations.debug(`Toggle off active notation ${evt.notationId}`)
          ctx.currentActiveNotationId = undefined
          ctx.presentationStateChannel.postMessage({
            type: 'SYNC_CURRENT_ACTIVE_NOTATION',
            meetingId: ctx.meetingId,
          })
        } else {
          logger.userNotations.debug(`Toggle on active notation ${evt.notationId}`)
          ctx.currentActiveNotationId = evt.notationId
          ctx.presentationStateChannel.postMessage({
            type: 'SYNC_CURRENT_ACTIVE_NOTATION',
            meetingId: ctx.meetingId,
            notationId: evt.notationId,
          })
        }
      }),
      toggleOnCoordinateMode: assign((ctx) => {
        logger.userNotations.debug(`Toggle on coordinate mode, meetingId: ${ctx.meetingId}`)
        ctx.presentationStateChannel.postMessage({
          type: 'TOGGLE_COORDINATE_MODE',
          meetingId: ctx.meetingId,
          toggleOn: true,
        })
      }),
      toggleOffCoordinateMode: assign((ctx) => {
        logger.userNotations.debug(`Toggle off coordinate mode, meetingId: ${ctx.meetingId}`)
        ctx.presentationStateChannel.postMessage({
          type: 'TOGGLE_COORDINATE_MODE',
          meetingId: ctx.meetingId,
          toggleOn: false,
        })
      }),
      createNotationDraft: assign((ctx, evt) => {
        if (!ctx.presentableState) {
          const errorText = `Cannot find presentableState when creating notation draft, meetingId: ${ctx.meetingId}`
          logger.userNotations.error(errorText)
          throw new Error(errorText)
        }
        logger.userNotations.debug(`Create notation draft, meetingId: ${ctx.meetingId}, event type: ${evt.type}`)

        const defaultDraft = ctx.customDeckId ? {
          customDeckId: ctx.customDeckId,
          status: HubSectionItemStatus.ACTIVE,
        } : {
          documentId: ctx.presentableState.documentId,
          documentVersionId: ctx.presentableState.documentVersionId,
          status: HubSectionItemStatus.ACTIVE,
        }

        switch (evt.type) {
          case 'SET_COORDINATE':
            ctx.notationDraft = {
              ...defaultDraft,
              pageId: evt.pageId,
              coordinate: evt.coordinate,
            }
            break;
          case 'CREATE_NOTE': {
            const documentVersionId = ctx.presentableState.documentVersionId
            const currentPageNum = ctx.presentableState.state.page
            const currentPageId = `${documentVersionId}_${currentPageNum}`
            ctx.notationDraft = {
              ...defaultDraft,
              pageId: currentPageId,
            }
            break;
          }
          case 'EDIT_NOTE':
            ctx.notationDraft = {
              status: defaultDraft.status,
              id: evt.notationId,
            }
            break;
          case 'DELETE_NOTE':
            ctx.notationDraft = {
              status: defaultDraft.status,
              id: evt.notationId,
            }
            break;
        }
      }),
      addDescription: assign((ctx, evt) => {
        if (!ctx.presentableState) {
          const errorText = `Cannot find presentableState when adding description, meetingId: ${ctx.meetingId}`
          logger.userNotations.error(errorText)
          throw new Error(errorText)
        }
        logger.userNotations.debug(`Add description, meetingId: ${ctx.meetingId}`)

        if (!ctx.notationDraft) {
          ctx.notationDraft = ctx.customDeckId ? {
            customDeckId: ctx.customDeckId,
            status: HubSectionItemStatus.ACTIVE,
            description: evt.description,
          } : {
            documentId: ctx.presentableState.documentId,
            documentVersionId: ctx.presentableState.documentVersionId,
            status: HubSectionItemStatus.ACTIVE,
            description: evt.description,
          }
        }
        else ctx.notationDraft.description = evt.description
      }),
      clearNotationDraft: assign((ctx, _event) => {
        logger.userNotations.debug(`Clear notation draft, meetingId: ${ctx.meetingId}`)
        ctx.notationDraft = undefined
      }),
      setMachineInfo: assign((ctx, evt) => {
        ctx.customDeckId = evt.customDeckId
        ctx.isReadOnlyMode = evt.isReadOnlyMode
      }),
    },
    services: {},
  },
)

export default customNoteSM
