import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox } from '@alucio/lux-ui';
import { useSelector } from 'react-redux';
import DNASlideRoll from 'src/components/DNA/SlideRoll/DNASlideRoll';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { SLIDE_ROLL_VARIANT } from 'src/components/DNA/SlideRoll/StyleSetting';
import { RootState, useDispatch } from 'src/state/redux';
import { useContent, LoadedPresentation } from 'src/state/context/ContentProvider/ContentProvider';
import { useMeetingPresentedMeta } from './State/MeetingPresentedMetaProvider';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import { SidebarOptionsEnum } from './SideBar/SideBar';
import useCurrentPage from '../DNA/hooks/useCurrentPage';

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    paddingHorizontal: 16,
  },
});

interface Props {
  presentation: LoadedPresentation,
  onPageSelect: (presentationPageNumber: number) => void,
  closeButtonOnPress?: () => void,
}

const PreviewSlideRoll = (props: Props) => {
  const { presentation, onPageSelect, closeButtonOnPress } = props;
  const { presentedMetas, isPresentedSlideEmpty } = useMeetingPresentedMeta();
  const dispatch = useDispatch();
  const isMeetingHistoryMode = !!useSelector((state: RootState) => state.contentPreviewModal)?.meetingId;
  const route = useCurrentPage({ exact: true })
  const isPublisherRoute = !!route?.configOptions?.modules?.includes('publisher');
  const hideInsertTextButton = isPublisherRoute || isMeetingHistoryMode;

  const handleInsertTextBtnOnPress = () => {
    dispatch(
      contentPreviewModalActions.setOpenedTab({
        openedTab: SidebarOptionsEnum.textInsertion,
      },
      ));
  };

  const memoizedSlideRoll = useMemo(() => (
    <DNASlideRoll
      presentedMeta={presentedMetas}
      activeSlide={presentation.currentPresentablePage}
      presentable={presentation.presentable}
      variant={isPresentedSlideEmpty ? SLIDE_ROLL_VARIANT.MEETING_PRESENTED_EMPTY : SLIDE_ROLL_VARIANT.PREVIEW_MODAL}
      horizontal={false}
      onSelect={onPageSelect}
      closeButtonOnPress={closeButtonOnPress}
      insertTextBtnOnPress={hideInsertTextButton ? undefined : handleInsertTextBtnOnPress}
    />
  ), [presentation, presentedMetas, isPresentedSlideEmpty, hideInsertTextButton]);

  return (
    <DNABox testID="preview-slide-roll" style={styles.mainWrapper}>
      {memoizedSlideRoll}
    </DNABox>
  );
};

const PreviewSlideRollConnector = (props) => {
  const { activePresentation, setActiveSlideByPresentationPageNumber } = useContent();
  if (!activePresentation) return null
  return (
    <DNABox>
      <PreviewSlideRoll
        presentation={activePresentation}
        onPageSelect={setActiveSlideByPresentationPageNumber}
        closeButtonOnPress={props.closeButtonOnPress}
      />
    </DNABox>

  );
};

PreviewSlideRoll.title = 'PreviewSlideRoll';

export default PreviewSlideRollConnector;
