import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAChip,
  DNADivider,
  DNAIcon,
  DNAText,
  Iffy,
  util,
} from '@alucio/lux-ui';
import { useMediaQuery } from 'react-responsive';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import {
  usePublisherVersioningState,
  MATCH_SLIDE_STATUS,
} from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings';
import CollapsibleCard from 'src/components/CollapsibleCard/CollapsibleCard';

const S = StyleSheet.create({
  countIcon: {
    backgroundColor: colors['color-success-500'],
    borderRadius: 22,
    padding: 6,
    marginHorizontal: 4,
  },
  columnHeaderContainer: {
    backgroundColor: colors['color-gray-80'],
    borderRadius: 8,
    width: 342,
  },
  columnHeaderContainerSmallerScreen: {
    backgroundColor: colors['color-gray-80'],
    borderRadius: 8,
    width: 242,
  },
  columnHeaderText: {
    color: colors['color-text-tertiary'],
    paddingVertical: 8,
  },
  headerContainer: {
    zIndex: 3,
    padding: 12,
    marginTop: 8,
    borderRadius: 8,
    backgroundColor: colors['color-text-basic'],
    borderColor: colors['color-gray-100'],
  },
  headerText: {
    paddingHorizontal: 41,
    paddingVertical: 32,
    textAlign: 'center',
  },
  helpStyle: {
    borderRadius: 4,
    borderColor: colors['color-gray-100'],
    backgroundColor: colors['color-text-basic'],
  },
  helpContentContainer: {
    paddingHorizontal: 16,
    paddingVertical: 14,
  },
  helpText: {
    lineHeight: 24,
  },
  helpContentContainerIndent: {
    marginVertical: 12,
    paddingLeft: 31,
  },
  helpTextIndent: {
    lineHeight: 24,
    textIndent: -15,
  },
  notEqualSign: {
    backgroundColor: colors['color-text-tertiary'],
    borderRadius: 22,
    color: colors['color-text-basic'],
  },
  notEqualSignOpaque: {
    backgroundColor: colors['color-text-tertiary'],
    borderRadius: 22,
    color: colors['color-gray-100'],
  },
  helpContainer: {
    width: 760,
    marginBottom: 30,
  },
  helpContainerSmallerScreen: {
    width: 586,
    marginBottom: 30,
  },
  tooltipText: {
    color: colors['color-text-white'],
  },
  hyperLink: {
    color: colors['color-primary-500'],
  },
})

const Header: React.FC = () => {
  const {
    slideCount,
    onUnmatch,
    slideGroupTargetRef,
    toggleSlidesGroupPoolVisible,
    onScrollToStatus,
  } = useMatchSlidesState();
  const { currentDocumentVersionORM, previousVersionORM } = usePublisherVersioningState();
  const [helpVisible, setHelpVisible] = useState<boolean>(false)
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  const navigateToHelpLink = () => {
    window.open(
      'https://alucio.zendesk.com/hc/en-us/articles/27976916232343-Slide-Matching-Process',
    )
  }

  return (
    <DNABox appearance="col" alignX="center">
      {/* Header action bar */}
      <DNABox
        appearance="row"
        alignX="center"
        alignY="center"
        spacing="sm"
        style={S.headerContainer}
      >
        <DNAButton
          onPress={toggleSlidesGroupPoolVisible}
          appearance="outline"
          status="tertiary"
          size="sm"
          padding="sm"
        >
          Find slides
        </DNAButton>
        <DNADivider vertical />
        <Iffy is={slideCount.needsReview}>
          <DNAButton
            iconLeft="alert-circle"
            appearance="outline"
            status="warning"
            size="sm"
            padding="sm"
            onPress={() => onScrollToStatus(MATCH_SLIDE_STATUS.NEEDS_REVIEW)}
            children={`${isLargeScreen ? 'Needs Review' : ''} (${slideCount.needsReview})`}
          />
        </Iffy>
        <Iffy is={slideCount.noMatch}>
          <DNAButton
            iconLeft="not-equal-variant"
            iconStyle={util.mergeStyles(
              undefined,
              [slideCount.noMatch < 1, S.notEqualSignOpaque],
              [slideCount.noMatch > 0, S.notEqualSign],
            )}
            appearance="outline"
            status="tertiary"
            size="sm"
            padding="sm"
            onPress={() => onScrollToStatus(MATCH_SLIDE_STATUS.NO_MATCH)}
            children={`${isLargeScreen ? 'No-match' : ''} (${slideCount.noMatch})`}
          />
        </Iffy>
        <DNAText b1 status="success">
          <DNAIcon.Styled
            name="check-bold"
            size={isLargeScreen ? LuxSizeEnum.md : LuxSizeEnum.sm}
            style={S.countIcon}
          />
          {`${slideCount.total - slideCount.needsReview - slideCount.noMatch} of ${slideCount.total} matches`}
        </DNAText>
        <DNADivider vertical style={{ marginHorizontal: 4 }} />
        <DNAButton
          onPress={() => onUnmatch('all')}
          appearance="outline"
          status="tertiary"
          size="sm"
          padding="sm"
          iconLeft={isLargeScreen ? undefined : 'not-equal-variant'}
        >
          {isLargeScreen ? 'Unmatch all' : ''}
        </DNAButton>
        <DNAPopover disablePopover={['tabletPWA']}>
          <DNAPopover.Anchor>
            <DNAButton
              appearance="outline"
              status="tertiary"
              size="lg"
              padding="xs"
              iconLeft="arrow-up"
              onPress={() => slideGroupTargetRef.current?.scrollToTop(true)}
            />
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText style={S.tooltipText} numberOfLines={1}>Scroll to top</DNAText>
          </DNAPopover.Content>
        </DNAPopover>
        <DNAPopover disablePopover={['tabletPWA']}>
          <DNAPopover.Anchor>
            <DNAButton
              appearance="outline"
              status="tertiary"
              size="lg"
              padding="xs"
              iconLeft="help-circle-outline"
              onPress={() => setHelpVisible(!helpVisible)}
            />
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText style={S.tooltipText} numberOfLines={1}>Learn more</DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      </DNABox>
      <DNABox alignX="center">
        <DNAText
          bold
          style={util.mergeStyles(
            undefined,
            S.headerText,
            { width: isLargeScreen ? 832 : 586 },
          )}
        >
          We've auto-matched slides to the previous version. Please review to make sure they all match.
        </DNAText>
      </DNABox>
      <Iffy is={helpVisible}>
        <DNABox
          style={util.mergeStyles(
            undefined,
            isLargeScreen ? S.helpContainer : S.helpContainerSmallerScreen,
          )
            }
          spacing="sm"
          appearance="col"
          alignX="end"
        >
          <DNAButton
            appearance="outline"
            status="tertiary"
            size="lg"
            padding="xs"
            iconLeft="close"
            onPress={() => setHelpVisible(false)}
          />
          <DNABox spacing="sm" fill appearance="col">
            <CollapsibleCard
              headerTitle={<DNAText b1 bold>Why should I match the slides?</DNAText>}
              isCollapsed={true}
              cardStyle={S.helpStyle}
            >
              <DNABox style={S.helpContentContainer}>
                <DNAText b1 numberOfLines={5} style={S.helpText}>
                  {/* eslint-disable-next-line max-len */}
                  When you match slides between versions, information about the slide is carried from version to version. This will allow viewers to automatically update their custom decks with the slides you have chosen, saving them time and effort. In addition, slide trends can be tracked over time.
                </DNAText>
              </DNABox>
            </CollapsibleCard>
            <CollapsibleCard
              headerTitle={<DNAText b1 bold>How do I match the slides?</DNAText>}
              isCollapsed={true}
              cardStyle={S.helpStyle}
            >
              <DNABox appearance="col" style={S.helpContentContainer}>
                {/* eslint-disable-next-line max-len */}
                <DNAText b1 style={S.helpText}>Beacon has automatically determined matches for your slides based on their similarity to the previous version. If there is no match, the slide on the left will be empty. If this is incorrect, a slide can be manually matched using the following steps:</DNAText>
                <DNABox appearance="col" style={S.helpContentContainerIndent}>
                  <DNAText b1 style={S.helpTextIndent}>
                    {/* eslint-disable-next-line max-len */}
                    1. Click on the “Find slides” button in the top action bar to open the left slide list, or click on the “Find slide” button within the empty box.
                  </DNAText>
                  <DNAText b1 style={S.helpTextIndent}>
                    {/* eslint-disable-next-line max-len */}
                    2. From the slide roll, find the previous version slide that corresponds with the slide in the new version.
                  </DNAText>
                  <DNAText b1 style={S.helpTextIndent}>
                    3. Drag and drop that slide into the dotted slide container.
                  </DNAText>
                </DNABox>
                <DNAText b1 style={S.helpText}>
                  For further information including step-by-step instructions, click
                  <TouchableOpacity onPress={navigateToHelpLink}>
                    <DNAText style={S.hyperLink}> here </DNAText>
                  </TouchableOpacity>
                  to access the full help article.
                </DNAText>
              </DNABox>
            </CollapsibleCard>
          </DNABox>
        </DNABox>
      </Iffy>
      <DNABox appearance="row" spacing="xl" alignX="center" style={{ padding: 8 }}>
        <DNABox
          style={util.mergeStyles(
            undefined,
            isLargeScreen ? S.columnHeaderContainer : S.columnHeaderContainerSmallerScreen,
          )
          }
          alignX="center"
          spacing="sm"
        >
          <DNAText style={S.columnHeaderText}>
            {`Version ${previousVersionORM?.meta.version.semVerLabel}`}
          </DNAText>
          <DNABox style={{ paddingVertical: 8 }}>
            <DNAChip style={{ borderRadius: 2 }} appearance="tag">CURRENT</DNAChip>
          </DNABox>
        </DNABox>
        <DNABox
          style={util.mergeStyles(
            undefined,
            isLargeScreen ? S.columnHeaderContainer : S.columnHeaderContainerSmallerScreen,
          )
          }
          alignX="center"
          spacing="sm"
        >
          <DNAText style={S.columnHeaderText}>
            {`${currentDocumentVersionORM?.meta.version.updateStatus === 'NOT_PUBLISHED'
              ? 'New Version'
              : currentDocumentVersionORM?.meta.version.semVerLabel}`}
          </DNAText>
          <DNABox style={{ paddingVertical: 8 }}>
            <DNAChip style={{ borderRadius: 2 }} appearance="tag" status="success">NEW</DNAChip>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default Header
