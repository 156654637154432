import { AppDispatch, store } from "src/state/redux"
import { allDocumentsSortedAndFilteredFactory } from "src/state/redux/selector/document"
import type { FilterAndSortOptions } from "src/state/redux/selector/document"
import { filterCollection, sortCollection } from "src/state/redux/selector/common"
import { allFoldersFilteredAndSortedFactory, allCustomDecks } from "src/state/redux/selector/folder"
import docQuery, { merge as docQueryMerge } from 'src/state/redux/document/query'
import folderQuery, { merge as folderQueryMerge } from 'src/state/redux/folder/query'
import { MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING_KEY, pageMappingActions } from "src/state/redux/slice/pageMapping"
import { userNotationsActions } from "src/state/redux/slice/userNotations"
import { datastoreSave } from "src/state/redux/slice/common"
import type { CreateUserNotationsPayload } from "src/state/redux/slice/userNotations"

import type {
  DocumentORM,
  DocumentVersionORM,
  CustomDeckORM,
  CustomDeckGroupORM,
} from "src/types/orms"
import {
  PageMapping,
  isDocumentVersionORM,
  FolderORM,
  VERSION_UPDATE_STATUS,
} from "src/types/types"
import {
  Folder,
  FolderItem,
  CustomDeck,
  Notation,
  CustomDeckGroup,
  CustomDeckPage,
  UserNotationsType,
} from "@alucio/aws-beacon-amplify/src/models"

import { v4 as uuid } from "uuid"
import logger from 'src/utils/logger'

import { multiSliceActions } from "./slice/multiSlice"

type TargetCustomDeckUpdates = {
  ORM: CustomDeckORM,
  targetGroups: Record<string, CustomDeckGroupORM>
}

export type TargetFolderDocVerUpgrades = {
  folderORM: FolderORM,
  folderItemsUpdatePayload: FolderItem[],
  upgradedIds: string[],
}

// [TODO] - Support hooks usage if needed? Need to pass in the API to get current state
// [TODO] - Move this into a util function
export const AUTO_UPDATE_DEFAULT_DATE = '1900-01-01T00:00:00.000Z'

/**
 * Handles upgrading document level Notations in 2 scenarios
 * - Scans all documents 
 *   - Hydration using no `opts`
 * - Targets single document
 *   - Subscription using `opts.documentId`
 * - (Note:) Notations may not be carried over if the upgrading document in question is 2 or more versions behind the latest published version
 *         We can consider daisy-chaining a carry-over in the future if we wish [TODO]
 * @param dispatch - Injectable dispatch to provide flexibility for both vanilla and hooks usage
 * @param opts - Targetting options for scanning all documents or providing a specific one
 * @returns
 */
export const upgradeLibraryDocumentsNotations = async (
  dispatch: AppDispatch = store.dispatch,
  opts?: { documentId: string },
) => {
  const ENABLE_MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING = !!localStorage
    .getItem(MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING_KEY)
  const allDocumentsSelector = allDocumentsSortedAndFilteredFactory()
  const documentIdFilter: FilterAndSortOptions<DocumentORM> = opts?.documentId
    ? {
      filter: {
        model: {
          id: opts.documentId
        }
      }
    }
    : {}

  // [NOTE] - This probably picks up archived documents as well
  //          Probably not an issue
  // [NOTE] - Technically getting access to state in here is an antipattern
  //          Makes unit testing a bit hard due to less isolation (from passing this in as state)
  //        - The alternative is for this function to take in these arguments and decouple it
  //          and possibly providing a wrapper function around it instead
  const targetDocuments = allDocumentsSelector(
    store.getState(),
    undefined,
    docQueryMerge(
      docQuery.filters.hasSlideMappingsForLatestPublished,
      docQuery.filters.isEligibleForNotationsUpgrade,
      documentIdFilter,
    )
  )

  if (!targetDocuments.length) {
    logger.upgrade.libraryDocNotations.info("No upgrades identified")
    return
  }

  const targetDocumentVersions = targetDocuments.map(docORM => docORM
    .relations
    .version
    .latestPublishedDocumentVersionORM!
  )

  await dispatch(pageMappingActions.fetchPageMappings(targetDocumentVersions))
    .unwrap()
    .catch(err => logger.upgrade.libraryDocNotations.warn('Could not fetch page mappings for upgrade', err))

  const pageMappingSlice = store.getState().pageMapping

  const pageMappings = pageMappingSlice
    .records
    .reduce<Record<string, PageMapping>>(
      (acc, pageMapping) => {
        acc[pageMapping.documentVersionId] = pageMapping
        return acc
      },
      {}
    )

  const debugNotations: CreateUserNotationsPayload[] = []

  // [TODO] - This can be extracted and reused
  // Transform Notations
  targetDocuments.forEach(docORM => {
    // [TODO] - Consider turning this into a util function
    //          or exposing the prev version on the docORM
    const filtered = filterCollection<DocumentVersionORM>(
      docORM.relations.documentVersions,
      { filter: { model: { status: 'PUBLISHED' } } }
    )
    const [latestPublishedVersion, prevPublishedVersion] = sortCollection<DocumentVersionORM>(
      filtered,
      {
        sort: [
          { model: { versionNumber: 'desc' } }
        ]
      }
    )

    try {
      const prevNotation = prevPublishedVersion.relations.userNotations

      // This shouldn't happen as we correctly identify eligible records in our original query
      if (!prevNotation) {
        throw new Error(
          'Skipping notation upgrade due to previous missing notations ' +
          prevPublishedVersion.model.id
        )
      }

      // [NOTE] - If we have any explicit user mapping actions (pageId or USER_UNMATCHED)
      //          then we only use mappings. Notations can be dropped here if no pageId is available
      //        - Otherwise, if we don't detect any mapping activity, we fallback to default pageNumber mapping
      if (!pageMappings[latestPublishedVersion.model.id]) {
        throw new Error(`Could not find pageMappings for ${latestPublishedVersion.model.id}`)
      }

      const { unmatchedCount, prevPageIdMappings } = pageMappings[latestPublishedVersion.model.id]
      const hasExplicitMapping = (unmatchedCount || Object.keys(prevPageIdMappings).length)

      const now = new Date().toISOString()
      const newNotations = prevNotation
        .notation
        .reduce<Notation[]>(
          (acc, notation) => {
            const newPageMappingId = pageMappings[latestPublishedVersion.model.id].prevPageIdMappings[notation.pageId]
            const hasInvalidMapping = (['USER_UNMATCHED', null, undefined].includes(newPageMappingId))
            const isActiveNotation = notation.status === 'ACTIVE'
            const pageNumber = notation.pageId.split('_').at(-1)
            const pageNumberFallbackMappingId = `${latestPublishedVersion.model.id}_${pageNumber}`

            if (!isActiveNotation)
              return acc

            if (hasExplicitMapping && hasInvalidMapping) {
              return acc
            }

            const newPageId = (hasExplicitMapping && !hasInvalidMapping)
              ? newPageMappingId!
              : pageNumberFallbackMappingId

            const newNotation: Notation = {
              ...notation,
              id: uuid(),
              createdAt: now,
              updatedAt: now,
              pageId: newPageId,
            }

            return [...acc, newNotation]
          },
          []
        )

      const newNotationPayload: CreateUserNotationsPayload = {
        documentId: docORM.model.id,
        documentVersionId: latestPublishedVersion.model.id,
        notation: newNotations,
        type: UserNotationsType.DOCUMENT_VERSION,
      }

      debugNotations.push(newNotationPayload)

      // Dispatch (individual) Notations updates
      if (!ENABLE_MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING) {
        store.dispatch(userNotationsActions.createUserNotations(newNotationPayload))
      }
    } catch (err) {
      logger.upgrade.folderDocVersions.error(err)
      // [NOTE] - For QA testing, we want to see explicit errors so we can any potential bugs
      //          However, we can turn this off in prod if we would rather have silent no-ops
      throw new Error(err as any)
      // Uncomment return for silent no-op upgrade
      // return
    }
  })

  if (debugNotations.length && ENABLE_MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING) {
    dispatch(multiSliceActions.promptLibraryMigration({
      newNotations: debugNotations,
      documentORMs: targetDocuments,
      pageData: pageMappingSlice.debug,
    }))
  }

  logger.upgrade.libraryDocNotations.info(
    "Upgraded Documents",
    targetDocuments.map(docORM => docORM.model.id)
  )
}

/**
 * Handles upgrading documents versions within Folders
 * There are three possible scenarios (based on the `opts` argument)
 * - Update a specific eligible Document FolderItem(s) within a specific folder
 *   - Individually via user input on FolderItem row or Folder's MyUpdate tab
 *   - Uses `opts.folderItemId` and `opts.skipGracePeriod = true`
 * - Update a specific eligible Document across all Folders
 *   - Bulk-upgrade via user input on Folder's MyUpdate tabs
 *   - Subscription via (expired GracePeriod)
 *   - Uses `opts.documentId` and `opts.skipGracePeriod = true`
 * - Update all eligible Documents with expired grace periods across all Folders
 *   - Hydration
 *   - Uses no `opts` and `skipGracePeriod = false`
 *
 * The expected mutations are to just bump the FolderItem's itemId, timestamp and reset specific flags and values
 * - (Note:) that User Notations for a Document Folder Item live at the Library level and would have already been upgraded
 * by this point
 * @param dispatch
 * @param opts
 * @returns 
 */
export const upgradeFolderDocuments = async (
  _dispatch: AppDispatch = store.dispatch,
  opts:
    | { folderItemId: string | string[], documentId?: undefined, skipGracePeriod: boolean }     // Manual
    | { documentId: string, folderItemId?: undefined, skipGracePeriod: boolean }     // Bulk (via doc id)
    | { documentId?: undefined, folderItemId?: undefined, skipGracePeriod: boolean } // Autoupgrade
  = { skipGracePeriod: false }
) => {
  const ENABLE_MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING = !!localStorage
    .getItem(MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING_KEY)

  const allFoldersSelector = allFoldersFilteredAndSortedFactory()

  const folderItemIdFilter: FilterAndSortOptions<FolderORM> = opts.folderItemId
    ? {
      filter: {
        relations: {
          items: (i) => i.some(item => {
            const itemORM = item.relations.itemORM

            return (
              isDocumentVersionORM(itemORM) &&
                Array.isArray(opts.folderItemId)
                ? opts.folderItemId.includes(item.model.id)
                : item.model.id === opts.folderItemId
            )
          }
          )
        }
      }
    }
    : {}

  const documentIdFilter: FilterAndSortOptions<FolderORM> = opts.documentId
    ? {
      filter: {
        relations: {
          items: (i) => i.some(item => {
            const itemORM = item.relations.itemORM

            return (
              isDocumentVersionORM(itemORM) &&
              itemORM.relations.documentORM.model.id === opts.documentId
            )
          }
          )
        }
      }
    }
    : {}

  // [NOTE] - Order matters and properties, queries conditions can overwrite each other
  const query = folderQueryMerge(
    folderQuery.filters.notRemoved,
    folderQuery.filters.isEligibleForDocumentUpgrade,
    folderItemIdFilter,
    documentIdFilter,
  )

  const targetFolders = allFoldersSelector(
    store.getState(),
    undefined,
    query
  )

  if (!targetFolders.length) {
    logger.upgrade.folderDocVersions.info("Skipping upgrade")
    return
  }

  const now = new Date().toISOString()

  const targetFolderUpdates = targetFolders.reduce<Array<TargetFolderDocVerUpgrades>>(
    (folderAcc, targetFolder) => {
      // Collect FolderItems to update
      const tagetDocVerFolderItemUpdates = targetFolder
        .relations
        .items
        .reduce<{
          hasUpdates: boolean,
          itemsPayload: FolderItem[],
          upgradedItemIds: string[]
        }>(
          (folderItemAcc, folderItemORM) => {
            const docVerORM = folderItemORM.relations.itemORM
            // Keep all other existing items
            if (!isDocumentVersionORM(docVerORM)) {
              folderItemAcc.itemsPayload.push(folderItemORM.model)
              return folderItemAcc
            }

            // Check docVer folderItem upgrade eligibility
            const { isLatestPublished, withinGracePeriod } = docVerORM.meta.version
            const shouldAutoUpgrade = !opts.skipGracePeriod && !withinGracePeriod
            const isPublished = docVerORM.model.status === 'PUBLISHED'
            const isManualUpgrade = opts.skipGracePeriod

            const latestPublishedVersionId = docVerORM
              .relations
              .documentORM
              .relations
              .version
              .latestPublishedDocumentVersionORM
              ?.model
              .id

            const shouldUpgrade = (
              !isLatestPublished &&
                  (isManualUpgrade || shouldAutoUpgrade) &&
                  isPublished &&
                  latestPublishedVersionId
            )

            // docVer folderItem not eligible for upgrade
            if (!shouldUpgrade) {
              folderItemAcc.itemsPayload.push(folderItemORM.model)
              folderItemAcc.upgradedItemIds.push(folderItemORM.model.id)
              return folderItemAcc
            }

            // Upgrade docVer folderItem
            folderItemAcc.hasUpdates = true

            const updatedItemPayload: FolderItem = {
              ...folderItemORM.model,
              itemId: latestPublishedVersionId!,
              itemLastUpdatedAt: now,
              // [NOTE] - For manual upgrades, we automatically acknowledge it by providing a timestamp
              updateAcknowledgedAt: opts.skipGracePeriod ? now : undefined,
              customTitle: undefined,
              visiblePages: undefined,
            }

            folderItemAcc.itemsPayload.push(updatedItemPayload)

            return folderItemAcc
          },
          {
            hasUpdates: false,
            itemsPayload: [],
            upgradedItemIds: [],
          }
        )

      // Collect folderItems that have eligible docVer item upgrades
      if (tagetDocVerFolderItemUpdates.hasUpdates) {
        folderAcc.push({
          folderORM: targetFolder,
          folderItemsUpdatePayload: tagetDocVerFolderItemUpdates.itemsPayload,
          upgradedIds: tagetDocVerFolderItemUpdates.upgradedItemIds,
        })
      }

      return folderAcc
    },
    []
  )

  if (!targetFolderUpdates.length) {
    logger.upgrade.folderDocVersions.debug("Skipping upgrade")
    return
  }

  if (!ENABLE_MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING) {
    try {
      // [TODO] - Determine if it's auto-upgrade
      targetFolderUpdates.forEach(target => {
        // Track analytics
        target.upgradedIds.forEach((upgradedId) => {
          if (opts.skipGracePeriod) {
            analytics?.track('FOLDER_ITEM_UPDATE_VERSION', {
              action: 'ITEM_UPDATE_VERSION',
              category: 'FOLDER',
              folderItemId: upgradedId,
            });
          }
          else {
            analytics?.track('FOLDER_ITEM_AUTO_UPDATE', {
              action: 'ITEM_AUTO_UPDATE',
              category: 'FOLDER',
              folderItemId: upgradedId
            });
          }
        })

        // Save records
        datastoreSave<Folder>(
          Folder,
          target.folderORM.model,
          { items: target.folderItemsUpdatePayload }
        )
      })
    } catch (err) {
      logger.upgrade.folderDocVersions.error(err)
      throw err
    }
  } else if (targetFolderUpdates.length) {
    store.dispatch(multiSliceActions.promptFolderDocVerUpgrade({
      folderTargets: targetFolderUpdates
    }))
  }

  logger.upgrade.folderDocVersions.info(
    "Upgraded Items with Folders",
    targetFolderUpdates.map(target => target.folderORM.model.id)
  )
}

/**
 * WIP
 * @param dispatch 
 * @param opts 
 */
export const upgradeCustomDecks = async (
  dispatch: AppDispatch = store.dispatch,
  opts:
    | { documentId: string, customDeckId?: undefined, skipGracePeriod: boolean } // Subscription - Auto 0-day grace period | Bulk Update
    | { customDeckId: string, documentId?: undefined, skipGracePeriod: boolean } // individual manual 
    | { customDeckId?: undefined, documentId?: undefined, skipGracePeriod: boolean }
  = { skipGracePeriod: false }
) => {
  // [NOTE] - This function has a slightly different approach due to the amount of
  //          redundant filtering that would be done if using higher level queries for filters
  //        - It's a bit more efficient this way with less looping
  //          Think of the above implementation as an alternative coding preference  
  const customDecks = allCustomDecks(store.getState())
  const pagesJSONTargetDocumentVersions: Record<string, DocumentVersionORM> = {}
  const targetCustomDeckUpdates: TargetCustomDeckUpdates[] = []

  // [NOTE] - There's no filtering of orphaned CustomDecks at this level so traversing the
  //          array is a little inefficient. We'd need to cross-reference FolderItems to do so
  customDecks.forEach(customDeck => {
    const customDeckIdMatches = opts.customDeckId
      ? customDeck.model.id === opts.customDeckId
      : true

    if (!customDeckIdMatches) {
      return;
    }

    const targetUpdates: TargetCustomDeckUpdates = {
      ORM: customDeck,
      targetGroups: {}
    }

    customDeck.meta.customDeckGroups.forEach(group => {
      const isPendingMinor = (group.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MINOR)

      // Identify eligible pages and their DocVerORM to fetch Pages.json
      // When doing minor version updates, it may only apply partial updates based on
      // each source document's upgrade status
      group.pages.forEach(page => {
        const docId = page.documentVersionORM.relations.documentORM.model.id
        // [TEST] - Opt Filter - Manual Upgrade
        const docIdMatches = opts.documentId
          ? docId === opts.documentId
          : true // Match all if no id is provided

        const { isLatestPublished, withinGracePeriod } = page.documentVersionORM.meta.version
        const shouldAutoUpgrade = !opts.skipGracePeriod && !withinGracePeriod
        const isManualUpgrade = opts.skipGracePeriod
        // [TEST]
        const shouldUpgradePage = (
          isPendingMinor && // This is probably redundant
          !isLatestPublished &&
          (isManualUpgrade || shouldAutoUpgrade) &&
          docIdMatches
        )

        if (shouldUpgradePage) {
          pagesJSONTargetDocumentVersions[page.documentVersionORM.model.id] = page.documentVersionORM
          targetUpdates.targetGroups[group.model.id] = group
        }
      })

      if (Object.keys(targetUpdates.targetGroups).length) {
        targetCustomDeckUpdates.push(targetUpdates)
      }
    })
  })

  await dispatch(pageMappingActions.fetchPageMappings(Object.values(pagesJSONTargetDocumentVersions)))
    .unwrap()
    .catch(err => logger.upgrade.folderCustomDecks.warn('Could not fetch mappings for CustomDeck upgrades', err))

  const pageMappingSlice = store.getState().pageMapping
  const now = new Date().toISOString()

  // Re-traverse identified CustomDecks to upgrade and apply any new mappings
  targetCustomDeckUpdates.forEach((targetUpdate) => {
    const { ORM, targetGroups } = targetUpdate

    const upgradedGroupsPayload = ORM.meta.customDeckGroups.map<CustomDeckGroup>((groupORM) => {
      const isGroupUpgradeTarget = targetGroups[groupORM.model.id]
      if (!isGroupUpgradeTarget)
        return groupORM.model

      const upgradedPagesPayload = groupORM.pages.map(pageORM => {
        const docORM = pageORM
          .documentVersionORM
          .relations
          .documentORM
        const latestDocVerId = docORM
          .relations
          .version
          .latestPublishedDocumentVersionORM
          ?.model
          .id!

        const pageMappings = pageMappingSlice
          .records
          .find(record => record.documentVersionId === latestDocVerId)

        if (!pageMappings) {
          // [TODO] - This should probably be a hard error
          throw new Error('May not have correctly fetched pages json -- falling back to pageNumber upgrade')
        }

        const newPageMapping = (
          pageMappings &&
          pageMappings[pageORM.model.pageId]
        )

        // [TEST]
        // Fallback to pageNumber upgrade if necessary
        // This can happen when the current version is outdated by more than 1 version
        const newPageId = (
          newPageMapping ||
          `${latestDocVerId}_${pageORM.model.pageNumber}`
        )

        const upgradedPage: CustomDeckPage = {
          pageId: newPageId,
          pageNumber: pageORM.model.pageNumber,
          documentVersionId: latestDocVerId,
        }

        return upgradedPage
      })

      const upgradedGroup: CustomDeckGroup = {
        ...groupORM.model,
        pages: upgradedPagesPayload
      }

      return upgradedGroup
    })

    const upgradedCustomDeckPayload: CustomDeck = {
      ...ORM.model,
      groups: upgradedGroupsPayload,
      // [TEST] - Previously we auto-acknowledge even during autoupgrade?
      autoUpdateAcknowledgedAt: opts.skipGracePeriod ? now : undefined,
      updatedAt: now,
    }

    console.log({ ORM, upgradedCustomDeckPayload })
    // [TODO] - Update records here
    // [TODO] - And any analytics
  })

  logger.upgrade.folderCustomDecks.info(
    'Upgraded Custom Decks',
    targetCustomDeckUpdates.map(record => record.ORM.model.id)
  )

  // [TODO] - Update Notations

  // [TODO] - Consider updating the corresponding Folder Item here as well?
  //          The previous implementation did not
}

// [NOTE] - Documents should not be that bad
// [NOTE] - CustomDecks should be harder
//        - We can only upgrade what we know of?
//        - Or support the shared usecase in functions above
//        - Autoupgrade we could carry the mapping over
//          Not sure if another user autoupgrade or manual, if manual, we wouldn't know how to carry the mappings over
export const upgradeSharedFolderItems = async () => {

}