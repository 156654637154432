import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { commonReducers, initialState, SliceStatus } from './common'
import { fetchPagesJson } from 'src/utils/loadCloudfrontAsset/common'
import { DocumentVersionORM, CustomDeckORM, PageMapping, ORMTypes } from 'src/types/types'
import {
  getS3KeysWithinCustomDeck,
  getPageJSONS3KeyForDocVer,
} from 'src/hooks/useContentPageData/useContentPageData'
import type { RootState } from 'src/state/redux'
import { PageData } from 'src/types/staticAssets'

export const MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING_KEY = 'ENABLE_MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING'
const sliceName = 'pageMapping'
const { reducers: commonReducer } = commonReducers<PageMapping>(sliceName)

const isCustomDeck = (contents: DocumentVersionORM[] | CustomDeckORM[]): contents is CustomDeckORM[] =>
  contents[0].type === ORMTypes.CUSTOM_DECK

// Upgrade Actions
// [TODO] - We probably shouldn't use ORM objects whenever possible ...
//          and we don't really need the metadata they provide for this
//        - But probably fine in this case since these objects are only being used very shortly
//          unlike when passed around in components
/**
 * This will map ids from oldPageId -> newPageId since
 * we're always working first by using older id to find new mappings
 */
const fetchPageMappings = createAsyncThunk<
  {
    formatted: PageMapping[],
    debug: Record<string, PageData>,
  },
  | DocumentVersionORM
  | CustomDeckORM
  | DocumentVersionORM[]
  | CustomDeckORM[],
  { state: RootState }
>(
  'fetchSlideMappings',
  async (content, api) => {
    const state = api.getState()
    let contents: DocumentVersionORM[] | CustomDeckORM[] = []
    let jsonS3Keys: Record<string, string> = {}

    if (!Array.isArray(content)) {
      contents = [content] as DocumentVersionORM[] | CustomDeckORM[]
    }
    else {
      contents = content
    }

    if (isCustomDeck(contents)) {
      jsonS3Keys = contents.reduce<Record<string, string>>(
        (acc, customDeck) => {
          return {
            ...acc,
            ...getS3KeysWithinCustomDeck(customDeck, false),
            ...getS3KeysWithinCustomDeck(customDeck, true)
          }
        },
        {}
      )
    }
    else {
      jsonS3Keys = contents.reduce<Record<string, string>>(
        (acc, docVerORM) => {
          const pagesS3Key = getPageJSONS3KeyForDocVer(docVerORM)
          if (pagesS3Key)
            acc[docVerORM.model.id] = pagesS3Key

          return acc
        },
        {}
      )
    }

    const uncachedJSONS3Keys = Object
      .entries(jsonS3Keys)
      .reduce<Record<string, string>>(
        (acc, [docVerId, jsonS3Key]) => {
          const cachedRecord = state
            .pageMapping
            .records
            .find(pageMapping => pageMapping.documentVersionId === docVerId)

          if (cachedRecord)
            return acc

          return {
            ...acc,
            [docVerId]: jsonS3Key
          }
        },
        {}
      )

    const results = await fetchPagesJson(uncachedJSONS3Keys, true)

    // [TODO] - Temp debugging
    const isDebugEnabled = !!localStorage.getItem(MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING_KEY)
    const debug = isDebugEnabled
      ? results.reduce<Record<string, PageData>>(
        (acc, result) => {
          acc[result.documentVersionId] = result.content
          return acc
        },
        {}
      )
      : { }

    const formatted = results.map<PageMapping>(result => {
      const pageMappings = result
        .content
        .pages
        .reduce<PageMapping>(
          (acc, pageData) => {
            // Entire page data is missing
            if (pageData === null) {  
              acc.missingPage++
              return acc
            }

            if (pageData.mapping === undefined) {
              acc.undefinedCount++
              return acc
            }

            if (pageData.mapping === null) {
              acc.nullCount++
              return acc
            }

            // [TODO] - Turn this into a const and replace other usage as well
            if(pageData.mapping === 'USER_UNMATCHED') {
              acc.unmatchedCount++
              return acc
            }

            if(pageData!.mapping) {
              acc.prevPageIdMappings[pageData.mapping] = `${result.documentVersionId}_${pageData.number}`
            }

            return acc
          },
          {
            documentVersionId: result.documentVersionId,
            prevPageIdMappings: { },
            missingPage: 0,
            unmatchedCount: 0,
            undefinedCount: 0,
            nullCount: 0,
          }
        )

      return pageMappings
    })

    return {
      formatted,
      debug,
    }
  }
)

const pageMappingSlice = createSlice({
  name: sliceName,
  initialState: {
    ...initialState<PageMapping>(),
    debug: { } as Record<string, PageData>,
  },
  // @ts-expect-error
  reducers: {
    ...commonReducer,
  },
  // [TODO] - Removes the Async Reducers, we don't have to use the builder callback, but it's recommended
  extraReducers: builder => {
    builder.addCase(
      fetchPageMappings.pending,
      (state) => {
        {
          state.isLoading = true
          state.status = SliceStatus.PENDING
        }
      }
    )
    builder.addCase(
      fetchPageMappings.fulfilled,
      (state, action) => {
        state.records = [...state.records, ...action.payload.formatted]
        state.debug = {
          ...state.debug,
          ...action.payload.debug
        }
        state.isLoading = false
        state.status = SliceStatus.OK
      }
    )
    builder.addCase(
      fetchPageMappings.rejected,
      (state, action) => {
        {
          state.errorStatus = {
            error: action.error,
            message: action.error.message
          }
          state.isLoading = false
          state.status = SliceStatus.ERROR
        }
      }
    )
  },
})

export default pageMappingSlice
export const pageMappingActions = {
  ...pageMappingSlice.actions,
  fetchPageMappings
}
