import _merge from 'lodash/merge'
import { FolderORM, VERSION_UPDATE_STATUS, isCustomDeckORM } from 'src/types/types'
import { FilterAndSortOptions } from 'src/state/redux/selector/common'

export const merge: (
  ...args: FilterAndSortOptions<FolderORM>[]
) => FilterAndSortOptions<FolderORM> = (...args) => _merge({}, ...args)

enum FilterOption {
  active = 'active',
  archived = 'archived',
  notRemoved = 'notRemoved',
  rootFolder = 'rootFolder',
  shared = 'shared',
  isEligibleForDocumentUpgrade = 'isEligibleForDocumentUpgrade',
  isEligibleForCustomDeckUpgrade = 'isEligibleForCustomDeckUpgrade',
}

// [TODO] - Update using `satisfies` to get rid of manual key typing
export type Filters = Record<FilterOption, FilterAndSortOptions<FolderORM>>

export const filters: Filters = {
  active: {
    filter: { model: { status: 'ACTIVE' } },
  },
  archived: {
    filter: { model: { status: 'ARCHIVED' } },
  },
  notRemoved: {
    filter: { model: { status: (s) => s !== 'REMOVED' } }
  },
  rootFolder: {
    filter: { relations: { parentFolderORM: null } },
  },
  shared: {
    filter: { meta: { isSharedWithTheUser: true } },
  },
  isEligibleForDocumentUpgrade: {
    // [TODO] - Double check accuracy of this property
    filter: { meta: { version: (v) => v.containsOutdatedDocVer } }
  },
  isEligibleForCustomDeckUpgrade: {
    filter: {
      relations: {
        items: (i) => {
          return i.some(item => {
            const itemORM = item.relations.itemORM
            if (!isCustomDeckORM(itemORM))
              return false

            return itemORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MINOR
          })
        }
      }
    }
  }
}

enum SortOption {
  folderTypeAsc = 'folderTypeAsc',
  pinnedAsc = 'pinnedAsc',
  nameAsc = 'nameAsc',
}

type Sorts = Record<SortOption, FilterAndSortOptions<FolderORM>>

export const sorts: Sorts = {
  folderTypeAsc: { sort: [{ type: 'desc' }] },
  pinnedAsc: {
    sort: [
      { model: { isPinned: 'asc' } },
      { model: { name: 'asc' } },
    ],
  },
  nameAsc: {
    sort: [{ model: { name: 'asc' } }],
  },
}

export default {
  filters,
  sorts,
  merge,
}
