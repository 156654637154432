import React, { useState } from 'react';
import { StyleSheet, Pressable } from 'react-native';
import { Iffy, Icon, DNABox, DNAText } from '@alucio/lux-ui';

import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useContentPreviewModal } from '../../State/ContentPreviewModalStateProvider';
import { tabConfigs } from 'src/components/ContentPreviewModalV2/SideBar/SideBar';
import SidebarContent from 'src/components/ContentPreviewModalV2/SideBar/SideBarContent';

const styles = StyleSheet.create({
  icon: {
    color: colors['color-info-500'],
    padding: 0,
    height: 18,
    width: 18,
  },
})

const ExpandIcon: React.FC<{ toggleLongDescription: boolean, onPress: () => void }> = (props) => {
  const { toggleLongDescription, onPress } = props;
  return (
    <Pressable onPress={onPress}>
      <DNABox alignY="center" spacing="xs">
        <Icon name={toggleLongDescription ? 'plus-box' : 'minus-box'} style={styles.icon} />
        <DNAText bold>
          Long Description
        </DNAText>
      </DNABox>
    </Pressable>
  )
}

const Details: React.FC = () => {
  const { activeDocumentVersionORM } = useContentPreviewModal()
  const [
    toggleLongDescription,
    setToggleLongDescription,
  ] = useState<boolean>(!!activeDocumentVersionORM?.model.longDescription);
  if (!activeDocumentVersionORM) return null
  const { customValues, integration } = activeDocumentVersionORM.meta;
  const sortedFieldLabels = Object.values(activeDocumentVersionORM.meta.customValues.configsMap).map(({ field }) =>
    field.fieldLabel).sort();
  const customFields = sortedFieldLabels?.map((fieldLabel, index: number) => {
    const field = Object.values(customValues.configsMap).find(({ field }) => field.fieldLabel === fieldLabel)

    return ( field && field.displayValues.length > 0
      ? <React.Fragment key={`modal_custom_fields_${index}`}>
        <DNABox appearance="col">
          <Iffy is={fieldLabel === 'Long Description'}>
            <ExpandIcon
              onPress={() => setToggleLongDescription(p => !p)}
              toggleLongDescription={toggleLongDescription}
            />
            <Iffy is={!toggleLongDescription}>
              <DNAText style={{ maxWidth: 280 }}>
                {field.displayValues[0]}
              </DNAText>
            </Iffy>
          </Iffy>
          <Iffy is={fieldLabel !== 'Long Description'}>
            <DNAText bold>{fieldLabel}</DNAText>
            {field.displayValues.map((value, index: number) => (
              <DNAText
                style={fieldLabel === 'Short Description' ? { maxWidth: 280 } : null }
                key={`custom_field_${index}`}
              >
                {value}
              </DNAText>
            ))}
          </Iffy>
        </DNABox>
      </React.Fragment>
      : null
    )
  });

  return (
    <DNABox appearance="col">
      <DNABox spacing="md" appearance="col">
        {customFields}
        {integration.source &&
          <>
            <DNAText bold>Content Source</DNAText>
            <DNAText style={{ maxWidth: 280 }}>{integration.source}</DNAText>
          </>
       }
        <DNABox appearance="col" spacing="between">
          <DNAText style={{ fontSize: 14 }} status="subtle">
            Document Id:
          </DNAText>
          <DNAText style={{ fontSize: 14 }} status="subtle">
            {activeDocumentVersionORM.model.documentId}
          </DNAText>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

const DetailsWrapper: React.FC = () => {
  return (
    <SidebarContent>
      <SidebarContent.Header
        sidebarHeader={tabConfigs.detailsSideBar.label}
      />
      <SidebarContent.Body>
        <Details/>
      </SidebarContent.Body>
    </SidebarContent>
  )
}

export default DetailsWrapper;
