import React from 'react'
import { useDispatch } from 'src/state/redux'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import {
  DNAModal,
  DNABox,
  useDisableSwipe,
  DNAButton,
  DNAText,
  Iffy,
  DNACheckbox,
} from '@alucio/lux-ui'
import { StyleSheet } from 'react-native';
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useForm, Controller } from 'react-hook-form'
import DNAThumbnail from '../Thumbnail/DNAThumbnail';
import InputComponent from 'src/components/Publishers/InputComponent'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import { TEXT_INSERTION_AVAILABLE_DOC_TYPE } from 'src/state/machines/customNote/customNoteUtils';

const styles = StyleSheet.create({
  input: {
    minWidth:600,
  },
})

interface DNAUpdateSlideDataProps {
  initialPageData: ContentPageData,
  setLatestDocumentVersionContentPageData: React.Dispatch<React.SetStateAction<ContentPageData[]>>,
  pageNumber: number,
  thumbURL?: string,
  fileType: FileType,
  send: (event: any) => void,
}

interface SlideSettingData {
  enableSlideTextInsertion?: boolean;
  title?: string;
  speakerNotes?: string | null;
}

const DNAUpdateSlideData: DNAModalVariant<DNAUpdateSlideDataProps> = ({
  toggleModal,
  fileType,
  initialPageData,
  setLatestDocumentVersionContentPageData,
  pageNumber,
  thumbURL,
  send,
}) => {
  useDisableSwipe()
  const dispatch = useDispatch()
  const isSlideTextInsertionEnabled = useFeatureFlags('BEAC_6389_slide_text_insertion') &&
    TEXT_INSERTION_AVAILABLE_DOC_TYPE.includes(fileType);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      title: initialPageData?.title,
      speakerNotes: initialPageData?.speakerNotes,
      enableSlideTextInsertion: initialPageData?.enableSlideTextInsertion,
    },
  })

  const {
    control,
    handleSubmit,
    formState: {
      isDirty,
    },
  } = form

  const onAction = (data: SlideSettingData) => {
    setLatestDocumentVersionContentPageData(preState => {
      return preState.map((pageData, index) => {
        if (index === (pageNumber - 1)) return { ...pageData, ...data }
        else return pageData
      })
    })
    send({ type: 'SET_IS_DIRTY', payload: { type: 'slidesData', isDirty: true } })
    dispatch(DNAModalActions.toggleModal())
  }

  return (
    <DNAModal>
      <DNAModal.Header onClose={toggleModal}>
        Edit slide details
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox spacing="md" alignY="center">
          <DNABox appearance="col" spacing="xs">
            <DNABox
              appearance="row"
              style={{ borderWidth: 1, borderColor: colors['color-gray-80'], padding: 20 }}
            >
              <DNAThumbnail
                variant={DNAThumbnail.Variants.DEFAULT}
                key={thumbURL}
                s3URL={thumbURL}
                size="xl"
              />
            </DNABox>
            <DNAText status="flat" bold>{`${pageNumber}.`}</DNAText>
          </DNABox>

          <DNABox appearance="col" spacing="md">
            <Controller
              name="title"
              render={({ field }) =>
                (<InputComponent
                  inputStyle={styles.input}
                  title="Title"
                  required={false}
                  characterLimit={100}
                  showCharacterCounter={true}
                  removeMarginPadding
                  {...field}
                />)
              }
              control={control}
            />
            <Controller
              control={control}
              name="speakerNotes"
              render={({ field: { value, ...restField } }) =>
                (<InputComponent
                  title="Speaker Notes"
                  required={false}
                  multiline
                  numOfLines={9}
                  removeMarginPadding
                  characterLimit={1000}
                  showCharacterCounter={true}
                  value={value ?? ''}
                  {...restField}
                />)
              }
            />
            <Iffy is={isSlideTextInsertionEnabled}>
              <Controller
                control={control}
                name="enableSlideTextInsertion"
                render={({ field: { value, onChange } }) =>
                  (<DNABox spacing="sm">
                    <DNACheckbox checked={!!value} onChange={onChange} />
                    <DNAText>
                      Viewers may insert text on slide
                    </DNAText>
                  </DNABox>)}
              />
            </Iffy>
          </DNABox>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNABox alignY="center" spacing="sm">
          <DNAButton size="sm" onPress={toggleModal} status="tertiary">
            Cancel
          </DNAButton>
          <DNAButton
            size="sm"
            disabled={!isDirty}
            testID="confirmation-button"
            onPress={handleSubmit(onAction)}
            status="primary"
          >
            Done
          </DNAButton>
        </DNABox>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

export default DNAUpdateSlideData
