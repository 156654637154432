import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, DNAIcon, DNASlider, DNACheckbox, DNADivider, Iffy } from '@alucio/lux-ui';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import { useSlideSettingsState } from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider';
import { SlideSettingsStateProvider, useSlideSettingsState as useSlideSettingsStateProxy }
  from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider.proxy';
import { getThumbURL } from 'src/utils/thumbnailHelpers';
import AssociatedSlidesThumbnails from 'src/components/DNA/Thumbnail/AssociatedSlidesThumbnails';
import { styles } from '../SlideSettings';

export const S = StyleSheet.create({
  attachmentSlides: {
    backgroundColor: colors['color-gray-10'],
    borderRightColor: colors['color-gray-80'],
    borderRightWidth: 1,
    width: 412,
  },
  attachmentSlidesHeader: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    height: 50,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  attachmentSlidesContent: {
    padding: 35,
  },
});

interface SlideProps {
  page: Page
}

const Slide: React.FC<SlideProps> = (props) => {
  const { page } = props
  const {
    service,
    currentDocumentVersionORM,
    thumbnailSizes,
    pageMap,
    getSlideTitle,
    getHasSlideTextInsertionEnabled,
    selectAssociatedSlide,
  } = useSlideSettingsStateProxy()

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.versionDraft,
      slideSettingsSelector.associatedSlides,
      slideSettingsSelector.isAddAssociatedSlidesMode,
      slideSettingsSelector.isAddAssociatedSlidesStepOne,
      slideSettingsSelector.isAddAssociatedSlidesStepTwo,
    ),
  )

  const coverThumbnailPageNumber = currentDocumentVersionORM.model.selectedThumbnail ?? 1
  const thumbURL = getThumbURL(currentDocumentVersionORM, page.number)

  const isChecked = cond.isAddAssociatedSlidesStepOne
    ? cond.associatedSlides.child[page.pageId]
    : cond.associatedSlides.parent[page.pageId]

  const associatedPages = Object
    .values(page.linkedSlides ?? {})
    ?.map(linkedSlideId => pageMap[linkedSlideId]) ?? []

  const allChildSlide = {}
  cond.versionDraft.pages?.forEach(page => {
    page.linkedSlides?.forEach(id => {
      allChildSlide[id] = true
    })
  })

  // Disabled: 1. If user at stepOne and the slide already has linkedslides
  //           2. If user at stepTwo and the slide is already some other slide's associated slide
  //           3. If user at stepTwo and the slide is already selected at stepOne
  const disabled = (cond.isAddAssociatedSlidesStepOne && !!page.linkedSlides?.length) ||
    (cond.isAddAssociatedSlidesStepTwo && !!allChildSlide[page.pageId]) ||
    (cond.isAddAssociatedSlidesStepTwo && cond.associatedSlides.child[page.pageId])

  // [TODO-ASSOCIATED] - Make a generic context to synchronize thumbnail loading
  //                   - Add support for generic interactive/badge rendering
  //                   - Add variant supports for different styling types

  return (
    <DNABox
      key={page.pageId}
      appearance="col"
      spacing="sm"
      childFill={1}
      style={{ marginRight: 12, marginBottom: 8 }} // this is to replace the spacing on the parent element, because spacing does not apply on the last item
    >
      {/* [TODO-ASSOCIATED] - Figure out a better way to avoid shifting scrollbar */}
      <DNABox style={{ marginRight: 12 }}>
        <DNAThumbnail
          key={thumbURL}
          s3URL={thumbURL}
          useLoadingIndicator
          size={thumbnailSizes.thumbnailSize}
          mode={DNAThumbnail.Modes.SELECTABLE}
          variant={DNAThumbnail.Variants.INFO}
          pageNumber={page.number}
          checked={isChecked}
          disabled={disabled}
          thumbnailTitle={getSlideTitle(page.number)}
          isTextInsertionEnabled={getHasSlideTextInsertionEnabled(page.number)}
          disabledMessage={
            cond.isAddAssociatedSlidesMode
              // eslint-disable-next-line max-len
              ? 'This slide cannot have associated slides because it is already associated with another slide.'
              : undefined
          }
          isCover={page.number === coverThumbnailPageNumber}
          isRequired={page.isRequired}
          onCheck={() => selectAssociatedSlide(page.pageId)}
        />
      </DNABox>
      <AssociatedSlidesThumbnails
        docVerORM={currentDocumentVersionORM}
        pages={associatedPages}
        size={thumbnailSizes.thumbnailSize}
      />
    </DNABox>
  )
}

const AddAssociatedSlidesModal: React.FC = () => {
  const slideSettingsState = useSlideSettingsState()
  const {
    service,
    currentDocumentVersionORM,
    thumbnailSizes,
    getSlideTitle,
    getHasSlideTextInsertionEnabled,
    backToIdle,
    saveAssociatedSlides,
    navToPreviousStepAssociatedSlides,
    navToNextStepAssociatedSlides,
    selectAllAssociatedSlides,
  } = slideSettingsState

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.versionDraft,
      slideSettingsSelector.associatedSlides,
      slideSettingsSelector.isAddAssociatedSlidesMode,
      slideSettingsSelector.isAddAssociatedSlidesStepOne,
      slideSettingsSelector.isAddAssociatedSlidesStepTwo,
      slideSettingsSelector.canGoToNextStepInAssociatedSlidesModal,
      slideSettingsSelector.canSaveAssociatedSlides,
    ),
  )

  // [SESSION] - Should not be necessary? Interact directly with state machine instead
  const [selectedChild, setSelectedChild] = useState<string[]>([])

  useEffect(() => {
    const allSelectedSlides:string[] = []
    Object.keys(cond.associatedSlides.child).forEach(id => {
      if (cond.associatedSlides.child[id]) allSelectedSlides.push(id)
    })
    setSelectedChild(allSelectedSlides.sort())
  }, [cond.associatedSlides.child])

  const numberOfSelectedChild = Object.values(cond.associatedSlides.child)
    .filter((value) => value)?.length || 0
  const numberOfSelectedParent = Object.values(cond.associatedSlides.parent)
    .filter((value) => value)?.length || 0
  const numberOfSlidesSelected =  cond.isAddAssociatedSlidesStepOne
    ? numberOfSelectedChild
    : numberOfSelectedParent
  // calculate the selectable slides num NOT the total slide num
  const SelectableNumberOfSlides = cond.versionDraft.pages?.reduce((acc, page) => {
    const allChildSlide = {}
    cond.versionDraft.pages?.forEach(page => {
      page.linkedSlides?.forEach(id => {
        allChildSlide[id] = true
      })
    })
    const disabled = (cond.isAddAssociatedSlidesStepOne && !!page.linkedSlides?.length) ||
      (cond.isAddAssociatedSlidesStepTwo && !!allChildSlide[page.pageId]) ||
      (cond.isAddAssociatedSlidesStepTwo && cond.associatedSlides.child[page.pageId])
    if (disabled) return acc
    else return acc + 1
  }, 0)
  const currentNumberOfSlidesSelected = `${numberOfSlidesSelected} of ${SelectableNumberOfSlides} Selected`
  const isAllSlidesChecked = numberOfSlidesSelected === SelectableNumberOfSlides

  return (
    <DNASlider
      visible={cond.isAddAssociatedSlidesMode}
      setVisible={backToIdle}
      orientation="horizontal"
    >
      <SlideSettingsStateProvider value={slideSettingsState}>
        <DNABox
          fill
          appearance="col"
          style={{ backgroundColor: colors['color-text-white'] }}
        >
          {/* HEADER */}
          <DNABox style={ styles.headerBar } spacing="between">
            <Iffy is={cond.isAddAssociatedSlidesStepOne}>
              <DNAButton
                appearance="outline"
                status="tertiary"
                padding="sm"
                onPress={backToIdle}
              >
                Cancel
              </DNAButton>
            </Iffy>
            <Iffy is={cond.isAddAssociatedSlidesStepTwo}>
              <DNAButton
                appearance="outline"
                status="tertiary"
                padding="sm"
                onPress={navToPreviousStepAssociatedSlides}
              >
                Back
              </DNAButton>
            </Iffy>
            <DNABox
              alignY="center"
              spacing="sm"
            >
              <DNAIcon.Styled
                appearance="outline"
                name={'numeric-1-box'}
                size="xl"
                status={cond.isAddAssociatedSlidesStepOne ? 'primary' : 'gray'}
              />
              <DNAText
                h4
                status={cond.isAddAssociatedSlidesStepOne ? 'primary' : 'subtle'}
                testID="add-associated-step-one"
              >
                Which slides are required?
              </DNAText>
              <DNAIcon.Styled
                appearance="outline"
                name={'numeric-2-box'}
                size="xl"
                status={cond.isAddAssociatedSlidesStepTwo ? 'primary' : 'gray'}
                style={{ marginLeft: 50 }}
              />
              <DNAText
                h4
                status={cond.isAddAssociatedSlidesStepTwo ? 'primary' : 'subtle'}
                testID="add-associated-step-two"
              >
                Where should they be attached?
              </DNAText>
            </DNABox>
            <Iffy is={cond.isAddAssociatedSlidesStepOne}>
              <DNAButton
                padding="sm"
                disabled={!cond.canGoToNextStepInAssociatedSlidesModal}
                onPress={navToNextStepAssociatedSlides}
              >
                Next
              </DNAButton>
            </Iffy>
            <Iffy is={cond.isAddAssociatedSlidesStepTwo}>
              <DNAButton
                padding="sm"
                disabled={!cond.canSaveAssociatedSlides}
                onPress={saveAssociatedSlides}
              >
                Save
              </DNAButton>
            </Iffy>
          </DNABox>

          {/* CONTENT */}
          <DNABox fill>
            {/* STEP TWO - LEFT SIDE BAR */}
            <Iffy is={cond.isAddAssociatedSlidesStepTwo}>
              <DNABox style={ S.attachmentSlides } appearance="col">
                <DNABox style= { S.attachmentSlidesHeader }>
                  <DNAText status="flatAlt" bold>
                    Required Slides ({Object.values(cond.associatedSlides.child)
                    .filter(b => b).length})
                  </DNAText>
                </DNABox>
                <ScrollView>
                  <DNABox appearance="col" alignX="center" style={ S.attachmentSlidesContent } spacing="lg">
                    {selectedChild.map(id => {
                      const coverThumbnailPageNumber = currentDocumentVersionORM.model.selectedThumbnail ?? 1
                      const page = currentDocumentVersionORM.meta.allPages.find(page => page.pageId === id)
                      const thumbURL = page?.number ? getThumbURL(currentDocumentVersionORM, page.number) : ''

                      return (
                        <DNAThumbnail
                          key={thumbURL}
                          s3URL={thumbURL}
                          variant={DNAThumbnail.Variants.INFO}
                          useLoadingIndicator
                          size="lg"
                          pageNumber={page?.number}
                          isCover={page?.number === coverThumbnailPageNumber}
                          isRequired={page?.isRequired}
                          thumbnailTitle={getSlideTitle(page?.number!)}
                          isTextInsertionEnabled={getHasSlideTextInsertionEnabled(page?.number)}
                        />
                      )
                    })}
                  </DNABox>
                </ScrollView>
              </DNABox>
            </Iffy>

            {/* ACTION BAR */}
            <DNABox fill appearance="col">
              <DNABox style={styles.rowWrapper} alignY="center">
                <Iffy is={cond.isAddAssociatedSlidesMode}>
                  <DNABox spacing="md" alignY="center">
                    <DNACheckbox
                      checked={isAllSlidesChecked}
                      onChange={selectAllAssociatedSlides}
                      style={{ marginLeft: 5, marginRight: 7 }}
                    />
                    <DNAText status="flatAlt">Select all</DNAText>
                    <DNADivider style={styles.verticalDivider} />
                    <DNAText status="flatAlt">
                      {currentNumberOfSlidesSelected}
                    </DNAText>
                  </DNABox>
                </Iffy>
                <DNABox fill alignX="end">
                  <DNAButton
                    appearance="ghostLink"
                    status="tertiary"
                    size="md"
                    padding="none"
                    onPress={thumbnailSizes.cycleThumbnailSize}
                    iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
                  />
                </DNABox>
              </DNABox>

              {/* SLIDES */}
              <DNABox fill>
                <ScrollView style={styles.content}>
                  <Iffy is={cond.isAddAssociatedSlidesMode}>
                    <DNABox
                      appearance="row"
                      wrap="start"
                      // [TODO-ASSOCIATED] - Consider adding marginBottom in DNABox when using spacing
                      childStyle={{ marginBottom: 32 }}
                      alignX="center"
                    >
                      {cond.versionDraft.pages?.map((page) => <Slide key={page.pageId} page={page} />)}
                    </DNABox>
                  </Iffy>
                </ScrollView>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
      </SlideSettingsStateProvider>
    </DNASlider>
  )
}

export default AddAssociatedSlidesModal
