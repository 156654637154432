import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNADivider, DNAText } from '@alucio/lux-ui';
import format from 'date-fns/format'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models';
import { useContentPreviewModal } from '../../State/ContentPreviewModalStateProvider';
import { tabConfigs } from 'src/components/ContentPreviewModalV2/SideBar/SideBar';
import SidebarContent from 'src/components/ContentPreviewModalV2/SideBar/SideBarContent';

const styles = StyleSheet.create({
  notes: { maxWidth: 280 },
})

const VersionHistory: React.FC = () => {
  const { activeDocumentVersionORM } = useContentPreviewModal()
  if (!activeDocumentVersionORM) return null
  const activeDoc = activeDocumentVersionORM.relations.documentORM
  const docVersions = activeDoc.relations.documentVersions

  const publishedVersionsEntries = docVersions
    .filter((ver) => ver.model.status === DocumentStatus.PUBLISHED)
    .map(docVer => {
      const header = `Version ${docVer.meta.version.semVerLabel}`
      const publishDate = `Published ${format(new Date(docVer.model.updatedAt || 0), 'MMMM dd, yyyy')}`
      const { releaseNotes, id } = docVer.model

      return (
        <DNABox
          key={id}
          appearance="col"
          spacing="md"
        >
          <DNAText bold>{header}</DNAText>
          <DNAText status="subtle">{publishDate}</DNAText>
          <DNAText style={styles.notes}>{releaseNotes}</DNAText>
          <DNADivider />
        </DNABox>
      )
    })

  return (
    <SidebarContent>
      <SidebarContent.Header
        sidebarHeader={tabConfigs.versionHistorySideBar.label}
      />
      <SidebarContent.Body>
        <DNABox fill appearance="col" spacing="md">
          {publishedVersionsEntries}
        </DNABox>
      </SidebarContent.Body>
    </SidebarContent>
  )
}

export default VersionHistory;
