import React from 'react';
import { DNAButton } from '@alucio/lux-ui';
import useSelector, { composite } from 'src/hooks/useSelector';
import * as customNotesSelector from 'src/state/machines/customNote/customNote.selectors';
import SidebarContent from '../../SideBarContent';
import { tabConfigs } from '../../SideBar';
import CustomNotesProvider, { useCustomNotes } from '../CustomNotes/CustomNotesProvider';
import { CustomNotes } from '../CustomNotes/CustomNotes';
import { useContentPreviewModal } from '../../../State/ContentPreviewModalStateProvider';
import * as logger from 'src/utils/logger';

const TextInsertionRightElement: React.FC = () => {
  const {
    service,
    createNotationWithCoordinate,
    currentPageNotationsInfo,
  } = useCustomNotes()

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      customNotesSelector.isInitializingState,
      customNotesSelector.isIdleState,
      customNotesSelector.canInsertText,
      customNotesSelector.isReadOnlyMode,
    ),
  )

  const hideContextMenu = cond.isReadOnlyMode ||
    cond.isInitializingState || currentPageNotationsInfo.hasMeetNotationLimits;

  if (hideContextMenu) return null
  return (
    <DNAButton
      testID="text-insertion-create-button"
      iconRight="plus"
      appearance="outline"
      size="lg"
      status="tertiary"
      padding="xs"
      style={{ width: 32, height: 32 }}
      onPress={createNotationWithCoordinate}
      disabled={!cond.isIdleState}
    />
  )
}

const TextInsertionWrapper: React.FC = () => {
  const { canCurrentSlideInsertText } = useContentPreviewModal();

  if (!canCurrentSlideInsertText) {
    logger.userNotations.error('Current slide doesn\'t have TextInsertion enabled');
    return null;
  }

  return (
    <CustomNotesProvider isTextInsertion>
      <SidebarContent>
        <SidebarContent.Header
          sidebarHeader={tabConfigs.textInsertion.label}
          descriptionText={'Inserted texts will not appear when this file\nis downloaded or shared'}
          rightElement={<TextInsertionRightElement/>}
        />
        <SidebarContent.Body>
          <CustomNotes />
        </SidebarContent.Body>
      </SidebarContent>
    </CustomNotesProvider>
  )
}

export default TextInsertionWrapper;
