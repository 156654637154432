import React, { useEffect } from 'react';
import { Pressable, ScrollView, StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAHeader, DNAText, Iffy, util } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import DocumentsUpdates from './DocumentsUpdates';
import { useDNAMyFoldersSharedResources } from './DNAMyFolders';
import { FolderORM } from 'src/types/orms';
import { useHistory, useLocation } from 'src/router';
import { filterCollection } from 'src/state/redux/selector/common';
import { filters, merge, sorts } from 'src/state/redux/folder/query';
import DNADefaultHeader from 'src/components/DNA/FlatList/Headers/DNADefaultHeaders';
import DNAGrid from 'src/components/DNA/Grid/DNAGrid';
import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList';
import { DisplayModes } from 'src/components/DNA/hooks/useDisplayMode';
import { ROUTES } from 'src/router/routes';
import { useAllPersonalDocuments } from 'src/state/redux/selector/document';
import FolderActions from './FolderActions';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import CustomDeckButton from 'src/components/Header/CustomDeckButton/CustomDeckButton';

const S = StyleSheet.create({
  chevronIcon: {
    color: colors['color-gray-500'],
    marginTop: 2,
  },
  header: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    padding: 0,
    shadowOpacity: 0,
  },
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    paddingBottom: 12,
  },
  myUpdatesContentTablet: {
    minHeight: 'auto',
  },
  selectedTab: {
    borderBottomWidth: 3,
    borderBottomColor: colors['color-brand2-500'],
  },
  tabButton: {
    paddingHorizontal: 12,
  },
  gridContent: { paddingHorizontal: 24, paddingVertical: 12, overflowY: 'auto' },
});

const TABS_OPTIONS = [{
  title: 'My Folders',
  path: '/folders',
}, {
  title: 'Shared With Me',
  path: '/shared_folders',
}, {
  title: 'My Uploads',
  path: '/folders/uploads',
}, {
  title: 'My Updates',
  path: '/folders/updates',
}, {
  title: 'Archived Folders',
  path: '/archived_folders',
}];

// TODO: refactor to show personal documents
const DNAMyFoldersTablet = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const lowerCasedPathName = pathname.toLowerCase();
  const sharedFoldersTab = lowerCasedPathName.includes('shared_folders')
  const archivedFoldersTab = lowerCasedPathName.includes('archived_folders')
  const isMyUpdatesScreen = lowerCasedPathName === '/folders/updates';
  const isMyUploadsScreen = lowerCasedPathName === '/folders/uploads';
  const isFoldersList = !archivedFoldersTab && !isMyUpdatesScreen && !isMyUploadsScreen;
  const featureFlags = useFeatureFlags('enableSharedFolders', 'enableMyUploads', 'BEAC_6655_custom_deck_usage');

  useEffect(() => {
    /*
     Redirect to 404 if my uploads is not enabled
     Ideally this would be placed in routes.tsx, however with the way the components were structured this is the best way
     to do it without major rewrites
    */
    if (isMyUploadsScreen && !featureFlags.enableMyUploads) {
      history.replace(ROUTES.NOT_FOUND.PATH);
    }
  }, []);

  const {
    folderNav,
    folderNavURL,
    displayModeIconName,
    toggleDisplayMode,
    displayMode,
    activeItems,
    onPress,
    getBreadCrumb,
    emptyVariantType,
  } = useDNAMyFoldersSharedResources();

  const personalDocuments = useAllPersonalDocuments();

  useEffect(() => {
    folderNav.setDisplaySharedFolders(sharedFoldersTab);
  }, [sharedFoldersTab]);

  // We need to correctly target the child index to flex
  // since they're Iffy, their idx are grid = 1 and list = 2
  const gridOrListChildFill = !archivedFoldersTab && displayMode === DisplayModes.grid ? 1 : 2;

  /* using correct item collection depending on level */
  const archivedItems = folderNav.isCurrentlyNested
    ? folderNav.currentItems
    : filterCollection(folderNav.currentFolders, merge(filters.archived, filters.rootFolder, sorts.pinnedAsc))

  return (
    <DNABox appearance="col" style={S.mainWrapper} fill>
      <DNAHeader testID="pwa-my-folders-header" status="flat" size="medium" style={S.header}>
        <DNAHeader.Left>
          <DNABox>
            <Iffy is={folderNav.folderStack.length === 0}>
              {
                TABS_OPTIONS.map((option) => {
                  if (option.title === 'Shared With Me' && !featureFlags.enableSharedFolders) {
                    return null;
                  }
                  if (option.title === 'My Uploads' && !featureFlags.enableMyUploads) {
                    return null;
                  }
                  return (
                    <DNABox
                      key={option.title}
                      alignY="center"
                      style={util.mergeStyles(undefined, { height: 56 },
                        [S.selectedTab, lowerCasedPathName === option.path])}
                    >
                      <Pressable
                        testID={'DNATab'}
                        style={S.tabButton}
                        onPress={() => history.push(option.path)}
                      >
                        <DNAText h4 status={lowerCasedPathName === option.path ? 'primary' : 'flat'}>
                          {option.title}
                        </DNAText>
                      </Pressable>
                    </DNABox>
                  )
                })
              }
            </Iffy>

            {/* Beyond one level down in folder tree */}
            <Iffy is={folderNav.folderStack.length > 0}>
              <DNABox fill alignY="center">
                <Iffy is={folderNav.folderStack.length === 1}>
                  {/* Root Link */}
                  <DNAButton
                    appearance="ghostLink"
                    status="tertiary"
                    size="lg"
                    iconLeft="chevron-left"
                    iconStyle={S.chevronIcon}
                    onPress={() => folderNavURL.popToFolder()}
                  />
                </Iffy>

                <Iffy is={folderNav.folderStack.length > 1}>
                  {getBreadCrumb(folderNav.folderStack.length - 2, true, true)}
                </Iffy>
              </DNABox>
            </Iffy>
          </DNABox>
        </DNAHeader.Left>
        <DNAHeader.Center>
          {/* Beyond one level down in folder tree */}
          <Iffy is={folderNav.folderStack.length > 0}>
            <DNABox fill alignY="center" spacing="between">
              <Iffy is={folderNav.folderStack.length === 1}>
                {/* Current Folder Link */}
                {getBreadCrumb(folderNav.folderStack.length - 1, true)}
              </Iffy>

              <Iffy is={folderNav.folderStack.length > 1}>{getBreadCrumb(folderNav.folderStack.length - 1, true)}</Iffy>
            </DNABox>
          </Iffy>
        </DNAHeader.Center>
        <DNAHeader.Right>
          {/* Right Controls */}
          <DNABox spacing="md">
            <Iffy is={isFoldersList}>
              <FolderActions />
            </Iffy>
            <Iffy is={isFoldersList && !sharedFoldersTab && featureFlags.BEAC_6655_custom_deck_usage}>
              <CustomDeckButton mode="myFolders" />
            </Iffy>
            <Iffy
              is={!isMyUploadsScreen &&
                !isMyUpdatesScreen}
            >
              <DNAButton
                size="md"
                appearance="outline"
                padding="sm"
                status="tertiary"
                iconLeft={displayModeIconName}
                onPress={toggleDisplayMode}
              />
            </Iffy>
          </DNABox>
        </DNAHeader.Right>
      </DNAHeader>
      <ScrollView>
        <DNABox fill alignX="center">
          <DNABox
            fill
            spacing="md"
            appearance="col"
            childFill={gridOrListChildFill}
          >
            {/* Content */}
            <Iffy is={isFoldersList && displayMode === DisplayModes.grid}>
              <DNAGrid
                items={activeItems}
                onPress={onPress}
                emptyVariant={emptyVariantType}
                // [TODO]: Switching between list and grid does not center empty properly
                style={S.gridContent}
              />
            </Iffy>

            <Iffy is={isFoldersList && displayMode === DisplayModes.list}>
              <DNAFlatList items={activeItems} onPress={onPress} emptyVariant={emptyVariantType} disableHeader={true}>
                <DNADefaultHeader />
              </DNAFlatList>
            </Iffy>

            {/* Archived */}
            <Iffy is={archivedFoldersTab}>
              <DNABox appearance="col" spacing="md">
                <Iffy is={displayMode === DisplayModes.grid}>
                  <DNAGrid
                    items={archivedItems}
                    onPress={onPress}
                    emptyVariant={emptyVariantType}
                    style={{ marginHorizontal: 24 }}
                  />
                </Iffy>
                <Iffy is={displayMode === DisplayModes.list}>
                  <DNAFlatList<FolderORM>
                    items={archivedItems}
                    onPress={onPress}
                    emptyVariant={emptyVariantType}
                    disableHeader={true}
                  >
                    <DNADefaultHeader />
                  </DNAFlatList>
                </Iffy>
              </DNABox>
            </Iffy>

            {/* Updates */}
            <Iffy is={isMyUpdatesScreen}>
              <DNABox fill alignX="center" style={S.myUpdatesContentTablet}>
                <DNABox fill spacing="md" appearance="col" style={{ paddingHorizontal: 16 }}>
                  <DocumentsUpdates isTablet={true} />
                </DNABox>
              </DNABox>
            </Iffy>

            {/* Uploads */}
            <Iffy is={isMyUploadsScreen}>
              <DNAFlatList
                items={personalDocuments}
                onPress={onPress}
                emptyVariant={emptyVariantType}
                disableHeader={true}
              >
                <DNADefaultHeader />
              </DNAFlatList>
            </Iffy>
          </DNABox>
        </DNABox>
      </ScrollView>
    </DNABox>
  )
};

export default DNAMyFoldersTablet;
