import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'
import { Platforms } from '@alucio/core'
import { DNABox, DNAButton, DNACheckbox, DNADivider, DNASelect, DNAText, Iffy, IndexPath } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { useDispatch } from 'src/state/redux'
import { useAppSettings } from 'src/state/context/AppSettings'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import { usePresentationBuilderState } from '../../state/PresentationBuilderStateProvider'
import { Action, usePresentationBuilderSharedResources } from '../PresentationEditor'
import { PRESENTATION_BUILDER_VARIANT } from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder'
import RequiredSlidesHiddenConfirmation
  from 'src/components/DNA/Modal/DNAPresentationBuilder/RequiredSlidesHiddenConfirmation'
import { useUnresolvedSlides } from '../../hooks/useUnresolvedSlides'

const S = StyleSheet.create({
  containerStyle: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    backgroundColor: 'white',
  },
  settings: {
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
    borderWidth: 1,
    minWidth: 210,
    paddingVertical: 16,
    paddingHorizontal: 20,
    shadowColor: colors['color-gray-900'],
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.16,
    shadowRadius: 11.22,
  },
});

const DISABLE_POPOVER = [Platforms.tabletPWA]

const useBulkValidations = () => {
  const {
    selectedTargetItems,
    selectedGroups,
    associatedParentsMap,
  } = usePresentationBuilderState();

  const allSelectedVisible = useMemo(() => selectedTargetItems.every(item => {
    const group = selectedGroups.find(group => group.id === item)
    return group && group.visible
  }), [selectedTargetItems, selectedGroups]);

  const allSelectedHidden = useMemo(() => selectedTargetItems.every(item => {
    const group = selectedGroups.find(group => group.id === item)
    return group && !group.visible
  }), [selectedTargetItems, selectedGroups]);

  const someRequiredSlidesSelected = useMemo(() => {
    return selectedTargetItems.some(item => {
      const group = selectedGroups.find(group => group.id === item)
      return group &&
        group.pages.some(page => {
          const hasAssociatedParent = associatedParentsMap.get(page.pageId) && !page.isRequired
          return page.isRequired || hasAssociatedParent
        },
        )
    })
  }, [selectedTargetItems, selectedGroups]);

  const toggleVisibilityText = allSelectedVisible
    ? 'Hide'
    : allSelectedHidden ? 'Show' : '';

  return {
    allSelectedVisible,
    someRequiredSlidesSelected,
    toggleVisibilityText,
  };
};

const ActionButtons: React.FC<{ disablePreview?: boolean }> = ({ disablePreview }) => {
  const { getActionEls } = usePresentationBuilderSharedResources();
  const { platform } = useAppSettings();
  const {
    editorThumbnailSize,
    cycleEditorThumbnailSize,
    hiddenSlidesVisible,
    setHiddenSlidesVisible,
    displayPageSourceFile,
    setDisplayPageSourceFile,
    onPreview,
  } = usePresentationBuilderState();

  const [displaySettings, setDisplaySettings] = useState<boolean>(false);

  function toggleVisibilitySettings(): void {
    setDisplaySettings((val) => !val);
  }

  const actions: Action[] = [
    {
      disabled: disablePreview,
      iconLeft:'play',
      appearance:'ghost',
      status: 'tertiary',
      padding: 'sm',
      tooltip:'Preview',
      onPress: () => onPreview(),
      testID: 'custom-preview-btn',
    },
    {
      hidden: platform === 'tabletPWA',
      iconLeft: editorThumbnailSize === 'lg' ? 'view-comfy' : 'view-grid',
      appearance:'ghost',
      status:'tertiary',
      padding: 'sm',
      tooltip:'Toggle thumbnail size',
      onPress: cycleEditorThumbnailSize,
    },
    {
      iconLeft: 'cog',
      appearance:'ghost',
      status:'tertiary',
      padding: 'sm',
      tooltip: 'Settings',
      onPress : toggleVisibilitySettings,
    },
  ];

  const actionEls = getActionEls(actions);
  /** TODO: refactor this once the composable action bar refactor
   * PR has has been merged in (which adds support for popovers)
   * see https://github.com/alucioinc/eeb/pull/1933 */
  const settingsActionEl = actionEls.pop();

  return (
    <DNABox spacing="sm">
      {actionEls}
      <DNAPopover
        lazyMount
        placement="bottom"
        interactive={true}
        visible={displaySettings}
        onBackdropPress={toggleVisibilitySettings}
        type="menu"
      >
        <DNAPopover.Anchor>
          {settingsActionEl}
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNABox spacing="sm" appearance="col" style={S.settings}>
            <DNABox spacing="sm">
              <DNACheckbox checked={displayPageSourceFile} onChange={setDisplayPageSourceFile}/>
              <DNAText>Show source file name</DNAText>
            </DNABox>
            <DNABox spacing="sm">
              <DNACheckbox
                testID="hidden-slide-checkbox"
                checked={hiddenSlidesVisible}
                onChange={setHiddenSlidesVisible}
              />
              <DNAText>Show hidden slides</DNAText>
            </DNABox>
          </DNABox>
        </DNAPopover.Content>
      </DNAPopover>
    </DNABox>
  )
};

const VisibilityDropDownMenu: React.FC<{}> = () => {
  const { setShowReviewRequiredOnly, hasNeedReviewGroup } = usePresentationBuilderState()
  const [selectedIndex, setSelectedIndex] = useState<IndexPath>(new IndexPath(0))

  const onSelect = (e) => {
    setSelectedIndex(e)
    setShowReviewRequiredOnly(e.row === 0)
  }

  const selectedValue = (): string => {
    if (selectedIndex.row === 0) {
      return 'Review required'
    } else if (selectedIndex.row === 1) {
      return 'All slides'
    }
    throw Error('unknown option selected')
  };

  useEffect(() => {
    if (!hasNeedReviewGroup) onSelect(new IndexPath(1))
  }, [hasNeedReviewGroup])

  if (!hasNeedReviewGroup) return null
  return (
    <DNABox alignY="center" fill testID="slide-groups-selection">
      <DNASelect
        style={{ minWidth: 160 }}
        value={selectedValue()}
        onSelect={onSelect}
      >
        <DNASelect.Item
          key="review-required"
          title="Review required"
        />
        <DNASelect.Item
          key="all-slides"
          title="All slides"
        />
      </DNASelect>
    </DNABox>
  )
}

const ExpandButton: React.FC = () => {
  const { unresolvedSlideCount } = useUnresolvedSlides();
  const { handleModeChange, isLocked, builderMode, hasNeedReviewGroup } = usePresentationBuilderState();

  if (builderMode === 'selection') {
    return (
      <DNAButton
        appearance="outline"
        status="tertiary"
        size="sm"
        disabled={isLocked || hasNeedReviewGroup}
        testID="expanded-collapse-custom-deck-button"
        onPress={ () => handleModeChange() }
        iconLeft="arrow-expand-horizontal"
        padding="sm"
      />
    );
  }

  if (unresolvedSlideCount > 0 || (isLocked || hasNeedReviewGroup)) {
    return (
      <DNAPopover
        placement="top"
        type="tooltip"
        offset={30}
        disablePopover={['tabletPWA']}
      >
        <DNAPopover.Anchor>
          <DNAButton
            appearance="outline"
            status="tertiary"
            size="sm"
            disabled={isLocked || hasNeedReviewGroup}
            testID="expanded-collapse-custom-deck-button"
            onPress={() => handleModeChange()}
            padding="sm"
          >
            Add Slides
          </DNAButton>
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText status="basic">
            Please resolve current slides before adding new ones
          </DNAText>
        </DNAPopover.Content>
      </DNAPopover>
    );
  }

  return (
    <DNAButton
      appearance="outline"
      status="tertiary"
      size="sm"
      disabled={isLocked || hasNeedReviewGroup}
      testID="expanded-collapse-custom-deck-button"
      onPress={() => handleModeChange()}
      padding="sm"
    >
      Add Slides
    </DNAButton>
  );
};

const SubHeader: React.FC<
{
  showActionButtons: boolean,
  disablePreview?: boolean,
}
> = ({ showActionButtons, disablePreview }) => {
  const {
    builderMode,
    variant,
    selectedGroups,
    selectedTargetItems,
    setSelectedTargetItems,
    setSelectedGroups,
    numOfRequiredSlides,
    visibleGroupsMap,
    hasNeedReviewGroup,
  } = usePresentationBuilderState();
  const {
    allSelectedVisible,
    someRequiredSlidesSelected,
    toggleVisibilityText,
  } = useBulkValidations();
  const storeDispatch = useDispatch();

  const isReadOnlyMode = variant === PRESENTATION_BUILDER_VARIANT.READ;
  const groupsIds = useMemo(() => {
    return Object.keys(visibleGroupsMap).filter(key => visibleGroupsMap[key])
  }, [visibleGroupsMap])

  const toggleVisibility = useCallback(() => {
    setSelectedGroups((prev) =>
      prev.map((group) => {
        const isSelected = selectedTargetItems.includes(group.id);
        return {
          ...group,
          visible: isSelected
            ? toggleVisibilityText === 'Show'
            : group.visible,
        }
      }),
    )
    setSelectedTargetItems([]);
  }, [someRequiredSlidesSelected, selectedTargetItems, allSelectedVisible]);

  const handleVisibilityChange = useCallback(() => {
    if (someRequiredSlidesSelected && allSelectedVisible) {
      storeDispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        component: (props) =>
          (<RequiredSlidesHiddenConfirmation
            { ...props }
            onSave={toggleVisibility}
          />),
      }));
    } else {
      toggleVisibility();
    }
  }, [someRequiredSlidesSelected, selectedTargetItems, allSelectedVisible]);

  const removeSelectedSlides = useCallback(() => {
    setSelectedGroups((prev) => (
      prev.filter((group) => !selectedTargetItems.includes(group.id))
    ))
    setSelectedTargetItems([]);
  }, [setSelectedGroups, selectedTargetItems]);

  const disableRemoveSelectionBtn =
    (!selectedTargetItems.length || someRequiredSlidesSelected) &&
    // if all the items are selected this will work as clean all
    !(selectedTargetItems.length === selectedGroups.length)

  const disableHideSelectionBtn = !selectedTargetItems.length || hasNeedReviewGroup
  const hideButtonPopoverText = toggleVisibilityText === '' ? 'Hide' : toggleVisibilityText
  const hideButtonText = builderMode === 'selection' ? '' : hideButtonPopoverText

  const onCheckSelectAll = useCallback((isChecked) => {
    setSelectedTargetItems(isChecked ? groupsIds : []);
  }, [groupsIds])

  return (
    <DNABox fill appearance="col">
      <DNABox alignY="center" spacing="between" style={S.containerStyle}>
        {/* LEFT SIDE */}
        <DNABox alignY="center" spacing="sm">
          {/* EXPAND BUTTON */}
          <Iffy is={!isReadOnlyMode}>
            <ExpandButton/>
          </Iffy>
          <DNABox alignY="center" spacing="sm">
            <Iffy is={!!groupsIds.length}>
              <DNABox>
                <DNACheckbox
                  status="primary"
                  testID="select-all-checkbox"
                  disabled={!groupsIds.length}
                  checked={!!groupsIds.length && groupsIds.length === selectedTargetItems.length}
                  onChange={onCheckSelectAll}
                />
              </DNABox>
            </Iffy>
            <DNABox>
              <DNAText
                testID="custom-deck-x-of-x"
                status="flatAlt"
                bold
              >
                {selectedTargetItems.length} of {groupsIds.length} selected</DNAText>
            </DNABox>
            <Iffy is={!!selectedTargetItems.length}>
              <DNABox alignY="center" spacing="sm">
                {/* HIDE BUTTON */}
                <Iffy is={!hasNeedReviewGroup}>
                  <DNAPopover disablePopover={DISABLE_POPOVER}>
                    <DNAPopover.Anchor>
                      <DNAButton
                        size="sm"
                        padding="sm"
                        disabled={disableHideSelectionBtn}
                        iconLeft={toggleVisibilityText === 'Show' ? 'eye-outline' : 'eye-off-outline'}
                        appearance="outline"
                        status="tertiary"
                        onPress={handleVisibilityChange}
                        testID="custom-deck-hide-button"
                      >
                        {hideButtonText}
                      </DNAButton>
                    </DNAPopover.Anchor>
                    <DNAPopover.Content>
                      <DNAText status="basic">{toggleVisibilityText === '' ? 'Hide' : toggleVisibilityText}</DNAText>
                    </DNAPopover.Content>
                  </DNAPopover>
                </Iffy>
                {/* REMOVE BUTTON */}
                <DNAPopover disablePopover={DISABLE_POPOVER}>
                  <DNAPopover.Anchor>
                    <DNAButton
                      size="sm"
                      padding="sm"
                      disabled={disableRemoveSelectionBtn}
                      iconLeft="trash-can-outline"
                      appearance="outline"
                      status="tertiary"
                      onPress={removeSelectedSlides}
                      testID="custom-deck-remove-button"
                    >{builderMode !== 'selection' && 'Remove'}</DNAButton>
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText status="basic">Remove</DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
              </DNABox>
            </Iffy>
          </DNABox>
        </DNABox>
        {/* RIGHT SIDE */}
        <DNABox testID="subheader-action-buttons" spacing="md">
          <VisibilityDropDownMenu />
          <Iffy is={showActionButtons}>
            <ActionButtons disablePreview={disablePreview} />
          </Iffy>
        </DNABox>
      </DNABox>
      <DNADivider />
      <Iffy is={numOfRequiredSlides > 0}>
        <DNABox alignY="center" style={S.containerStyle}>
          <DNAText numberOfLines={1} bold status="danger">
            {numOfRequiredSlides} additional required slide(s) added.
          </DNAText>
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

export default React.memo(SubHeader);
