import { createMachine, spawn } from 'xstate';
import { assign } from '@xstate/immer';
import { v4 } from 'uuid';
import { BroadcastChannel } from 'broadcast-channel';
import equal from 'fast-deep-equal';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import {
  Dimensions,
  PDFMessageTypes,
  PlayerEventSource,
  PlayerMode,
  PPZTransform,
  PresentationContentState,
  SetPresentationMeta,
} from '@alucio/core';
import { AlucioChannel } from '@alucio/lux-ui';
import { RATIOS } from '@alucio/lux-ui/src/components/layout/DNAAspectRatio/DNAAspectRatio';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import { NOTATION_TYPE } from '@alucio/aws-beacon-amplify/src/API';
import * as PW from './playerWrapperTypes';
import * as logger from 'src/utils/logger';
import { playerStateObserver, presentationStateObserver } from './playerWrapperUtils';

export * as PW from './playerWrapperTypes';

const PlayerWrapper = (
  meetingId: string = 'aaaaaa',
  playerMode: PlayerMode,
  enableNew3PC: boolean,
  frameId: string = v4(),
  lockAspectRatio: boolean,
  playerContext: PlayerEventSource,
  enableMultipleIframes?: boolean,
) => createMachine<
  PW.PlayerWrapperContext,
  PW.PlayerWrapperEvents,
  PW.PlayerWrapperState
>(
  {
    predictableActionArguments: false,
    id: 'PlayerWrapper',
    context: {
      aspectRatio: RATIOS['16_9'],
      frameId,
      meetingId,
      playerMode,
      enableNew3PC,
      // We need to create a new channel rather than use AlucioChannel.get otherwise the context and wrapper
      // are using the same channel object you we won't receive any messages
      presentationStateChannel: new BroadcastChannel<PW.PresentationChannelMessage>('PRESENTATION_CHANNEL'),
      openedIFrames: {},
      playerStateChannel: AlucioChannel.get(AlucioChannel.commonChannels.PDF_CHANNEL),
      messageNumber: 0,
      shouldBroadcastPlayerMessages: playerMode === 'INTERACTIVE',
      lockAspectRatio,
      lastPresentedIframeId: frameId,
    },
    initial: 'initialize',
    states: {
      initialize: {
        tags: [PW.Tags.IS_IDLE],
        after: {
          0: {
            description: `
              Prefer "after: 0" to "always". Functionally they are the same but
              "always" is buggy with executing multiple times
              leading to too many init calls which messes up init overall
            `,
            actions: assign(ctx => {
              logger.playerWrapperMachine.info(`Entering player wrapper state machine, frameId: ${ctx.frameId}`)
              if (!ctx.presentationStateObserver) {
                logger.playerWrapperMachine.debug(`Initialize presentation state observer, frameId: ${ctx.frameId}`)
                // [TODO] - Try to get types working (returns an unsub function)
                const actor = spawn(
                  presentationStateObserver(ctx.presentationStateChannel, ctx.meetingId),
                  'presentationStateObserverActor',
                )

                ctx.presentationStateObserver = actor
              }

              if (!ctx.playerStateObserver) {
                logger.playerWrapperMachine.debug(`Initialize player state observer, frameId: ${ctx.frameId}`)
                const actor = spawn(
                  playerStateObserver(ctx.playerStateChannel, ctx.meetingId, playerContext),
                  'playerStateObserverActor',
                )

                ctx.playerStateObserver = actor
              }
            }),
            target: '#PlayerWrapper.idle',
          },
        },
      },
      idle: {
        tags: [PW.Tags.IS_IDLE],
        on: {
          PRESENTATION_STATE_SYNC: {
            target: 'presenting.loading.initializing',
            actions: [
              'logEvent',
              'setPresentableState',
              'updatePlayer',
              'clearPlayerState',
            ],
          },
        },
      },
      presenting: {
        states: {
          loading: {
            initial: 'initializing',
            states: {
              initializing: {
                entry: assign((ctx, event) => {
                  ctx.lastPresentedIframeId = ctx.frameId;
                  ctx.frameId = v4();
                  const evt = event as PW.EVT_PRESENTATION_STATE_SYNC;
                  // IF THE NEW DOCUMENT IS FROM THE SAME SOURCE (CUSTOM DECK),
                  // WE'LL ADD A NEW IFRAME TO THE OPENED IFRAMES LIST
                  if (evt.isFromSameSource && enableMultipleIframes) {
                    logger.playerWrapperMachine.debug('Initializing new document from same source');
                    ctx.openedIFrames = {
                      ...ctx.openedIFrames,
                      [evt.payload.documentVersionId]: {
                        iFrameId: ctx.frameId,
                      },
                    };
                  } else {
                    // IF THE NEW DOCUMENT IS FROM A DIFFERENT SOURCE,
                    // WE'LL RESET THE OPENED IFRAMES TO ONLY INCLUDE THAT NEW DOCUMENT
                    logger.playerWrapperMachine.debug('Initializing new document from different source');
                    ctx.openedIFrames = {
                      [evt.payload.documentVersionId]: {
                        iFrameId: ctx.frameId,
                      },
                    };
                  }
                }),
                on: {
                  PLAYER_ACTION: {
                    cond: 'playerInitialized',
                    actions: [
                      'logEvent',
                      'setPlayerMode',
                    ],
                    target: 'initialized',
                  },
                },
              },
              initialized: {
                always: {
                  cond: (ctx) => !!ctx.presentableState && ctx.presentableState.JWT !== 'PENDING',
                  actions: [
                    'loadDocument',
                  ],
                  target: 'loading',
                },
                on: {
                  PRESENTATION_STATE_SYNC: {
                    actions: [
                      'logEvent',
                      'setPresentableState',
                      'updatePlayer',
                    ],
                  },
                },
              },
              loading: {
                on: {
                  PLAYER_ACTION: [{
                    cond: 'playerDocumentLoaded',
                    target: '#PlayerWrapper.presenting.ready',
                    actions: assign((ctx, evt) => {
                      logger.playerWrapperMachine.debug(`PLAYER_ACTION ${evt.type}`, { ctx, evt })
                      ctx.playerState = cloneDeep(ctx.presentableState)
                      // We always clear out the PPZ state and let the player update
                      // to make sure we're kept in sync
                      ctx.playerState && (ctx.playerState.ppzCoords = undefined)
                    }),
                  }, {
                    cond: 'isPageLoadedEvt',
                    actions: [
                      'logEvent',
                      'resolvePlayerEvent',
                    ],
                  }],
                },
              },
            },
          },
          ready: {
            tags: [PW.Tags.IS_CONTENT_LOADED],
            entry: ctx => {
              if (ctx.shouldBroadcastPlayerMessages) {
                ctx.presentationStateChannel.postMessage({
                  type: 'IFRAME_READY',
                  meetingId: ctx.meetingId,
                  messageNumber: ctx.messageNumber,
                })
              }
            },
            on: {
              SEND_IFRAME_READY: {
                description: 'Send message to notation machine that iframe is ready so it can start syncing notation',
                actions: ctx => {
                  if (ctx.shouldBroadcastPlayerMessages) {
                    ctx.presentationStateChannel.postMessage({
                      type: 'IFRAME_READY',
                      meetingId: ctx.meetingId,
                      messageNumber: ctx.messageNumber,
                    })
                  }
                },
              },
              PLAYER_ACTION: {
                cond: 'isMyIFrameEvt',
                actions: [
                  'logEvent',
                  'resolvePlayerEvent',
                ],
              },
              RELOAD_WEB_DOC: {
                cond: (ctx) => ctx.presentableState?.contentType === FileType.WEB ||
                  ctx.presentableState?.contentType === FileType.HTML,
                actions: [
                  'logEvent',
                  ctx => {
                    const documentVersionId = ctx.presentableState?.documentVersionId
                    documentVersionId && ctx.playerStateChannel.postMessage({
                      type: PDFMessageTypes.RELOAD_IFRAME,
                      frameId: ctx.frameId,
                      value: documentVersionId,
                    })
                  },
                ],
              },
              // Presentation Provider (state) -> State Machine
              PRESENTATION_STATE_SYNC: [
                {
                  cond: 'loadNewDocument',
                  target: '#PlayerWrapper.presenting.loading.initializing',
                  actions: [
                    'logEvent',
                    'setPresentableState',
                    'updatePlayer',
                    'clearPlayerState',
                  ],
                },
                {
                  cond: 'presentationStateChanged',
                  actions: [
                    'logEvent',
                    'setPresentableState',
                    'updatePlayer',
                    'applyPresentableState',
                  ],
                },
              ],
              TOGGLE_HIGHLIGHTER: {
                actions: 'toggleHighlighter',
              },
              TOGGLE_NOTATIONS: {
                actions: 'toggleNotations',
              },
              TOGGLE_COORDINATE_MODE: {
                actions: 'toggleCoordinateMode',
              },
              SEND_NOTATION_COORDINATE: {
                cond: 'matchedFrameId',
                actions: 'sendNotationCoordinate',
              },
              SYNC_CURRENT_ACTIVE_NOTATION: {
                cond: 'matchedFrameId',
                actions: 'syncCurrentActiveNotation',
              },
              SET_EDIT_NOTATION: {
                cond: 'matchedFrameId',
                actions: 'setEditNotation',
              },
            },
          },
        },
      },
    },
    on: {
      PRESENTATION_STATE_IDLE:
      {
        cond: 'isMyMeetingEvt',
        target: 'idle',
        actions: [
          'logEvent',
          'setPresentableState',
          'updatePlayer',
          'clearPlayerState',
        ],
      },
    },
  },
  {
    guards: {
      isMyIFrameEvt: (ctx, event) => {
        const evt = event as PW.EVT_PLAYER_ACTION
        return ctx.frameId === evt.payload.frameId
      },
      isMyMeetingEvt: (ctx, event) => {
        const evt = event as (PW.EVT_PRESENTATION_STATE_IDLE | PW.EVT_PRESENTATION_STATE_SYNC)
        return ctx.meetingId === evt.meetingId
      },
      isPageLoadedEvt: (ctx, event) => {
        const evt = event as PW.EVT_PLAYER_ACTION;
        return evt.payload.frameId === ctx.frameId && evt.payload.type === 'PDF_PAGE_LOADED';
      },
      loadNewDocument: (ctx, event) => {
        const evt = event as PW.EVT_PRESENTATION_STATE_SYNC
        const isSameMeeting = ctx.meetingId === evt.meetingId
        const hasDocumentVersionId = !!evt.payload.documentVersionId && !!ctx?.playerState?.documentVersionId
        const isPresentableIdDifferent = ctx?.presentableState?.documentVersionId !== evt.payload.documentVersionId
        const isPlayerStateIdDifferent = ctx?.playerState?.documentVersionId !== evt.payload.documentVersionId
        const isDifferentDocument = isPresentableIdDifferent || isPlayerStateIdDifferent;
        const hasAnOpenedIframe = !!ctx.openedIFrames[evt.payload.documentVersionId];
        const isDifferentButAlreadyOpenedDocument = isDifferentDocument && hasAnOpenedIframe;

        // DETECTS IF WE NEED TO MODIFY THE OPENED IFRAMES LIST //
        if (isSameMeeting && hasDocumentVersionId) {
          // SAME DOCUMENT FROM DIFFERENT SOURCE: RESET OPENED IFRAMES
          if (!isDifferentDocument && !evt.isFromSameSource) {
            logger.playerWrapperMachine.debug('Detected new source of same document. Resetting the opened iframes');
            ctx.openedIFrames = {
              [evt.payload.documentVersionId]: {
                iFrameId: ctx.frameId,
                aspectRatio: ctx.openedIFrames[evt.payload.documentVersionId]?.aspectRatio,
              },
            };
          }
          // DIFFERENT DOCUMENT FROM SAME SOURCE: IF IT'S ALREADY OPENED, USE THE EXISTING IFRAME
          if (isDifferentButAlreadyOpenedDocument && evt.isFromSameSource) {
            logger.playerWrapperMachine
              .debug('Detected change of document (already opened) from same source. Using existing iframe');
            const existingFrameId = ctx.openedIFrames[evt.payload.documentVersionId]?.iFrameId;
            ctx.lastPresentedIframeId = ctx.frameId;
            ctx.frameId = existingFrameId;
          }
        }

        if (!ctx.lockAspectRatio) {
          ctx.aspectRatio = ctx.openedIFrames[evt.payload.documentVersionId]?.aspectRatio || ctx.aspectRatio;
        }

        return (
          isSameMeeting &&
          hasDocumentVersionId &&
          isDifferentDocument &&
          !hasAnOpenedIframe
        )
      },
      presentationStateChanged: (ctx, event) => {
        const evt = event as PW.EVT_PRESENTATION_STATE_SYNC
        logger
          .playerWrapperMachine
          .debug('checking presentation state changed', { existing: ctx.presentableState, new: evt.payload })
        return evt.meetingId === ctx.meetingId && !equal(ctx.presentableState, evt.payload)
      },
      playerInitialized: (ctx, event) => {
        const evt = event as PW.EVT_PLAYER_ACTION
        return evt.payload.frameId === ctx.frameId && evt.payload.type === 'IFRAME_LOADED'
      },
      playerDocumentLoaded: (ctx, event) => {
        const evt = event as PW.EVT_PLAYER_ACTION
        return evt.payload.frameId === ctx.frameId && evt.payload.type === 'DOCUMENT_LOADED'
      },
      matchedFrameId: (ctx, event) => {
        const evt = event as PW.EVT_SEND_NOTATION_COORDINATE | PW.EVT_SYNC_CURRENT_ACTIVE_NOTATION
        /* If event contains frameId, it means the message is coming from the playerStateChannel and we need to verify if it matches,
        else the message is from presentationStateChannel and we already verified the meetingId in the observer so we can return true here. */
        if (evt.frameId) return evt.frameId === ctx.frameId
        else return true
      },
    },
    actions: {
      logEvent: (ctx, evt, meta) => {
        logger.playerWrapperMachine.debug(evt.type, { ctx, evt, meta })
      },
      loadDocument: (ctx, _, __) => {
        logger.playerWrapperMachine.debug('Load document and sending LOAD_DOCUMENT message')
        const contentState = ctx.presentableState
        if (contentState) {
          const msg = {
            type: PDFMessageTypes.LOAD_FILE,
            frameId: ctx.frameId,
            value: {
              JWT: contentState.JWT,
              bucket: contentState.bucket,
              docPath: contentState.docPath,
              documentVersionId: contentState.documentVersionId,
              documentId: contentState.documentId,
              groupId: contentState.groupId,
              contentType: contentState.contentType,
              visiblePages: contentState.visiblePages,
              contentURL: contentState.contentURL,
              state: {
                page: contentState.state.page,
                step: contentState.state.step,
                viewport: contentState.ppzCoords,
              },
              notations: contentState.notations,
            },
          }
          ctx.playerStateChannel.postMessage(msg)
        }
      },
      clearPlayerState: assign((ctx) => {
        ctx.playerState = undefined
      }),
      // Beacon Provider (Updated State) -> Sync to machine -> Resolves what to do
      applyPresentableState: assign((ctx, event, __) => {
        logger.playerWrapperMachine.debug('Apply presentable state')
        if (ctx.presentableState && ctx.playerState) {
          const presentableState = ctx.presentableState
          const playerState = ctx.playerState
          // If groups change, update meta which controls show/hide specific slides to make groups function
          if (presentableState.groupId !== playerState.groupId) {
            logger.playerWrapperMachine.debug('Detected GroupID Change from Presentable')
            const payload: SetPresentationMeta = {
              groupId: presentableState.groupId,
              slide: presentableState.state.page,
              step: presentableState.state.step,
              visiblePages: [...presentableState.visiblePages],
            }
            ctx.playerStateChannel.postMessage({
              type: PDFMessageTypes.SET_PRESENTATION_META,
              frameId: ctx.frameId,
              value: payload,
            })
            playerState.groupId = presentableState.groupId
            playerState.state = presentableState.state
            playerState.visiblePages = presentableState.visiblePages
          }
          // If beacon master state is different from player state
          // Force a sync to the player (beacon master overrides player state)
          // Note: we don't want to send a SET_STATE msg to the player upon an
          // update of the totalSteps
          else if (!equal(omit(presentableState.state, ['totalSteps']), omit(playerState.state, ['totalSteps']))) {
            logger.playerWrapperMachine.debug('Detected Presentation State (Progress) Change from Presentable')
            if (ctx.messageNumber === event.messageNumber || ctx.playerMode !== 'INTERACTIVE') {
              const payload: PresentationContentState = {
                documentVersionId: presentableState.documentVersionId,
                groupId: presentableState.groupId,
                page: presentableState.state.page,
                step: presentableState.state.step,
                totalSteps: presentableState.state.totalSteps,
                viewport: {
                  positionX: presentableState.ppzCoords?.positionX ?? 0,
                  positionY: presentableState.ppzCoords?.positionY ?? 0,
                  scale: presentableState.ppzCoords?.scale ?? 1,
                },
              }
              ctx.playerStateChannel.postMessage({
                type: PDFMessageTypes.SET_PRESENTATION_STATE,
                frameId: ctx.frameId,
                value: payload,
              })
            }
            playerState.state.page = presentableState.state.page
            playerState.state.step = presentableState.state.step
          }
          // If zoom coordinates are different, force player state to take beacon's last known zoom state
          else if (!equal(presentableState.ppzCoords, playerState.ppzCoords) &&
            presentableState.ppzCoords && ctx.playerMode !== 'INTERACTIVE') {
            logger.playerWrapperMachine.debug('Detected PPZ Change from Presentable')
            const payload: PPZTransform = {
              positionX: presentableState.ppzCoords.positionX,
              positionY: presentableState.ppzCoords.positionY,
              scale: presentableState.ppzCoords.scale,
            }
            ctx.playerStateChannel.postMessage({
              type: PDFMessageTypes.PPZ_TRANSFORM,
              frameId: ctx.frameId,
              value: payload,
            })
          } else if (presentableState.JWT !== playerState.JWT) {
            logger.playerWrapperMachine.debug('Detected ContentAccessToken Change from Presentable');
            ctx.playerStateChannel.postMessage({
              type: PDFMessageTypes.SET_CONTENT_ACCESS_TOKEN,
              frameId: ctx.frameId,
              value: presentableState.JWT,
            });
            playerState.JWT = presentableState.JWT;
          }
        } else {
          logger.playerWrapperMachine.debug('No changes detected between Presentable and Player State')
        }
      }),
      resolvePlayerEvent: assign((ctx, event) => {
        const evt = event as PW.EVT_PLAYER_ACTION
        const shouldBroadcast = ctx.shouldBroadcastPlayerMessages
        logger.playerWrapperMachine.debug(`Resolve player event: ${evt.payload.type}`)
        switch (evt.payload.type) {
          case 'PDF_PAGE_LOADED': {
            // Temporary fix for BEAC-3025 by removing this we lock to 16:9
            // and avoid timing issues of switching between windows
            if (!ctx.lockAspectRatio) {
              const pageBounds = evt.payload.value as Dimensions
              const pageRatio = parseFloat(
                (pageBounds.height / pageBounds.width).toFixed(2),
              )

              if (ctx.presentableState?.contentType === FileType.WEB) {
                ctx.aspectRatio = RATIOS.FULL
              } else {
                ctx.aspectRatio = pageRatio === RATIOS['4_3']
                  ? RATIOS['4_3']
                  : RATIOS['16_9']
              }
              // SETS THE ASPECT RATIO TO THE APPROPRIATE DOC SETTING //
              Object.entries(ctx.openedIFrames).forEach(([documentVersionId, iFrameSetting]) => {
                if (iFrameSetting.iFrameId === evt.payload.frameId) {
                  ctx.openedIFrames[documentVersionId].aspectRatio = ctx.aspectRatio;
                }
              });
              break
            }
            break
          }
          case 'NAVIGATE_PAST_FIRST': {
            logger.playerWrapperMachine.debug('Broadcasting NAVIGATE_PAST_FIRST')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'NAVIGATE_PAST_FIRST',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'NAVIGATE_PAST_LAST': {
            logger.playerWrapperMachine.debug('Broadcasting NAVIGATE_PAST_LAST')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'NAVIGATE_PAST_LAST',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'SHOW_SEARCH': {
            logger.playerWrapperMachine.debug('Broadcasting SHOW_SEARCH')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'SHOW_SEARCH',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'SHOW_MY_CONTENT': {
            logger.playerWrapperMachine.debug('Broadcasting SHOW_MY_CONTENT')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'SHOW_MY_CONTENT',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'PPZ_TRANSFORM': {
            logger.playerWrapperMachine.debug('Handling PPZ Transform')
            const newPPZ = evt.payload.value as PPZTransform
            if (ctx.playerState) {
              ctx.playerState.ppzCoords = newPPZ
            }
            if (!equal(ctx.presentableState?.ppzCoords, newPPZ)) {
              logger.playerWrapperMachine.debug('Broadcasting PPZ Change', newPPZ)
              shouldBroadcast && ctx.presentationStateChannel.postMessage({
                type: 'PPZ_TRANSFORM',
                meetingId: ctx.meetingId,
                presentableGroupId: ctx.playerState?.groupId ?? '',
                payload: newPPZ,
              })
            }
            break
          }
          case 'PAGE_CHANGE': {
            const newState = evt.payload.value as PresentationContentState
            if (ctx.playerState) {
              ctx.playerState.state.page = newState.page ?? ctx.playerState.state.page
              ctx.playerState.state.step = newState.step ?? ctx.playerState.state.step
            }
            if (newState.page !== ctx.presentableState?.state.page ||
              newState.step !== ctx.presentableState?.state.step ||
              newState.totalSteps !== ctx.presentableState?.state.totalSteps) {
              const payload = {
                page: newState.page ?? 0,
                step: newState.step ?? 0,
                groupId: newState.groupId ?? '',
                totalSteps: newState.totalSteps ?? 0,
              }
              logger.playerWrapperMachine.debug('Broadcasting PRESENTATION_PROGRESS', payload)
              ctx.messageNumber = ctx.messageNumber ? ctx.messageNumber + 1 : 1;
              shouldBroadcast && ctx.presentationStateChannel.postMessage({
                type: 'PRESENTATION_PROGRESS',
                meetingId: ctx.meetingId,
                payload: payload,
                messageNumber: ctx.messageNumber,
              })
            }
            break
          }
          default: {
            logger.playerWrapperMachine.warn(`Unrecognized Player Event ${evt.payload.type} encountered`)
          }
        }
      }),
      setPlayerMode: (ctx) => {
        logger.playerWrapperMachine.debug('Set player mode')
        ctx.playerStateChannel.postMessage({
          type: PDFMessageTypes.PLAYER_MODE,
          frameId: ctx.frameId,
          value: {
            mode: ctx.playerMode,
            enableNew3PC: ctx.enableNew3PC,
          },
        })
      },
      setPresentableState: assign((ctx, event) => {
        logger.playerWrapperMachine.debug('Set presentable state')
        const evt = event as PW.EVT_PRESENTATION_STATE_SYNC
        ctx.presentableState = evt.payload
      }),
      updatePlayer: assign((ctx, event) => {
        logger.playerWrapperMachine.debug('Update player')
        if (ctx.playerMode === 'NON_INTERACTIVE') return
        const evt = event as PW.EVT_PRESENTATION_STATE_SYNC
        /* SYNC PLAYER FOR NOTATION */
        if (evt.shouldUpdatePlayer) {
          const msg = {
            type: PDFMessageTypes.UPDATE_NOTATIONS,
            frameId: ctx.frameId,
            value: {
              notations: evt.payload.notations ?? [],
            },
          }
          ctx.playerStateChannel.postMessage(msg)
        }
      }),
      toggleHighlighter: assign((ctx, event) => {
        logger.playerWrapperMachine.debug('Toggle highlighter')
        const contentState = ctx.presentableState
        if (contentState) {
          // TODO: Remove as in a followiing ticket
          const evt = event as PW.TOGGLE_HIGHLIGHTER
          const isEnabled = evt.highlighterVisible
          const msg = {
            type: PDFMessageTypes.TOGGLE_HIGHLIGHTER,
            frameId: ctx.frameId,
            value: isEnabled,
          }
          ctx.playerStateChannel.postMessage(msg)
        }
      }),
      toggleNotations: assign((ctx, event) => {
        logger.playerWrapperMachine.debug('Toggle notations')
        if (ctx.playerMode === 'NON_INTERACTIVE') return
        const contentState = ctx.presentableState
        if (contentState) {
          const evt = event as PW.EVT_TOGGLE_NOTATIONS
          const isEnabled = evt.toggleOn
          const msg = {
            type: PDFMessageTypes.TOGGLE_NOTATIONS,
            frameId: ctx.frameId,
            value: {
              notationType: isEnabled ? evt.notationType || NOTATION_TYPE.CALLOUT : undefined,
              value: isEnabled,
            },
          }
          ctx.playerStateChannel.postMessage(msg)
        }
      }),
      toggleCoordinateMode: assign((ctx, event) => {
        logger.playerWrapperMachine.debug('Toggle coordinate mode')
        if (ctx.playerMode === 'NON_INTERACTIVE') return
        const contentState = ctx.presentableState
        if (contentState) {
          const evt = event as PW.EVT_TOGGLE_COORDINATE_MODE
          const isEnabled = evt.toggleOn
          const msg = {
            type: PDFMessageTypes.TOGGLE_COORDINATE_MODE,
            frameId: ctx.frameId,
            value: isEnabled,
          }
          ctx.playerStateChannel.postMessage(msg)
        }
      }),
      sendNotationCoordinate: assign((ctx, event) => {
        logger.playerWrapperMachine.debug('Send notation coordinate')
        if (ctx.playerMode === 'NON_INTERACTIVE') return
        const contentState = ctx.presentableState
        if (contentState) {
          const evt = event as PW.EVT_SEND_NOTATION_COORDINATE
          ctx.presentationStateChannel.postMessage({
            type: 'SEND_NOTATION_COORDINATE',
            meetingId: ctx.meetingId,
            coordinate: evt.coordinate,
            pageId: evt.pageId,
          })
        }
      }),
      setEditNotation: assign((ctx, event) => {
        logger.playerWrapperMachine.debug('Set current edit notation')
        if (ctx.playerMode === 'NON_INTERACTIVE') return
        const evt = event as PW.EVT_SET_EDIT_NOTATION;
        if (evt.fromPlayer) {
          ctx.presentationStateChannel.postMessage({
            type: 'SET_EDIT_NOTATION',
            meetingId: ctx.meetingId,
            notationId: evt.notationId,
          })
        }
      }),
      syncCurrentActiveNotation: assign((ctx, event) => {
        logger.playerWrapperMachine.debug('Sync current active notation')
        if (ctx.playerMode === 'NON_INTERACTIVE') return
        const contentState = ctx.presentableState
        const evt = event as PW.EVT_SYNC_CURRENT_ACTIVE_NOTATION
        if (evt.fromPlayer) {
          ctx.presentationStateChannel.postMessage({
            type: 'SYNC_CURRENT_ACTIVE_NOTATION',
            meetingId: ctx.meetingId,
            notationId: evt.notationId,
          })
        }
        else if (contentState) {
          ctx.playerStateChannel.postMessage({
            type: 'SYNC_CURRENT_ACTIVE_NOTATION',
            frameId: ctx.frameId,
            value: evt.notationId ?? '',
          })
        }
      }),
    },
  },
)

export default PlayerWrapper
