import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { ScrollView, TouchableOpacity } from 'react-native'
import { useDispatch } from 'src/state/redux'
import { userNotationsActions, CreateUserNotationsPayload } from 'src/state/redux/slice/userNotations'
import { DNAButton, DNAModal, DNAText, DNABox, DNADivider } from '@alucio/lux-ui'
import { DNAModalVariant } from './DNAConnectedModal'
import type { DocumentORM, DocumentVersionORM, CustomDeckORM } from 'src/types/orms'
import { filterCollection, sortCollection } from 'src/state/redux/selector/common'
import { Notation, CustomDeck, Folder } from '@alucio/aws-beacon-amplify/src/models'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import type { PageData } from 'src/types/staticAssets'
import { datastoreSave } from 'src/state/redux/slice/common'
import type { TargetFolderDocVerUpgrades } from 'src/state/redux/saga'
import { isCustomDeckORM, isDocumentVersionORM, isFolderORM } from 'src/types/typeguards'
import { useAllDocumentVersionsMap } from 'src/state/redux/selector/document'

interface LibraryProps {
  newNotations: CreateUserNotationsPayload[],
  documentORMs: DocumentORM[],
  pageData: Record<string, PageData>,
  controlFlow: {
    resolve: (value: unknown) => void,
    reject: (value: unknown) => void,
  }
}

const NotationThumbnail: React.FC<{
  docVerORM?: DocumentVersionORM,
  notation?: Notation,
  pageId?: string,
  showDocVerId?: boolean,
}> = (props) => {
  const { docVerORM, notation, pageId, showDocVerId } = props

  if (!docVerORM)
    return null

  const pageNumber = (pageId ?? notation?.pageId ?? '').split('_').at(-1)

  if (!pageNumber) {
    return (
      <DNAText>
        Could not render thumbnail
      </DNAText>
    )
  }

  const s3Key = detectArchivedFileKeyPath(
    docVerORM.model,
    {
      number: Number(pageNumber) ?? 0,
      pageId: pageId ?? notation?.pageId ?? 'Uh oh',
    },
    'sm'
  );

  return (
    <DNABox appearance='col'>
      {showDocVerId && <DNAText>{docVerORM.model.id}</DNAText>}
      <DNAThumbnail
        key={s3Key}
        s3URL={s3Key}
        size='md'
        variant={DNAThumbnail.Variants.INFO}
        pageNumber={Number(pageNumber)}
      />
      <DNAText>{notation?.description ?? '(No notation desc)'}</DNAText>
    </DNABox>
  )
}

export const LibraryNotationMigration: DNAModalVariant<LibraryProps> = (props) => {
  const {
    newNotations,
    controlFlow,
    documentORMs,
    pageData
  } = props;
  const [iteration, setIteration] = useState<number>(0)
  const dispatch = useDispatch()

  const currentNotation = newNotations[iteration]

  useEffect(
    () => {
      if (!newNotations.length) {
        controlFlow.reject('No items passed')
      }

      if (!currentNotation) {
        controlFlow.resolve('Finished resolving migrations')
      }
    },
    [newNotations, iteration]
  )

  const currentDocument = useMemo(
    () => {
      return documentORMs.find(docORM => docORM.model.id === currentNotation?.documentId)
    },
    [iteration, documentORMs, newNotations]
  )

  const publishedVersions = useMemo<{
    latestPublishedVersion?: DocumentVersionORM,
    prevPublishedVersion?: DocumentVersionORM
  }>(
    () => {
      if (!currentDocument) {
        return {
          latestPublishedVersion: undefined,
          prevPublishedVersion: undefined,
        }
      }

      const filtered = filterCollection<DocumentVersionORM>(
        currentDocument.relations.documentVersions,
        { filter: { model: { status: 'PUBLISHED' } } }
      )

      const [latestPublishedVersion, prevPublishedVersion] = sortCollection<DocumentVersionORM>(
        filtered,
        {
          sort: [
            { model: { versionNumber: 'desc' } }
          ]
        }
      )

      return {
        latestPublishedVersion,
        prevPublishedVersion,
      }
    },
    [currentDocument]
  )

  const migrationAction = useCallback(
    (accept: boolean) => () => {
      if (accept)
        dispatch(userNotationsActions.createUserNotations(newNotations[iteration]))
      setIteration(p => p + 1)
    },
    [iteration]
  )

  if (!currentNotation)
    return null

  return (
    <DNAModal
      style={{ width: '95dvw', height: '80dvh', flex: 1 }}
      // @ts-ignore
      childFill={1}
    >
      <DNAModal.Header>
        <DNAText>
          Library Notation Debug
        </DNAText>
      </DNAModal.Header>
      <DNAModal.Body style={{ padding: 24, flex: 1 }}>
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }}>
          <DNABox appearance='col' fill spacing='xl'>
            <DNABox appearance='row' childFill spacing='sm'>
              {/* PREV NOTATIONS */}
              <DNABox appearance='col' fill alignX='center'>
                <DNAText h2>
                  Previous Notations Ver (
                  {
                    (
                      publishedVersions.prevPublishedVersion?.model.semVer?.major + '.' +
                      publishedVersions.prevPublishedVersion?.model.semVer?.minor
                    )
                  }
                  )
                </DNAText>
                <DNABox appearance='col' spacing='md'>
                  {
                    publishedVersions.prevPublishedVersion?.relations.userNotations?.notation.map(notation => (
                      <NotationThumbnail
                        key={notation.id}
                        docVerORM={publishedVersions.prevPublishedVersion}
                        notation={notation}
                      />
                    )) ?? <DNAText>No Notations Founds</DNAText>
                  }
                </DNABox>
              </DNABox>

              {/* NEW NOTATIONS */}
              <DNABox appearance='col' fill alignX='center'>
                <DNAText h2>
                  New Notations Ver (
                  {
                    (
                      publishedVersions.latestPublishedVersion?.model.semVer?.major + '.' +
                      publishedVersions.latestPublishedVersion?.model.semVer?.minor
                    )
                  }
                  )
                </DNAText>
                <DNABox appearance='col' spacing='md'>
                  {
                    newNotations[iteration]
                      .notation
                      .sort((a, b) => a.pageId.localeCompare(b.pageId))
                      .map(notation =>
                        <NotationThumbnail
                          key={notation.id}
                          docVerORM={publishedVersions.prevPublishedVersion}
                          notation={notation}
                        />
                      ) ?? <DNAText>No Notations Founds</DNAText>
                  }
                </DNABox>
              </DNABox>
            </DNABox>
            {/* DEBUG Page */}
            <DNABox appearance='col' alignX='center'>
              <DNAText h2>Pages.json (Latest published Ver Mappings)</DNAText>
              <DNAText>
                {
                  publishedVersions.latestPublishedVersion?.model.id
                    ? JSON.stringify(
                      pageData[publishedVersions.latestPublishedVersion.model.id]
                        .pages
                        .filter(Boolean)
                        .map(page => ({
                          prevPageToMapTo: page?.mapping?.split('_').at(-1),
                          pageNumber: page?.number,
                          mapping: page?.mapping,
                        })),
                      null,
                      4
                    )
                    : "Could not find Page Data"
                }
              </DNAText>
            </DNABox>
          </DNABox>

        </ScrollView>
      </DNAModal.Body>
      <DNAModal.Confirmation style={{ paddingVertical: 12, paddingHorizontal: 24 }}>
        <DNAButton appearance="filled" status="success" onPress={migrationAction(true)}>
          Accept
        </DNAButton>
        <DNAButton appearance="outline" status="warning" onPress={migrationAction(false)}>
          Skip
        </DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

interface CustomDeckProps {
  newNotations: CreateUserNotationsPayload[],
  customDeckORMs: CustomDeckORM[],
  pageData: Record<string, PageData>,
  newCustomDecks: Partial<CustomDeck>[],
  controlFlow: {
    resolve: (value: unknown) => void,
    reject: (value: unknown) => void,
  }
}

export const CustomDeckNotationMigration: DNAModalVariant<CustomDeckProps> = (props) => {
  const {
    newNotations,
    newCustomDecks,
    controlFlow,
    customDeckORMs,
    pageData
  } = props;
  const [iteration, setIteration] = useState<number>(0)
  const [activePagesVersion, setActivePagesVersion] = useState<string | undefined>(
    newCustomDecks?.at(0)
      ?.groups?.at(0)
      ?.pages?.at(0)
      ?.documentVersionId
  )
  const dispatch = useDispatch()

  const currentCustomDeck = newCustomDecks[iteration]

  const customDecks = useMemo<{ curr?: CustomDeck, prev?: CustomDeckORM }>(
    () => {
      if (!currentCustomDeck) {
        return {
          curr: undefined,
          prev: undefined,
        }
      }

      const deckORM = customDeckORMs.find(
        deckORM => deckORM.model.id === currentCustomDeck.id
      )

      return {
        curr: {
          ...deckORM?.model,
          ...currentCustomDeck,
        } as CustomDeck,
        prev: deckORM,
      }
    },
    [customDeckORMs, newCustomDecks]
  )

  const currentNotations = useMemo(
    () => newNotations.find(userNotation => userNotation.customDeckId === customDecks.curr?.id),
    [customDecks]
  )

  useEffect(
    () => {
      if (!newNotations.length && !newCustomDecks.length) {
        controlFlow.reject('No items passed')
      }

      if (!currentCustomDeck) {
        controlFlow.resolve('Finished resolving migrations')
      }
    },
    [newCustomDecks, newNotations, iteration]
  )

  const migrationAction = useCallback(
    (accept: boolean) => () => {
      if (accept) {
        datastoreSave(
          CustomDeck,
          customDecks.prev!.model,
          customDecks.curr!
        )

        if (currentNotations?.notation.length) {
          dispatch(userNotationsActions.createUserNotations(currentNotations))
        }
      }

      setIteration(p => p + 1)
    },
    [iteration, customDecks]
  )

  if (!currentCustomDeck || !customDecks.prev || !customDecks.curr) {
    return null
  }

  return (
    <DNAModal
      style={{ width: '95dvw', height: '80dvh', flex: 1 }}
      // @ts-ignore
      childFill={1}
    >
      <DNAModal.Header>
        <DNAText>
          CustomDeck Notation Debug
        </DNAText>
      </DNAModal.Header>
      <DNAModal.Body style={{ padding: 24, flex: 1 }}>
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }}>
          <DNABox appearance='col' fill spacing='xl'>
            <DNABox appearance='row' childFill spacing='sm'>
              {/* PREV NOTATIONS */}
              <DNABox appearance='col' fill alignX='center'>
                <DNAText h2>
                  Prev Custom Deck ({customDecks.prev.model.title})
                </DNAText>
                <DNABox appearance='col' spacing='md'>
                  {
                    customDecks
                      .prev
                      .meta
                      .customDeckGroups
                      .map(groupORM => groupORM
                        .pages
                        .map(pageORM => (
                          <NotationThumbnail
                            key={groupORM.model.id + pageORM.model.pageId}
                            docVerORM={groupORM.pages.at(0)?.documentVersionORM}
                            showDocVerId
                            pageId={pageORM.model.pageId}
                            notation={customDecks
                              .prev
                              ?.relations
                              .userNotations
                              ?.notation
                              .find(notation => notation.pageId === pageORM.model.pageId)
                            }
                          />
                        )
                        ))
                  }
                </DNABox>
              </DNABox>

              {/* NEW NOTATIONS */}
              <DNABox appearance='col' fill alignX='center'>
                <DNAText h2>
                  New Custom Deck ({customDecks.curr?.title})
                </DNAText>
                <DNABox appearance='col' spacing='md'>
                  {
                    customDecks
                      .curr
                      .groups
                      .map(group => group
                        .pages
                        .map(page => (
                          <TouchableOpacity onPress={() => setActivePagesVersion(page.documentVersionId)}>
                            <NotationThumbnail
                              key={group.id + page.pageId}
                              showDocVerId
                              pageId={page.pageId}
                              docVerORM={customDecks
                                .prev
                                ?.meta
                                .customDeckGroups
                                .find(groupORM => groupORM.model.id === group.id)
                                ?.pages.at(0)
                                ?.documentVersionORM
                                .relations
                                .documentORM
                                .relations
                                .version
                                .latestPublishedDocumentVersionORM
                              }
                              notation={
                                newNotations
                                  .find(userNotation => userNotation.customDeckId === customDecks.curr?.id)
                                  ?.notation
                                  .find(notation => notation.pageId === page.pageId)
                              }
                            />
                          </TouchableOpacity>
                        )
                        )
                      )
                  }
                </DNABox>
              </DNABox>
            </DNABox>
            {/* DEBUG Page */}
            <DNABox appearance='col' alignX='center'>
              <DNAText h2>Pages.json ({activePagesVersion ?? 'No DocVerId found'})</DNAText>
              <DNAText>
                {
                  (activePagesVersion && pageData[activePagesVersion])
                    ? (
                      JSON.stringify(
                        pageData[activePagesVersion]
                          .pages
                          .filter(Boolean)
                          .map(page => ({
                            prevPageToMapTo: page?.mapping?.split('_').at(-1),
                            pageNumber: page?.number,
                            mapping: page?.mapping,
                          })),
                        null,
                        4
                      ))
                    : "Could not find Page Data, this DocVersion was probably not eligible for an upgrade"
                }
              </DNAText>
            </DNABox>
          </DNABox>

        </ScrollView>
      </DNAModal.Body>
      <DNAModal.Confirmation style={{ paddingVertical: 12, paddingHorizontal: 24 }}>
        <DNAButton
          appearance="filled"
          status="success"
          onPress={migrationAction(true)}
        >
          Accept (Slide Mappings {currentNotations?.notation.length ? ' + Notations' : ' Only'})
        </DNAButton>
        <DNAButton
          appearance="outline"
          status="warning"
          onPress={migrationAction(false)}
        >
          Skip
        </DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

interface DocVerProps {
  folderTargets: Array<TargetFolderDocVerUpgrades>,
  controlFlow: {
    resolve: (value: unknown) => void,
    reject: (value: unknown) => void,
  }
}

export const FolderDocVerUpgrade: DNAModalVariant<DocVerProps> = (props) => {
  const { folderTargets, controlFlow } = props;
  const [iteration, setIteration] = useState<number>(0)
  const docVerMap = useAllDocumentVersionsMap()

  const currentFolder = folderTargets[iteration]

  useEffect(
    () => {
      if (!folderTargets.length) {
        controlFlow.reject('No items passed')
      }

      if (!currentFolder) {
        controlFlow.resolve('Finished resolving migrations')
      }
    },
    [folderTargets, iteration]
  )

  const migrationAction = useCallback(
    (accept: boolean) => () => {
      if (accept) {
        datastoreSave<Folder>(
          Folder,
          currentFolder.folderORM.model,
          { items: currentFolder.folderItemsUpdatePayload }
        )
      }
      setIteration(p => p + 1)
    },
    [currentFolder, iteration]
  )

  if (!currentFolder) {
    return null
  }

  return (
    <DNAModal
      style={{ width: '95dvw', height: '80dvh', flex: 1 }}
      // @ts-ignore
      childFill={1}
    >
      <DNAModal.Header>
        <DNAText>
          Folder DocVer Debug
        </DNAText>
      </DNAModal.Header>
      <DNAModal.Body style={{ padding: 24, flex: 1 }}>
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }}>
          <DNABox appearance='col' fill spacing='xl'>
            <DNABox appearance='row' childFill spacing='sm'>
              {/* PREV NOTATIONS */}
              <DNABox appearance='col' fill alignX='center'>
                <DNAText h2>
                  Prev Folder Items - { currentFolder.folderORM.model.name}
                </DNAText>
                <DNABox appearance='col' spacing='md'>
                  {
                    currentFolder.folderORM.relations.items.map((item) => {
                      const itemORM = item.relations.itemORM
                      const isDocumentVersion = isDocumentVersionORM(itemORM)
                      const isFolder = isFolderORM(itemORM)
                      const title = item.model.customTitle ?? (
                        isDocumentVersion
                          ? itemORM.model.title
                          : isFolder
                            ? itemORM.model.name
                            : itemORM.model.title
                      )

                      return (
                        <DNABox appearance='col'>
                          <DNAText bold>Title: {`\t\t`}{title ?? 'Unknown Title'}</DNAText>
                          <DNAText>Item Id: {`\t`}{itemORM.model.id}</DNAText>
                          <DNAText>Type: {`\t\t`}{itemORM.type}</DNAText>
                          <DNAText>Status: {`\t`}{item.model.status ?? 'Unknown'}</DNAText>
                          <DNAText>Ack At: {`\t`}{item.model.updateAcknowledgedAt ?? '(Not acknowledged)'}</DNAText>
                          <DNADivider />
                        </DNABox>
                      )                      
                    })
                  }
                </DNABox>
              </DNABox>

              {/* NEW NOTATIONS */}
              <DNABox appearance='col' fill alignX='center'>
                <DNAText h2>
                  Next Folder Items - { currentFolder.folderORM.model.name}
                </DNAText>
                <DNABox appearance='col' spacing='md'>
                  {
                    currentFolder.folderItemsUpdatePayload.map((item) => {                      
                      const originalItemORM = currentFolder
                        .folderORM
                        .relations
                        .items
                        .find(ogItem => ogItem.model.id === item.id)
                        ?.relations
                        .itemORM
                      const isCustomDeck = isCustomDeckORM(originalItemORM)
                      const isFolder = isFolderORM(originalItemORM)
                      const latestDocVerTitle = docVerMap[item.itemId.split('_').at(0) ?? '']
                        ?.find(docVer => docVer.id === item.itemId)
                        ?.title

                      const title = (isCustomDeck
                        ? originalItemORM.model.title
                        : isFolder
                          ? originalItemORM.model.name
                          : latestDocVerTitle
                      ) ?? ('Could not find item title')

                      return (
                        <DNABox appearance='col'>
                          <DNAText bold>Title: {`\t\t`}{title}</DNAText>
                          <DNAText>Item Id: {`\t`}{item.itemId}</DNAText>
                          <DNAText>Type: {`\t\t`}{item.type}</DNAText>
                          <DNAText>Status: {`\t`}{item.status ?? 'Unknown'}</DNAText>
                          <DNAText>Ack At: {`\t`}{item.updateAcknowledgedAt ?? '(Not acknowledged)'}</DNAText>
                          <DNADivider />
                        </DNABox>
                      )
                    })
                  }
                </DNABox>
              </DNABox>
            </DNABox>
          </DNABox>

        </ScrollView>
      </DNAModal.Body>
      <DNAModal.Confirmation style={{ paddingVertical: 12, paddingHorizontal: 24 }}>
        <DNAButton appearance="filled" status="success" onPress={migrationAction(true)}>
          Accept
        </DNAButton>
        <DNAButton appearance="outline" status="warning" onPress={migrationAction(false)}>
          Skip
        </DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}
