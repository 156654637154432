import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { commonReducers, initialState, SliceStatus } from './common';
import { getDependenciesForSharedFolder, getSharedFolders } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { multiSliceActions } from './multiSlice';
import { DataStore } from '@aws-amplify/datastore';
import { v4 as uuid } from 'uuid';
import { useDispatch, batch } from 'react-redux';
import { folderPermissionActions } from './folderPermission';
import {
  CreateSubFolderMutation,
  FOLDER_ITEM_STATUS, FOLDER_ITEM_TYPE, FolderItemInput, GetSharedFoldersQuery,
  UpdateFolderInput,
  UpdateFolderItemsMutation,
} from '@alucio/aws-beacon-amplify/src/API';
import {
  CustomDeck,
  Folder,
  Document,
  DocumentVersion,
  AccessTokenData,
  GetSharedFoldersOutput,
  CustomDeckGroup,
  FolderItemStatus,
  FolderItemType,
} from '@alucio/aws-beacon-amplify/src/models';
import { store } from 'src/state/redux';
import customDeckSlice from './customDeck';
import documentVersionSlice from './documentVersion';
import documentSlice from './document';
import authTokenSlice, { AccessTokenDataExtended } from './authToken';
import { createSubFolder, updateFolderItems } from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import ActiveUser from 'src/state/global/ActiveUser';
import { DocumentORM, FolderItemORM, FolderORM } from 'src/types/orms';
import { EDITOR_TYPE } from './PresentationBuilder/PresentationBuilder';
import { getRootFolder } from 'src/utils/foldersHelpers';
import { handleDuplicateItems } from './duplicateHelper';
import cloneDeep from 'lodash/cloneDeep';
import { addFolderItem, updateFolderDocumentVersion } from '../util/folderUpdates';
import { unionBy } from 'lodash';

const sliceName = 'sharedFolder'
const { reducers, extraReducers } = commonReducers<Folder>(sliceName)

export async function getExtenalDependencies(folderId: string, parentFolderId: string) {
  const { data } = await API.graphql(
    graphqlOperation(getDependenciesForSharedFolder, {
      folderId: folderId,
      parentFolderId: parentFolderId,
    }),
  ) as GraphQLResult<{
    getDependenciesForSharedFolder: {
      customDecks: CustomDeck[];
      documents: Document[]; documentVersions: DocumentVersion[];
      accessTokens: AccessTokenData[];
    };
  }>;

  const {
    customDecks,
    documents,
    documentVersions,
    accessTokens,
  } = data?.getDependenciesForSharedFolder || {};

  return {
    customDecks,
    documents,
    documentVersions,
    accessTokens,
  }
}

export const useFetchSharedFolders = () => {
  const dispatch = useDispatch()

  const fetchSharedFolders = async () => {
    dispatch(sharedFolderActions.setStatus(SliceStatus.PENDING))
    dispatch(folderPermissionActions.setStatus(SliceStatus.PENDING))
    try {
      const { data } = await API.graphql(graphqlOperation(getSharedFolders)) as GraphQLResult<GetSharedFoldersQuery>;
      if (data?.getSharedFolders) {
        dispatch(multiSliceActions.replaceSharedFolders(data.getSharedFolders as GetSharedFoldersOutput));
      }
    } catch (err) {
      console.error(err);
      dispatch(sharedFolderSlice.actions.setStatus(SliceStatus.ERROR))
      dispatch(folderPermissionActions.setStatus(SliceStatus.ERROR))
    }
  }

  return {
    fetchSharedFolders,
  }
}
const getFolderExternalDependencies = createAsyncThunk(
  'folder/getFolderExternalDependencies',
  async ({ folderId, parentFolderId }: { folderId: string, parentFolderId: string }) => {
    const {
      customDecks, documents, documentVersions, accessTokens,
    } = await getExtenalDependencies(folderId, parentFolderId);

    // [NOTE] - These do not insert proper DataStore records!!
    //        - That means you may run into an issue trying to save a record that is not available to you by default!
    batch((): void => {
      customDecks && store.dispatch(customDeckSlice.actions.insert(customDecks));
      documents && store.dispatch(documentSlice.actions.insert(documents));
      documentVersions && store.dispatch(documentVersionSlice.actions.insert(documentVersions));
      accessTokens && store.dispatch(authTokenSlice.actions.upsert(
        accessTokens?.map(accesToken => new AccessTokenDataExtended(accesToken)),
      ));
    });
  },
)

export enum UpdateType {
  ADD_DOCUMENT,
  DELETE_ITEM,
  UPDATE_ACKNOWLEDGED,
  UPDATE_DOCUMENT_VERSION,
  UPDATE_FOLDER_ITEM_TITLE,
  ADD_SUB_FOLDER,
  DELETE_SUB_FOLDER,
  RENAME_SUB_FOLDER,
}

const updateItems = createAsyncThunk(
  'folder/updateFolderItems',
  async (args: { folder: FolderORM, folderItems: FolderItemORM[] | DocumentORM[] | FolderItemInput[],
    action: UpdateType}) => {
    const { folder, folderItems, action } = args
    const now = new Date().toISOString();
    const rootFolder = getRootFolder(folder)
    let items: FolderItemInput[] = []
    if (action === UpdateType.ADD_DOCUMENT) {
      items = folderItems.map(item => addFolderItem(item))
    }
    else if (action === UpdateType.UPDATE_DOCUMENT_VERSION) {
      items = folderItems.map(updateFolderDocumentVersion);
    }
    else if (action === UpdateType.DELETE_SUB_FOLDER) {
      items = folderItems.map<FolderItemInput>((item) => {
        return {
          ...item,
          status: FOLDER_ITEM_STATUS.REMOVED,
          itemLastUpdatedAt: now,
          updateAcknowledgedAt: now,
        }
      },
      )
    }
    else {
      items = folderItems.map<FolderItemInput>((item) => {
        if (action === UpdateType.UPDATE_ACKNOWLEDGED) {
          analytics?.track('FOLDER_ITEM_ACKNOWLEDGE_UPDATE', {
            action: 'ITEM_ACKNOWLEDGE_UPDATE',
            category: 'FOLDER',
            folderItemId: item.model.id,
          });
        }
        return {
          ...item.model,
          itemLastUpdatedAt: now,
          status: action === UpdateType.DELETE_ITEM ? FOLDER_ITEM_STATUS.REMOVED : FOLDER_ITEM_STATUS.ACTIVE,
          updateAcknowledgedAt: action === UpdateType.UPDATE_ACKNOWLEDGED ? now : item.model.updateAcknowledgedAt,
        }
      },
      )
    }
    const folderUpdates = {
      items,
      id: folder.model.id,
    }

    try {
      const { data } = await API.graphql(graphqlOperation(updateFolderItems, {
        foldersUpdates : [folderUpdates],
        rootFolderId: rootFolder.model.id,
      })) as GraphQLResult<UpdateFolderItemsMutation>;

      return data?.updateFolderItems;
    }
    catch (err) {
      console.error('Unable to update folder items', err)
      throw err
    }
  },
)

const createShareSubFolder = createAsyncThunk(
  'sharedFolder/createShareSubFolder',
  async ({ folder, title }:
    { folder: FolderORM, title: string }) => {
    try {
      const { data } = await API.graphql(graphqlOperation(createSubFolder, {
        newSubFolder: {
          name: title,
          parentFolderId: folder.model.id,
        },
      })) as GraphQLResult<CreateSubFolderMutation>;
      return data?.createSubFolder;
    }
    catch (err) {
      console.error('Unable to update folder items', err)
      throw err
    }
  },
)

const createCustomDeckInFolder = createAsyncThunk(
  'sharedFolder/createCustomDeckInFolder',
  async ({
    targetFolder,
    selectedSlides,
    title,
    duplicateUserNotationsCallback,
  }: {
      targetFolder: FolderORM,
      selectedSlides: CustomDeckGroup[],
      title: string,
      duplicateUserNotationsCallback?: (newCustomDeckId: string) => void,
    }) => {
    const now = new Date().toISOString();
    const newCustomDeck = new CustomDeck({
      title: title ?? '',
      createdBy: ActiveUser.user?.id!,
      createdAt: now,
      autoUpdateAcknowledgedAt: now,
      updatedBy: ActiveUser.user?.id!,
      updatedAt: now,
      tenantId: ActiveUser.user?.tenantId!,
      groups: selectedSlides,
    })

    try {
      const newCustomDeckRes = await DataStore.save(newCustomDeck)

      const newItem = {
        type: FolderItemType.CUSTOM_DECK,
        itemId: newCustomDeckRes.id,
        id: uuid(),
        addedAt: now,
        status: FolderItemStatus.ACTIVE,
        itemLastUpdatedAt: now,
        customTitle: title,
      }
      const rootFolder = getRootFolder(targetFolder)

      const folderUpdates = {
        items: [newItem],
        id: targetFolder.model.id,
      }

      const { data } = await API.graphql(graphqlOperation(updateFolderItems, {
        foldersUpdates: [folderUpdates],
        rootFolderId: rootFolder.model.id,
      })) as GraphQLResult<UpdateFolderItemsMutation>;

      analytics?.track('CUSTOM_SAVE', {
        action: 'SAVE',
        category: 'CUSTOM',
        customDeckId: newCustomDeckRes.id,
        editorType: EDITOR_TYPE.OWNER,
      });

      await duplicateUserNotationsCallback?.(newCustomDeck.id)

      return data?.updateFolderItems;
    }
    catch (err) {
      console.error('Unable to update folder items', err)
    }
  },
)

const duplicateItem = createAsyncThunk(
  'sharedFolder/duplicateItem',
  async ({
    targetFolderORM,
    itemToDupe,
    duplicateUserNotationsCallback,
  }: {
    targetFolderORM: FolderORM;
    itemToDupe: FolderItemORM;
    duplicateUserNotationsCallback?: (newCustomDeckId: string) => void,
  }) => {
    const targetFolderModel = targetFolderORM.model
    const { sequenceNumber, basedCustomDeck } = await handleDuplicateItems({ targetFolderORM, itemToDupe })
    let itemId = itemToDupe.model.itemId
    const titleRoot = itemToDupe.meta.title
    if (itemToDupe.model.type === FolderItemType.CUSTOM_DECK) {
      if (!basedCustomDeck) throw new Error('Could not find custom deck')
      try {
        const dupCustmDeck = await DataStore.save(
          new CustomDeck({
            ...cloneDeep({
              ...basedCustomDeck,
              createdBy: ActiveUser.user?.id,
              updatedBy: ActiveUser.user?.id,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              sourceCustomDeckId: basedCustomDeck.sourceCustomDeckId ?? basedCustomDeck.id,
            }),
            title: `${titleRoot} Copy ${sequenceNumber}`,
          }),
        );
        itemId = dupCustmDeck.id
        await duplicateUserNotationsCallback?.(itemId)
      } catch (err) {
        console.error('Unable to duplicate custom deck', err)
      }
    }

    /** Generate new FolderItem with unique random id  */
    const currTS = new Date().toISOString();
    const duplicateItem = {
      ...itemToDupe.model,
      id: uuid(),
      itemId,
      customTitle: `${titleRoot} Copy ${sequenceNumber}`,
      addedAt: currTS,
      updateAcknowledgedAt: currTS,
      itemLastUpdatedAt: currTS,
    };

    analytics?.track('FOLDER_DUPLICATE_ITEM', {
      action: 'DUPLICATE_ITEM',
      category: 'FOLDER',
      folderId: targetFolderModel.id,
      itemType: itemToDupe.type,
      sourceItemId: itemToDupe.model.id,
    })

    const folderUpdates = {
      items: [duplicateItem],
      id: targetFolderModel.id,
      shareStatus: targetFolderModel.shareStatus,
      status: targetFolderModel.status,
    }

    try {
      const rootFolder = getRootFolder(targetFolderORM)
      const { data } = await API.graphql(graphqlOperation(updateFolderItems, {
        foldersUpdates: [folderUpdates],
        rootFolderId: rootFolder?.model.id,
      })) as GraphQLResult<UpdateFolderItemsMutation>;
      return data?.updateFolderItems;
    } catch (err) {
      console.error('Unable to duplicate folder item', err)
    }
  },
);

const updateItemPagesAndTitle = createAsyncThunk(
  'sharedFolder/updateItemsPagesAndTitle',
  async ({ folderItemORM, updates, folderORM }:
    { folderItemORM: FolderItemORM,
      updates: { visiblePages: number[] | undefined, customTitle: string | undefined }, folderORM: FolderORM }) => {
    const { id: folderId } = folderORM.model
    const rootFolder = getRootFolder(folderORM)
    const item = folderORM.model.items.find(item => item.id === folderItemORM.model.id)
    const updatedItem = {
      ...item,
      visiblePages: updates.visiblePages,
      customTitle: updates.customTitle,
      itemLastUpdatedAt: new Date().toISOString(),
      updateAcknowledgedAt: new Date().toISOString(),
    }

    const folderUpdates = {
      items: [updatedItem],
      id: folderId,
      shareStatus: folderORM.model.shareStatus,
      status: folderORM.model.status,
    }

    try {
      const { data } = await API.graphql(graphqlOperation(updateFolderItems, {
        foldersUpdates: [folderUpdates],
        rootFolderId: rootFolder.model.id,
      })) as GraphQLResult<UpdateFolderItemsMutation>;
      return data?.updateFolderItems;
    }
    catch (err) {
      console.error('Unable to update folder items', err)
    }
  },
)

const updateSharedFolder = createAsyncThunk(
  'sharedFolder/updateSharedFolder',
  async ({ folderORM, folderUpdates, updateType }:
    { folderORM: FolderORM, folderUpdates: UpdateFolderInput, updateType: UpdateType }) => {
    const { id: folderId } = folderORM.model
    const foldersUpdates: UpdateFolderInput[] = [folderUpdates]
    const rootFolder = getRootFolder(folderORM)
    const updatesParentFolderItem = rootFolder.model.items.find(item => item.itemId === folderId)
    if (!updatesParentFolderItem) {
      throw new Error('Could not find parent folder item')
    }
    const updatedItem: FolderItemInput = {
      id: updatesParentFolderItem.id,
      itemLastUpdatedAt: new Date().toISOString(),
      status: updateType === UpdateType.DELETE_SUB_FOLDER ? FOLDER_ITEM_STATUS.REMOVED : FOLDER_ITEM_STATUS.ACTIVE,
      type: FOLDER_ITEM_TYPE[updatesParentFolderItem.type],
      itemId: folderId,
      addedAt: updatesParentFolderItem.addedAt,
    };

    foldersUpdates.push({
      id: rootFolder.model.id,
      items: [updatedItem],
    });

    try {
      const { data } = await API.graphql(graphqlOperation(updateFolderItems, {
        foldersUpdates,
        rootFolderId: rootFolder.model.id,
      })) as GraphQLResult<UpdateFolderItemsMutation>;
      return data?.updateFolderItems;
    } catch (err) {
      console.error('Unable to update folder items', err)
      throw err
    }
  },
)

const getSharedFoldersAction = createAsyncThunk(
  'sharedFolder/getSharedFolders',
  async () => {
    const { data } = await API.graphql(graphqlOperation(getSharedFolders)) as GraphQLResult<GetSharedFoldersQuery>;
    return data?.getSharedFolders || [];
  },
)

const sharedFolderSlice = createSlice({
  name: sliceName,
  initialState: initialState<Folder>(),
  reducers: {
    ...reducers,
    replace: (state, { payload }) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
      state.records = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    updateFolderItemTitle: (state, action) => {
      state.records.forEach(folder => {
        folder.items.forEach(item => {
          if (item.id === action.payload.id) {
            item.customTitle = action.payload.title;
          }
        })
      })
    },
  },
  extraReducers: {
    [getSharedFoldersAction.fulfilled.toString()]: (state, { payload }) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
      state.records = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    [getFolderExternalDependencies.pending.toString()]: (state, _) => {
      state.status = SliceStatus.PENDING;
    },
    [getFolderExternalDependencies.fulfilled.toString()]: (state, _) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
    },
    [getFolderExternalDependencies.rejected.toString()]: (state, _) => {
      state.status = SliceStatus.ERROR;
    },
    [createCustomDeckInFolder.fulfilled.toString()] : (state, { payload }) => {
      handleSuccess(state, payload)
    },
    [createCustomDeckInFolder.rejected.toString()] : (state) => {
      state.status = SliceStatus.ERROR
    },
    [updateItems.fulfilled.toString()] : (state, { payload }) => {
      handleSuccess(state, payload)
    },
    [updateItems.rejected.toString()] : (state) => {
      state.status = SliceStatus.ERROR
    },
    [createShareSubFolder.fulfilled.toString()] : (state, { payload }) => {
      handleSuccess(state, payload)
    },
    [createShareSubFolder.rejected.toString()] : (state) => {
      state.status = SliceStatus.ERROR
    },
    [updateSharedFolder.fulfilled.toString()] : (state, { payload }) => {
      handleSuccess(state, payload)
    },
    [updateSharedFolder.rejected.toString()] : (state) => {
      state.status = SliceStatus.ERROR
    },
    [duplicateItem.fulfilled.toString()] : (state, { payload }) => {
      handleSuccess(state, payload)
    },
    [duplicateItem.rejected.toString()] : (state) => {
      state.status = SliceStatus.ERROR
    },
    [updateItemPagesAndTitle.fulfilled.toString()] : (state, { payload }) => {
      state.status = SliceStatus.OK
      state.hydrated = true
      state.records.forEach((folder) => {
        if (folder.id === payload.id) {
          folder.items = payload.items
        }
        return folder
      },
      )
    },
    [updateItemPagesAndTitle.rejected.toString()] : (state) => {
      state.status = SliceStatus.ERROR
    },
    ...extraReducers,
  },
})

const handleSuccess = (state, payload) => {
  state.status = SliceStatus.OK;
  state.hydrated = true;
  state.records = unionBy(payload, state.records, 'id');
};

export default sharedFolderSlice

export const sharedFolderActions = {
  ...sharedFolderSlice.actions,
  getFolderExternalDependencies,
  createCustomDeckInFolder,
  updateItems,
  createShareSubFolder,
  getSharedFoldersAction,
  duplicateItem,
  updateItemPagesAndTitle,
  updateSharedFolder,
}
