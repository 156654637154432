import React from 'react';
import { DNABox, DNAButton, DNAChip, DNAText, Iffy } from '@alucio/lux-ui'
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { StyleSheet } from 'react-native';
import Skelley from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import DNADocumentChip from '../../DNA/Document/DNADocumentChip';
import { isCustomDeckORM, isDocumentVersionORM, isFolderItemORM, isPageGroupORM } from 'src/types/typeguards';
import DocumentVersionSelector from '../DocumentVersionSelector';
import SlideGroupSelector from '../SlideGroupSelector';
import PresentedSlideSelector from '../PresentedSlideSelector';
import TopMenuBar from './TopMenuBar';
import format from 'date-fns/format'
import { useContentPreviewModal } from '../State/ContentPreviewModalStateProvider';
import { DocumentAccessLevel } from '@alucio/aws-beacon-amplify/src/models';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import MeetingButton from 'src/components/Header/MeetingButton/MeetingButton';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { useMeetingPresentedMeta } from '../State/MeetingPresentedMetaProvider';
import { useSharedFilesState } from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesProvider.proxy';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import ConfirmationModal from 'src/screens/Meetings/PresentationControls/TabContent/MeetingDetails/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useContentPreviewModalManagement } from '../State/ContentPreviewModalState';

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    paddingLeft: 16,
    borderBottomWidth: 1,
    height: 64,
    borderBottomColor: colors['color-gray-80'],
  },
  headerBarIcons: {
    flexDirection: 'row-reverse',
    marginRight: 0,
  },
  tooltipTextColor: {
    color: 'white',
  },
  skeletonContainer: {
    minWidth: 200,
  },
  skeletonLoadingState: {
    minWidth: 200,
    height: 20,
    opacity: 0.8,
  },
  backButton: {
    marginRight: 12,
  },
  headerRightButtonGroup: {
    marginLeft: 16,
  },
  lastUpdateText: {
    marginLeft: 8,
  },
  exitButton: {
    width: 56,
    marginLeft: 24,
  },
  badgesContainer: {
    overflow: 'hidden',
  },
});

// [TODO] - should really just expose this at the ORM level for convenience
const useLastUpdatedAt = () => {
  const { activePresentation } = useContent()

  if (!activePresentation) return undefined

  const documentVersion =
    isDocumentVersionORM(activePresentation.presentable.orm) ? activePresentation.presentable.orm
      : isFolderItemORM(activePresentation.presentable.orm) &&
      isDocumentVersionORM(activePresentation.presentable.orm.relations.itemORM)
        ? activePresentation.presentable.orm.relations.itemORM : undefined;

  const pageGroup = isPageGroupORM(activePresentation.presentable.orm)
    ? activePresentation.presentable.orm
    : undefined

  const customDeck = (
    isFolderItemORM(activePresentation.presentable.orm) &&
    isCustomDeckORM(activePresentation.presentable.orm.relations.itemORM)
  )
    ? activePresentation.presentable.orm.relations.itemORM
    : undefined

  const lastUpdatedDate = documentVersion?.model.updatedAt ??
    pageGroup?.relations.documentVersionORM.model.updatedAt ??
    customDeck?.model.updatedAt

  return lastUpdatedDate ? new Date(lastUpdatedDate) : undefined
}

const Badges: React.FC = () => {
  const { activePresentation } = useContent()
  const { isModifiedDocumentVersion, activeDocumentVersionORM } = useContentPreviewModal()
  const route = useCurrentPage({ exact: false })
  const lastUpdatedAt = useLastUpdatedAt()
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const showStatus = isPublisherRoute ||
    activeDocumentVersionORM?.relations.documentORM.meta.sealedStatus === 'ARCHIVED';
  if (!activePresentation) return null
  const isUserDocument = activeDocumentVersionORM?.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER;

  const FileTypeBadge: React.FC = () => {
    return (
      <DNAChip testID="file-chip-badge" appearance="tag">
        {activeDocumentVersionORM?.model?.type}
      </DNAChip>
    )
  }

  const ModifiedBadge: React.FC = () => {
    return (
      <DNAChip appearance="tag">
        MODIFIED
      </DNAChip>
    )
  }

  const LastUpdatedAt: React.FC = () => {
    if (!lastUpdatedAt) return null
    return (
      <DNAText testID="last-update" status="subtle" numberOfLines={1} style={styles.lastUpdateText}>
        Last Updated {format(lastUpdatedAt, 'MM/dd/yyyy')}
      </DNAText>
    )
  }

  const isCustomDeck = isFolderItemORM(activePresentation.presentable.orm) &&
    isCustomDeckORM(activePresentation.presentable.orm.relations.itemORM)
  const showFileTypeBadge = (!isCustomDeck && !activeDocumentVersionORM) || isUserDocument;
  const isPresetingNonUserDoc = activeDocumentVersionORM && !isUserDocument;

  return (
    <DNABox fill style={styles.badgesContainer}>
      <DNABox spacing="sm">
        {showFileTypeBadge && <FileTypeBadge />}
        {!isModifiedDocumentVersion && !activeDocumentVersionORM && <ModifiedBadge />}
        {isPresetingNonUserDoc && <CustomFieldBadgeList documentVersionORM={activeDocumentVersionORM} />}
        {isPresetingNonUserDoc && <FileTypeBadge />}
        {isPresetingNonUserDoc && showStatus && <DNADocumentChip item={activeDocumentVersionORM} variant="status"/>}
        {isPresetingNonUserDoc && isModifiedDocumentVersion && <ModifiedBadge />}
      </DNABox>
      <LastUpdatedAt />
    </DNABox >
  )
}

enum Variants {
  ContentPreviewModal = 'ContentPreviewModal',
  Meetings = 'Meetings',
  Hubs = 'Hubs',
}
type HeaderVariantProp = {
  docHasPageGroups: boolean
}

const ContentPreviewModalHeaderButtons: React.FC<HeaderVariantProp> = ({ docHasPageGroups }) => {
  const { activeDocumentVersionORM, isModifiedDocumentVersion, canContentBePresented } = useContentPreviewModal()
  const { isReadOnly } = useContentPreviewModalManagement()
  const isUserDocument = activeDocumentVersionORM?.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER;
  const enableDocumentOptions = activeDocumentVersionORM && !isModifiedDocumentVersion && !isUserDocument
  return (
    <DNABox spacing="sm" alignY="center">
      <Iffy is={canContentBePresented}>
        <MeetingButton context="contentPreviewModal" />
      </Iffy>
      <Iffy is={enableDocumentOptions}>
        <DNABox disabled={isReadOnly}>
          <DocumentVersionSelector />
        </DNABox>
        <Iffy is={docHasPageGroups}>
          <SlideGroupSelector />
        </Iffy>
        <TopMenuBar />
      </Iffy>
    </DNABox>
  )
}

const MeetingHeaderButtons: React.FC<HeaderVariantProp> = ({ docHasPageGroups }) => {
  const { activePresentation } = useContent()
  const { activeDocumentVersionORM, isModifiedDocumentVersion } = useContentPreviewModal()
  const { isReadOnly } = useContentPreviewModalManagement()
  const { meetingORM, isSaveEnabled, saveContentPresentedChanges } = useMeetingPresentedMeta();
  if (!activePresentation || !meetingORM) return null

  const isUserDocument = activeDocumentVersionORM?.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER;
  const enableDocumentOptions = activeDocumentVersionORM && !isModifiedDocumentVersion && !isUserDocument

  return (
    <DNABox spacing="sm" alignY="center">
      <Iffy is={enableDocumentOptions}>
        <DNABox disabled={isReadOnly}>
          <DocumentVersionSelector />
        </DNABox>
      </Iffy>
      <PresentedSlideSelector docHasPageGroups={docHasPageGroups} />
      <DNAButton
        padding="sm"
        disabled={!isSaveEnabled}
        onPress={saveContentPresentedChanges}
      >
        Save
      </DNAButton>
    </DNABox>
  )
}

type HeaderProp = {
  variant: Variants
}

const Header: React.FC<HeaderProp> = ({ variant }) => {
  const { activePresentation, presentations, changeActivePresentation } = useContent()
  const {
    activeDocumentVersionORM, isAssociatedFile, setIsAssociatedFile, onClose,
  } = useContentPreviewModal()
  const { content } = useContentPreviewModalManagement()
  const { meetingORM, isSaveEnabled } = useMeetingPresentedMeta();
  const dispatch = useDispatch();
  const { deviceMode } = useAppSettings();

  const goBack = () => {
    setIsAssociatedFile(false)
    changeActivePresentation(presentations[0].presentable.id)
  }

  const onClosePressed = (): void => {
    if (isSaveEnabled && meetingORM) {
      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (<ConfirmationModal
            {...props}
            onConfirmAction={onClose}
          />),
        }))
    } else {
      onClose();
    }
  };

  const { orm } = activePresentation?.presentable || {};

  let title = ''
  let docHasPageGroups = false

  if (isPageGroupORM(orm)) {
    title = orm.relations.documentVersionORM.model.title || ''
    docHasPageGroups = true
  } else if (isDocumentVersionORM(orm)) {
    title = orm.model.title || ''
    docHasPageGroups = !!orm.relations.pageGroups.length
  } else if (isFolderItemORM(orm)) {
    title = orm.model.customTitle || (activeDocumentVersionORM ? activeDocumentVersionORM?.model.title : '') || ''
    docHasPageGroups = isDocumentVersionORM(orm.relations.itemORM) &&
      !!orm.relations.itemORM.relations.pageGroups.length
  }

  const HeaderButtonsVariants: Record<Variants, React.FC<HeaderVariantProp>> = {
    ContentPreviewModal: ContentPreviewModalHeaderButtons,
    Meetings: MeetingHeaderButtons,
    Hubs: () => null,
  }
  const HeaderButtonsVariant = HeaderButtonsVariants[variant]

  return (
    <DNABox
      testID="content-preview-modal-header"
      alignY="center"
      appearance="row"
      style={styles.mainWrapper}
    >
      <DNABox fill alignY="center">
        <Iffy is={isAssociatedFile && presentations.length > 1}>
          <DNABox alignX="center" alignY="center" style={ styles.backButton }>
            <DNAButton
              status="tertiary"
              appearance="outline"
              iconLeft="chevron-left"
              size={deviceMode === 'desktop' ? 'md' : 'lg'}
              padding={deviceMode === 'desktop' ? 'sm' : 'md'}
              rounded="md"
              onPress={goBack}
            />
          </DNABox>
        </Iffy>
        <DNABox fill appearance="col" spacing="sm">
          <Iffy is={!activePresentation}>
            <DNABox spacing="sm" appearance="col" style={styles.skeletonContainer}>
              <Skelley count={1} style={styles.skeletonLoadingState} />
            </DNABox>
          </Iffy>
          <Iffy is={activePresentation}>
            <DNAText testID="preview-modal-title" numberOfLines={1} h5 bold >
              {title} { isPageGroupORM(content) ? `(${content.model.name || 'Unnamed group'})` : '' }
            </DNAText>
            <Badges />
          </Iffy>
        </DNABox>
      </DNABox>
      <DNABox alignX="end" spacing="sm" alignY="center" style={styles.headerRightButtonGroup}>
        <HeaderButtonsVariant docHasPageGroups={docHasPageGroups}/>
        <DNABox alignX="center" style={styles.exitButton} alignY="center">
          <DNAButton
            testID="content-present-mode-exit-button"
            status="tertiary"
            appearance="ghostLink"
            iconLeft="close"
            size="md"
            onPress={onClosePressed}
          />
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

const HeaderWrapper: React.FC = () => {
  const { meetingORM } = useMeetingPresentedMeta();
  const sharedFilesState = useSharedFilesState();
  const variant = meetingORM ? Variants.Meetings : sharedFilesState ? Variants.Hubs : Variants.ContentPreviewModal
  return (<Header variant={variant}/>)
}

export default HeaderWrapper
