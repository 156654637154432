import { useCallback, useEffect, useRef, useState } from 'react';
import equal from 'fast-deep-equal';
import { BroadcastChannel } from 'broadcast-channel';
import { PPZTransform } from '@alucio/core';
import { FileType, MeetingType, Notation } from '@alucio/aws-beacon-amplify/src/models';
import AWSConfig from '@alucio/aws-beacon-amplify/src/aws-exports';
import {
  PresentablePage,
  isCustomDeckORM,
  isDocumentVersionORM,
  isFolderItemORM,
  CustomDeckORM,
} from 'src/types/types';
import { PresentationBroadcastContent } from '@alucio/core/lib/state/context/PresentationPlayer/types';
import { LoadedPresentation } from './ContentProvider';
import { getDocPath } from 'src/components/ContentPreviewModal/ContentPreview/IFrame/IFrame.web';
import { PW } from 'src/state/machines/presentation/playerWrapper';
import { AlucioChannel, useSafePromise } from '@alucio/lux-ui';
import {
  PresentationChannelMessage,
  PresentationStateIdleEvent,
  PresentationStateSyncEvent,
} from 'src/state/machines/presentation/playerWrapperTypes';
import { descendingOrderNotations } from 'src/utils/notationHelpers';
import useInterval from '@alucio/core/src/hooks/useInterval/useInterval';
import { useAppSettings } from '../AppSettings';
import useTokenHandler from 'src/hooks/useTokenHandler/useTokenHandler';
import * as logger from 'src/utils/logger';
import debounce from 'lodash/debounce';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useIsExternalPlayer } from 'src/screens/Loading';
import { useMeeting } from 'src/state/redux/selector/meeting';

const useContentInfoBroadcastChannel = (
  setActiveSlideByPresentationPageNumber: (presentationPageNumber: number, step?: number, totalSteps?: number) => void,
  setCurrentPPZCoords: (coords?: PPZTransform) => void,
  activePresentation?: LoadedPresentation,
  // TODO when we integrate we'll want to pull the meeting ID from the hook
  meetingId:string = 'aaaaaa',
) => {
  const { isOnline } = useAppSettings()
  const safePromise = useSafePromise()
  const isExternalPlayer = useIsExternalPlayer();
  const featureFlags = useFeatureFlags('enableNew3PC', 'enableMultipleIFrames');
  const meetingORM = useMeeting(meetingId);
  const bypassMessaging = !isExternalPlayer && meetingORM?.model.type === MeetingType.VIRTUAL;
  const bypassMessagingRef = useRef<boolean>(bypassMessaging);
  const messageNumberRef = useRef<number>(0);
  const isFromSameSource = useRef<boolean>(false);
  const [getTokenInterval, setGetTokenInterval] = useState<number | null>(null);
  const [contentInfo, setContentInfo] = useState<PresentationBroadcastContent>();
  const [shouldUpdatePlayer, setShouldUpdatePlayer] = useState<boolean>(false)
  const currentActivePresentationRef = useRef<LoadedPresentation | undefined>(activePresentation);
  const channel: BroadcastChannel<PW.PresentationChannelMessage> = useRef(
    AlucioChannel.get(AlucioChannel.commonChannels.PRESENTATION_CHANNEL)).current
  const { getAccessToken } = useTokenHandler();
  const debouncedHandlePresentationChange = useCallback(debounce((activePresentation, contentInfo) =>
    handleNewActivePage(activePresentation, contentInfo), 250, { leading: true }), []);

  useEffect(() => {
    bypassMessagingRef.current = bypassMessaging;
  }, [bypassMessaging]);

  useEffect(() =>
    featureFlags.enableMultipleIFrames
      ? debouncedHandlePresentationChange(activePresentation, contentInfo)
      : handleNewActivePage(activePresentation, contentInfo),
  [activePresentation,
    activePresentation?.currentPresentablePage,
    activePresentation?.currentStep,
    activePresentation?.currentPPZCoords?.positionX,
    activePresentation?.currentPPZCoords?.positionY,
    activePresentation?.currentPPZCoords?.scale]);

  // ** CHANNEL LISTENER/SENDER ** //
  // SENDS A MESSAGE WITH THE NEW UPDATED CONTENT STATE
  const broadcastStateSync = useCallback((msg: PresentationChannelMessage) => {
    if (featureFlags.enableNew3PC && bypassMessagingRef.current) {
      return;
    }
    async function postMsg() {
      logger.useContentInfoChannel.debug('Sending to Presentation Channel', { channel, msg })
      channel.postMessage(msg)
    }
    safePromise.makeSafe(postMsg())
      .catch(err => !err.isCanceled
        ? console.error(err)
        : undefined,
      )
  }, []);
  useEffect(() => {
    logger.useContentInfoChannel.debug('Calling broadcast from useEffect', contentInfo)
    broadcastStateSync(getBroadcastMessage(contentInfo));
  }, [contentInfo]);

  const getBroadcastMessage = (contentInfo?: PresentationBroadcastContent) => {
    const msg:PresentationStateSyncEvent|PresentationStateIdleEvent = contentInfo
      ? {
        type: 'PRESENTATION_STATE_SYNC',
        meetingId,
        payload: contentInfo,
        messageNumber: messageNumberRef.current,
        shouldUpdatePlayer,
        isFromSameSource: isFromSameSource.current,
      }
      : {
        type: 'PRESENTATION_STATE_IDLE',
        meetingId,
      }
    if (shouldUpdatePlayer) setShouldUpdatePlayer(false)
    return msg
  }

  const excludedEvts = [
    'TOGGLE_NOTATIONS',
    'SYNC_CURRENT_ACTIVE_NOTATION',
    'PRESENTATION_STATE_SYNC',
    'SEND_NOTATION_COORDINATE',
    'SET_EDIT_NOTATION',
  ]
  // WAITS FOR MESSAGES TO UPDATE THE STATE ACCORDINGLY
  useEffect(() => {
    const messageHandler = (msg: PW.PresentationChannelMessage) => {
      if (msg.meetingId === meetingId && !excludedEvts.includes(msg.type)) {
        switch (msg.type) {
          case 'NAVIGATE_PAST_FIRST':
            logger.useContentInfoChannel.debug('Received message: NAVIGATE_PAST_FIRST')
            navigatePastFirst()
            break
          case 'NAVIGATE_PAST_LAST':
            logger.useContentInfoChannel.debug('Received message: NAVIGATE_PAST_LAST')
            navigatePastLast();
            break
          case 'PRESENTATION_PROGRESS': {
            logger.useContentInfoChannel.debug('Received message: PRESENTATION_PROGRESS', msg)
            messageNumberRef.current = msg.messageNumber;
            const { page, groupId, step, totalSteps } = msg.payload
            if (page && groupId) {
              pageChange(page, groupId, step, totalSteps);
            }
            break
          }
          case 'PPZ_TRANSFORM': {
            logger.useContentInfoChannel.debug('Received message: PPZ_TRANSFORM')
            const currGroupId = currentActivePresentationRef.current?.currentPresentablePage.presentableGroup.id
            // Check the groupId to make sure we are not getting a PPZ event for a previous doc/group
            if (msg.presentableGroupId === currGroupId) {
              setCurrentPPZCoords(msg.payload);
            } else {
              logger.useContentInfoChannel.warn('Got PPZ message for non-current group ignorning')
            }
            break
          }
          case 'SYNC_PRESENTABLE': {
            logger.useContentInfoChannel.debug('Received message: SYNC_PRESENTABLE')
            broadcastStateSync(getBroadcastMessage(contentInfo));
            break
          }
          case 'TOGGLE_COORDINATE_MODE': {
            logger.useContentInfoChannel.debug('Received message: TOGGLE_COORDINATE_MODE')
            break
          }
          case 'IFRAME_READY': {
            logger.useContentInfoChannel.debug('Received message: IFRAME_READY')
            messageNumberRef.current = msg.messageNumber;
            break
          }
          default:
            logger.useContentInfoChannel.warn(`Received unhandled message from wrapper: ${msg.type}`, msg)
        }
      }
    }
    channel.addEventListener('message', messageHandler)

    return () => {
      // Remove the event listener
      channel.removeEventListener('message', messageHandler)
    }
  }, [contentInfo]);

  // HEARTBEAT
  useInterval(() =>
    broadcastStateSync(getBroadcastMessage(contentInfo)), 2000);

  // INTERVAL TO AVOID HAVING NON-SYNCED STATUS
  useInterval(() => {
    const contentInfoPageId = `${contentInfo?.documentVersionId}_${contentInfo?.state.page}`;
    const activePageId = activePresentation?.currentPresentablePage?.page.pageId;
    if (activePageId && contentInfo && contentInfoPageId !== activePageId) {
      logger.useContentInfoChannel.verbose('Detected unsynced state');
      debouncedHandlePresentationChange(activePresentation, contentInfo);
    }
  }, 2000);

  useInterval(() => {
    async function refreshContentToken() {
      if (activePresentation) {
        logger.useContentInfoChannel.debug('getting access JWT')
        const documentVersionORM = activePresentation?.currentPresentablePage.documentVersionORM;
        const { accessToken: jwt, expirationTimeInSeconds } = await getAccessToken(documentVersionORM);

        setContentInfo((contentInfo) => {
          if (contentInfo) {
            return {
              ...contentInfo,
              JWT: jwt,
            }
          }

          return undefined;
        });
        setGetTokenInterval((expirationTimeInSeconds - 60) * 1000);
      }
    }

    refreshContentToken();
  }, getTokenInterval);

  // ** HANDLERS ** //
  function navigatePastLast(): void {
    navigatePast(true);
  }

  function navigatePastFirst(): void {
    navigatePast();
  }

  // ACCORDING TO OUR LAST PAGE (currentPresentablePageRef) AND THE NEW ACTIVE ONE,
  // IT DETERMINES THE NEW STATE FOR THE PRESENTATION TO HAVE
  function handleNewActivePage(
    activePresentation?: LoadedPresentation,
    contentInfo?: PresentationBroadcastContent): void {
    logger.useContentInfoChannel.info('Entered handleNewActivePage')
    if (!activePresentation) {
      logger.useContentInfoChannel.verbose('No ActivePresentation')
      currentActivePresentationRef.current = undefined;
      setContentInfo(undefined)
      return;
    }
    const activePresentationToCompare: LoadedPresentation | undefined =
      currentActivePresentationRef.current ? { ...currentActivePresentationRef.current } : undefined;
    currentActivePresentationRef.current = { ...activePresentation };
    const docVerORM = activePresentation.currentPresentablePage.documentVersionORM;
    const docVerORMFromRef = activePresentationToCompare?.currentPresentablePage.documentVersionORM;
    const presentableNotations = activePresentation.presentable.notations;
    const sourceDocumentNotations = docVerORM.relations.userNotations?.notation;
    const sourceDocumentNotationsFromRef = docVerORMFromRef?.relations.userNotations?.notation;
    const orm = activePresentation.presentable.orm;
    const ormFromRef = activePresentationToCompare?.presentable.orm;
    const isFolderItem = isFolderItemORM(orm);
    const isCustomDeck = isFolderItem && isCustomDeckORM(orm.relations.itemORM);
    const isFolderItemFromRef = isFolderItemORM(ormFromRef);
    const folderItemNotations =
      isFolderItem && (isCustomDeckORM(orm.relations.itemORM) || isDocumentVersionORM(orm.relations.itemORM))
        ? orm.relations.itemORM.relations.userNotations?.notation
        : undefined
    const folderItemNotationsFromRef =
      isFolderItemFromRef &&
      (isCustomDeckORM(ormFromRef.relations.itemORM) || isDocumentVersionORM(ormFromRef.relations.itemORM))
        ? ormFromRef?.relations.itemORM.relations.userNotations?.notation
        : undefined

    const isNewDocument = docVerORM.model.id !== docVerORMFromRef?.model.id;
    const isSameSource = ormFromRef?.model.id === orm.model.id;

    if (isNewDocument) {
      logger.useContentInfoChannel.verbose('Detected changed of Document')
      // UPDATES THE CONTENT INFO STATE TO REFLECT THE NEW DOCUMENT INFO
      isFromSameSource.current = isSameSource;
      handleChangeOfDocument(activePresentation);
      return;
    }

    if (!contentInfo) {
      logger.useContentInfoChannel.verbose('No ContentInfo');
      return;
    }

    let newNotations: Notation[] | undefined;
    const isNewNotations =
      !equal(presentableNotations, activePresentationToCompare?.presentable.notations) ||
      !equal(sourceDocumentNotations, sourceDocumentNotationsFromRef) ||
      !equal(folderItemNotations, folderItemNotationsFromRef)

    if (isNewNotations) {
      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE NOTATIONS
      logger.useContentInfoChannel.verbose('Detected change of Notation')
      if (activePresentation.isHub) newNotations = presentableNotations || []
      else if (isFolderItem) {
        const newFolderItemNotations = descendingOrderNotations(folderItemNotations || [])
        const newSourceDocumentNotations = descendingOrderNotations(sourceDocumentNotations || [])
        newNotations = isCustomDeck
          ? [...newFolderItemNotations, ...newSourceDocumentNotations]
          : [...newFolderItemNotations]
        if (presentableNotations) {
          // REPLACES OLD NOTATION(S) WITH NEW ONE/LATEST UPDATED ONE TO AVOID DUPLICATES
          const mergedNotations: Notation[] = presentableNotations.reduce((acc, notation) => {
            const alreadyInArrayNotationIndex = acc.findIndex(({ id }) => notation.id === id);
            if (alreadyInArrayNotationIndex !== -1) {
              if (acc[alreadyInArrayNotationIndex].updatedAt <= notation.updatedAt) {
                acc[alreadyInArrayNotationIndex] = notation;
              }
            } else {
              acc.push(notation);
            }
            return acc;
          }, newNotations);
          newNotations = mergedNotations;
        }
      }
      else {
        newNotations = descendingOrderNotations(sourceDocumentNotations || [])
        if (presentableNotations) newNotations = [...presentableNotations, ...newNotations]
      }
    }

    const isNewGroup = activePresentation.currentPresentablePage.presentableGroup.id !==
      activePresentationToCompare?.currentPresentablePage.presentableGroup.id;

    if (isNewGroup) {
      const group = activePresentation.currentPresentablePage.presentableGroup;
      logger.useContentInfoChannel.verbose('Detected change of Group')

      setShouldUpdatePlayer(!!newNotations);
      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE GROUP/PAGE/STEP
      setContentInfo({
        ...contentInfo,
        notations: newNotations || contentInfo.notations,
        groupId: group.id,
        state: {
          page: activePresentation.currentPresentablePage.page.number,
          step: activePresentation.currentStep || 0,
          totalSteps: activePresentation.totalSteps || 0,
        },
        visiblePages: group.pages.reduce<number[]>((acc, page) => {
          acc.push(page.page.number);
          return acc;
        }, []),
      });
      return;
    }

    const isNewPage = activePresentation.currentPresentablePage.id !==
      activePresentationToCompare?.currentPresentablePage.id;

    if (isNewPage) {
      logger.useContentInfoChannel.verbose('Detected change of Page')
      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE PAGE/STEP
      setContentInfo({
        ...contentInfo,
        state: {
          page: activePresentation.currentPresentablePage.page.number,
          step: activePresentation.currentStep || 0,
          totalSteps: activePresentation.totalSteps || 0,
        },
      });
      return;
    }

    if (newNotations) {
      // THESE NEW NOTATIONS ARE PRE-CALCULATED IN ADVANCE TO ACCOUNT FOR SCENARIOS WHERE THE
      // GROUP MAY HAVE CHANGED AND SO DID THE NOTATIONS. IN SUCH CASES, THE CONTENT INFO GETS UPDATED
      // WITH THE NEW NOTATIONS AND CONTENT. OTHERWISE, ONLY THE NOTATIONS ARE SET HERE.
      setShouldUpdatePlayer(true)
      setContentInfo({
        ...contentInfo,
        notations: newNotations,
      });
      return;
    }

    const isNewStep = activePresentation.currentStep !==
      activePresentationToCompare?.currentStep;

    if (isNewStep) {
      logger.useContentInfoChannel.verbose('Detected change of Step')
      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE STEP
      setContentInfo({
        ...contentInfo,
        state: {
          page: contentInfo.state.page,
          step: activePresentation.currentStep || 0,
          totalSteps: activePresentation.totalSteps || 0,
        },
      });
      return;
    }

    const isNewPPZCords = !equal(
      activePresentation.currentPPZCoords, activePresentationToCompare?.currentPPZCoords);

    if (isNewPPZCords) {
      logger.useContentInfoChannel.verbose('Detected change of PPZCords')
      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE COORDS
      setContentInfo({
        ...contentInfo,
        ppzCoords: activePresentation.currentPPZCoords,
      });
    }

    logger.useContentInfoChannel.verbose('No change detected')
  }

  async function handleChangeOfDocument(activePresentation: LoadedPresentation): Promise<void> {
    const { documentVersionORM } = activePresentation.currentPresentablePage;
    const docPath = getDocPath(documentVersionORM, isOnline);
    const bucket = AWSConfig.aws_user_files_s3_bucket;
    const group = activePresentation.currentPresentablePage.presentableGroup;
    const { watermarkText } = documentVersionORM.meta;
    const { isContentCached } = documentVersionORM.meta.assets;
    const presentableNotations = activePresentation.presentable.notations;
    const sortedSourceDocumentNotations = descendingOrderNotations(
      documentVersionORM.relations.userNotations?.notation || [],
    )
    const orm = activePresentation.presentable.orm;
    let notations: Notation[] | undefined;
    let customDeckORM: CustomDeckORM | undefined;
    if (activePresentation.isHub) notations = presentableNotations;
    else if (isFolderItemORM(orm) && isCustomDeckORM(orm.relations.itemORM)) {
      customDeckORM = orm.relations.itemORM;
      const sortedCustomDeckNotations = descendingOrderNotations(customDeckORM.relations.userNotations?.notation || [])
      notations = [...sortedCustomDeckNotations, ...sortedSourceDocumentNotations]
    }
    else notations = sortedSourceDocumentNotations

    const updatedInfo = {
      // The JWT is loaded via a promise to allow us to go ahead and init
      // the player while we're fetching the JWT
      JWT: (isOnline && !isContentCached) ? 'PENDING' : '',
      bucket,
      docPath: `/content/${docPath}`,
      documentVersionId: documentVersionORM.model.id,
      documentId: documentVersionORM.relations.documentORM.model.id,
      customDeckId: customDeckORM?.model.id,
      groupId: group.id,
      contentType: FileType[documentVersionORM.model.type],
      contentURL: documentVersionORM.model.contentURL,
      state: {
        page: activePresentation.currentPresentablePage.page.number,
        step: activePresentation.currentStep || 0,
        totalSteps: activePresentation.totalSteps || 0,
      },
      ppzCoords: activePresentation?.currentPPZCoords,
      visiblePages: group.pages.reduce<number[]>((acc, page) => {
        acc.push(page.page.number);
        return acc;
      }, []),
      watermarkText,
      notations,
    }

    if (isOnline && !isContentCached) {
      getAccessToken(documentVersionORM).then((response) => {
        const { accessToken: jwt, expirationTimeInSeconds } = response;

        setContentInfo({
          ...updatedInfo,
          JWT: jwt,
        })
        // WE'LL FETCH A NEW TOKEN EVERY (TIMEOUT - 1) MINUTES
        setGetTokenInterval((expirationTimeInSeconds - 60) * 1000);
      });
      return;
    }
    setContentInfo(updatedInfo);
  }

  // DETERMINES THE NEXT PAGE/GROUP TO GO, DEPENDING ON THE CURRENT ONE
  function navigatePast(next?: boolean): void {
    let newPage: PresentablePage | undefined;

    for (const group of currentActivePresentationRef.current?.presentable.presentableGroups || []) {
      for (const page of group.pages) {
        if (currentActivePresentationRef.current?.currentPresentablePage.presentationPageNumber ===
          (page.presentationPageNumber + (next ? -1 : 1))) {
          newPage = page;
          break;
        }
      }
    }

    if (newPage) {
      setActiveSlideByPresentationPageNumber(newPage.presentationPageNumber, next ? 0 : -1);
    } else {
      console.warn(`New Page to NAVIGATE_PAST_${next ? 'FIRST' : 'LAST'} not found`);
    }
  }

  // NOTE: PAGENUMBER IS THE ACTUAL NUMBER OF THE PAGE WITHIN A DOCUMENT
  function pageChange(pageNumber: number, groupId: string, step?: number, totalSteps?: number): void {
    let newPage: PresentablePage | undefined;

    for (const group of currentActivePresentationRef.current?.presentable.presentableGroups || []) {
      for (const page of group.pages) {
        if (page.page.number === pageNumber && group.id === groupId) {
          newPage = page;
          break;
        }
      }
    }

    if (newPage) {
      setActiveSlideByPresentationPageNumber(newPage.presentationPageNumber, step, totalSteps);
    } else {
      console.warn('New Page to CHANGE not found');
    }
  }
};

export default useContentInfoBroadcastChannel;
