import { State as StateDef, ResolveTypegenMeta, BaseActionObject } from 'xstate';
import { HubSectionItemStatus, Notation, NotationType } from '@alucio/aws-beacon-amplify/src/models';
import { LoadedPresentation } from 'src/state/context/ContentProvider/ContentProvider';
import type * as CustomNote from './customNote.types';
import { Typegen0 } from './customNote.machine.typegen';
import { isCustomDeckORM, isFolderItemORM } from 'src/types/typeguards';
import { CustomDeckORM } from 'src/types/orms';

// [NOTE] - Normally we can use some util helpers, other TS access to get this
//          but it's possible to run into a circular dependency error when using selector as machine guards
//        - This probably creates a 2nd instance of the State type but it's needed for now
type State = StateDef<
  CustomNote.SMContext,
  CustomNote.SMEvents,
  any,
  { value: any, context: CustomNote.SMContext },
  ResolveTypegenMeta<
    Typegen0,
    CustomNote.SMEvents,
    BaseActionObject,
    CustomNote.SMServices
  >
>

export type CurrentPageNotationsLevelMap = {
  [notationId: string]: 'documentLevel' | 'customDeckLevel'
}

const CUSTOM_NOTE_LIMIT_PER_SLIDE = 50

// TAGS
export const isInitializingState = (state: State) => ({ isInitializingState: state.tags.has('INITIALIZING') })
export const isCreatingNewNote = (state: State) => ({ isCreatingNewNote: state.tags.has('NEW_NOTE') })

// CHECK STATE
export const isIdleState = (state: State) => ({ isIdleState: state.matches('idle') })
export const isSetCoordinateMode = (state: State) => ({ isSetCoordinateMode: state.matches('setCoordinateMode') })
export const isDeletingNoteState = (state: State) => ({ isDeletingNoteState: state.matches('deletingNote') })
export const isCreatingNoteState = (state: State) => ({ isCreatingNoteState: state.matches('creatingNote') })
export const isEditingNoteState = (state: State) => ({ isEditingNoteState: state.matches('editingNote') })
export const isSaveNotationState = (state: State) => ({ isSaveNotationState: state.matches('savingToUserNotations') })
export const isTextInsertion = (state: State) => ({ isTextInsertion: !!state.context.isTextInsertion })

// MACHINE
export const canAddHighlight = (state: State) => ({ canAddHighlight: state.can('ENTER_SET_COORDINATE_MODE') })
export const canInsertText = (state: State) => ({ canInsertText: state.can('ENTER_SET_COORDINATE_MODE') })

// CONTEXT
export const isReadOnlyMode = (state: State) => ({ isReadOnlyMode: state.context.isReadOnlyMode })
export const customDeckId = (state: State) => ({ customDeckId: state.context.customDeckId })
export const currentActiveNotationId = (state: State) =>
  ({ currentActiveNotationId: state.context.currentActiveNotationId })
export const presentableState = (state: State) =>
  ({ presentableState: state.context.presentableState })
export const currentPageNum = (state: State) => {
  const presentableStateValue = presentableState(state).presentableState
  const currentPageNum = presentableStateValue?.state.page ?? 0
  return { currentPageNum }
}
export const notationDraft = (state: State) =>
  ({ notationDraft: state.context.notationDraft })
export const currentPageNotations = (state: State) => {
  const presentableStateVal = presentableState(state).presentableState
  const currentPageNumVal = currentPageNum(state).currentPageNum
  const isTextInsertion = !!state.context.isTextInsertion;
  const currentDocumentVersionId = presentableStateVal?.documentVersionId
  const currentPageId = `${currentDocumentVersionId}_${currentPageNumVal}`
  // Notations from presentableState should already be sorted by creation date
  const currentPageNotations: Notation[] = presentableStateVal
    ?.notations
    ?.filter(notation =>
      notation.status === HubSectionItemStatus.ACTIVE &&
      notation.pageId === currentPageId &&
      notation.id &&
      (isTextInsertion === (notation.type === NotationType.TEXT_INSERTION)),
    ) ?? []
  return { currentPageNotations }
}
export const currentPageNotationsInfo = (state: State) => {
  const currentPageNotationsValue = currentPageNotations(state).currentPageNotations
  const currentPageNotationsInfo = (activePresentation?: LoadedPresentation) => {
    const docLevelUserNotations = activePresentation?.currentPresentablePage.documentVersionORM.relations.userNotations
    const docLevelNotations = docLevelUserNotations?.notation || []
    const orm = activePresentation?.presentable.orm
    const isCustomDeck = isFolderItemORM(orm) && isCustomDeckORM(orm.relations.itemORM)
    const customDeckLevelUserNotations = isCustomDeck
      ? (orm.relations.itemORM as CustomDeckORM).relations.userNotations
      : undefined
    const customDeckLevelNotations = customDeckLevelUserNotations?.notation || []
    let customDeckCount = 0
    let sourceDocCount = 0

    const currentPageNotationsLevelMap = currentPageNotationsValue
      .reduce<CurrentPageNotationsLevelMap>((acc, notation) => {
        if (docLevelNotations.find(n => n.id === notation.id)) {
          acc[notation.id] = 'documentLevel'
          sourceDocCount++
        }
        else if (customDeckLevelNotations.find(n => n.id === notation.id)) {
          acc[notation.id] = 'customDeckLevel'
          customDeckCount++
        }
        return acc
      }, {})

    const hasMeetNotationLimits = isCustomDeck && !state.context.isTextInsertion
      ? customDeckCount >= CUSTOM_NOTE_LIMIT_PER_SLIDE
      : sourceDocCount >= CUSTOM_NOTE_LIMIT_PER_SLIDE
    return { currentPageNotationsLevelMap, hasMeetNotationLimits }
  }
  return { currentPageNotationsInfo }
}
