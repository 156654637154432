import React, { useEffect } from 'react';
import { Modal, StyleSheet } from 'react-native';
import {
  Iffy,
  DNABox,
  DNAHeader,
  DNAText,
  DNACard,
  DISCLAIMER_MODE,
  DNAIcon,
  DNAButton,
  Disclaimer,
  useDNASlider,
  withToastProvider,
} from '@alucio/lux-ui';
import { Divider } from '@ui-kitten/components';

import DNARouteStack, {
  useDNARouteStack,
} from 'src/components/DNA/Navigation/DNARouteStack/DNARouteStack';
import DNARouteTabs from 'src/components/DNA/Navigation/DNARouteTabs/DNARouteTabs';
import Password, { usePasswordForm, PasswordProvider } from './Password';
import { useHistory } from 'src/router';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { ROUTES } from 'src/router/routeDef';
import Settings from './Settings';

import { useAppSettings } from 'src/state/context/AppSettings';
import Offline from './Offline';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import CRMIntegration from './CRMIntegration';
import ActiveUser from 'src/state/global/ActiveUser';
import { useTenant } from 'src/state/redux/selector/tenant';

export type DisclaimerType = { isVisble: boolean; mode: DISCLAIMER_MODE };

const styles = StyleSheet.create({
  desktopTabsWrapper: {
    paddingBottom: 40,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 8,
  },
  desktopPageWrapper: {
    maxWidth: 1440,
    paddingHorizontal: 48,
    paddingVertical: 20,
  },
});

const ProfileDesktop = () => {
  const { isOnline } = useAppSettings()
  const tenant = useTenant(ActiveUser.user!.tenantId);
  const isCRMEnabled = !!tenant?.tenant.config?.crmIntegration

  return (
    <DNABox alignX="center">
      <DNABox fill style={styles.desktopPageWrapper}>
        <DNABox fill spacing="md" appearance="col">
          {/* Header */}
          <DNABox alignY="center" spacing="between">
            {/* @Kenny, what status was intended here? Secondary does not exist for this component */}
            <DNAText h5 status="secondary">
              Account Profile
            </DNAText>
          </DNABox>
          {/* Content */}
          <DNACard style={styles.desktopTabsWrapper}>
            <DNARouteTabs.Router>
              <DNARouteTabs.Tabs>
                <DNARouteTabs.Tab title="Password" path="/profile/password">
                  {isOnline ? <Password /> : <OfflinePasswordMessage />}
                </DNARouteTabs.Tab>
                <DNARouteTabs.Tab title="Beacon App" path="/profile/offline">
                  <Offline />
                </DNARouteTabs.Tab>
                {isCRMEnabled
                  ? <DNARouteTabs.Tab title="CRM Integration" path="/profile/crm-integration">
                    <CRMIntegration />
                  </DNARouteTabs.Tab>
                  : <></>}
                <DNARouteTabs.Unmatched rewrite="/profile/password" />
              </DNARouteTabs.Tabs>
            </DNARouteTabs.Router>
          </DNACard>
        </DNABox>
      </DNABox>
    </DNABox>
  );
};

const OfflinePasswordMessage: React.FC = () => {
  return (
    <DNABox style={{ height: 512 }}>
      <DNAEmpty
        emptyVariant={EmptyVariant.NoNetworkGenericUnavailable}
      />
    </DNABox>
  );
};

const OfflineMessage: React.FC = () => {
  const stack = useDNARouteStack();

  return (
    <Modal transparent visible>
      <DNABox
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        appearance="col"
        alignY="center"
        fill
        spacing="around"
      >
        <DNACard style={{ borderRadius: 4, marginHorizontal: 97 }}>
          <DNABox spacing="between" style={{ height: 64 }} alignY="center">
            <DNABox style={{ marginLeft: 20 }}>
              <DNAIcon.Styled
                name="wifi-off"
                appearance="ghost"
                status="tertiary"
              />
              <DNAText bold style={{ marginLeft: 21 }}>
                Looks like you’re offline.
              </DNAText>
            </DNABox>
            <DNAButton
              status="primary"
              appearance="ghost"
              style={{ marginRight: 20 }}
              onPress={() => stack.push('/profile')}
            >
              Close
            </DNAButton>
          </DNABox>
          <Divider />
          <DNABox alignY="center" style={{ marginLeft: 24, height: 84 }}>
            <DNAText>
              Please connect to the Internet to access this feature.
            </DNAText>
          </DNABox>
        </DNACard>
      </DNABox>
    </Modal>
  );
};

const DNAProfileHeaderTablet: React.FC = () => {
  const stack = useDNARouteStack();
  const slider = useDNASlider();
  const passwordForm = usePasswordForm();

  const isProfileScreen = stack.isCurrentPath('/profile', true);
  const isPasswordPage = stack.isCurrentPath('/profile/password', true);
  const isCRMIntegration = stack.isCurrentPath('/profile/crm-integration', true);
  const headerTitle = isCRMIntegration ? 'CRM Integration' : isPasswordPage ? 'Change Password' : 'Account Profile';

  return (
    <DNAHeader status="brand" title={headerTitle}>
      <DNAHeader.Left>
        <Iffy is={isProfileScreen}>
          <DNAButton
            status="secondary"
            onPress={() => {
              slider.setVisible(false);
            }}
            testID="tablet-header-close"
          >
            Close
          </DNAButton>
        </Iffy>
        <Iffy is={isPasswordPage}>
          <DNAButton
            status="secondary"
            disabled={passwordForm.changingInProgress}
            onPress={() => {
              passwordForm.clearFields();
              stack.pop();
            }}
            testID="tablet-header-close"
          >
            Cancel
          </DNAButton>
        </Iffy>
        <Iffy is={isCRMIntegration}>
          <DNAButton
            status="secondary"
            onPress={() => {
              stack.pop();
            }
            }
            testID="tablet-header-close"
          >
            {'<'}
          </DNAButton>
        </Iffy>
      </DNAHeader.Left>

      <DNAHeader.Right>
        <Iffy is={isPasswordPage}>
          <DNAButton
            disabled={!passwordForm.isPasswordValid}
            onPress={() => {
              passwordForm.onChangePassword().then((err) => {
                if (!err) stack.pop();
              });
            }}
            testID="change-password-button"
          >
            Change Password
          </DNAButton>
        </Iffy>
      </DNAHeader.Right>
    </DNAHeader>
  );
};

export const TabletProfile: React.FC = () => {
  const currentPage = useCurrentPage({ exact: false });
  const history = useHistory();
  const { isOnline } = useAppSettings()

  const isProfileScreen = currentPage?.PATH.includes(ROUTES.PROFILE.PATH)

  useEffect(() => {
    // PWA doesn't use the profile route, redirect user to root if a user somehow got here and
    // open the account profile slider. We set a value in localStorage and remove it in
    // ProfileMenuTablet component once user is redirected and the account profile slider has opened.
    const hasRedirected = localStorage.getItem('hasRedirectedFromProfile');

    if (isProfileScreen && !hasRedirected) {
      localStorage.setItem('isFromTabletProfile', 'true');
      localStorage.setItem('hasRedirectedFromProfile', 'true');
      history.replace('/');
    }

    return () => {
      localStorage.removeItem('isFromTabletProfile');
      localStorage.removeItem('hasRedirectedFromProfile');
    }
  }, [currentPage]);

  if (isProfileScreen) {
    return null;
  }

  return (
    <DNABox fill appearance="col" style={{ backgroundColor: 'white' }}>
      <DNABox>
        <PasswordProvider>
          <DNARouteStack.Internal initialPath="/profile">
            {/* Header */}
            <DNARouteStack.Header>
              <DNAProfileHeaderTablet />
            </DNARouteStack.Header>

            {/* Settings Page */}
            <DNARouteStack.Screen path="/profile">
              <Settings />
            </DNARouteStack.Screen>

            {/* Password Page */}
            <DNARouteStack.Screen
              path="/profile/password"
              style={{ height: '100vh' }}
            >
              {isOnline ? <Password /> : <OfflinePasswordMessage />}
            </DNARouteStack.Screen>

            {/* Password Offline Page */}
            <DNARouteStack.Screen
              style={{ backgroundColor: 'transparent' }}
              path="/profile/offline"
              skipAnimation
            >
              <OfflineMessage />
            </DNARouteStack.Screen>

            {/* Privacy Disclaimer Page */}
            <DNARouteStack.Screen path="/profile/privacy" skipAnimation>
              {(stack) => (
                <Disclaimer
                  mode={DISCLAIMER_MODE.Privacy}
                  onClose={() => stack.pop()}
                />
              )}
            </DNARouteStack.Screen>

            {/* TOU Disclaimer Page */}
            <DNARouteStack.Screen path="/profile/tou" skipAnimation>
              {(stack) => (
                <Disclaimer
                  mode={DISCLAIMER_MODE.ToU}
                  onClose={() => stack.pop()}
                />
              )}
            </DNARouteStack.Screen>

            {/* CRM Integration */}
            <DNARouteStack.Screen path="/profile/crm-integration" style={{ height: '100vh' }}>
              <CRMIntegration />
            </DNARouteStack.Screen>
          </DNARouteStack.Internal>
        </PasswordProvider>
      </DNABox>
    </DNABox>
  );
};

const componentWrapper = (ChildComponent) => () => <ChildComponent />;
const TabletToasterProfile = componentWrapper(withToastProvider(TabletProfile));

const Profile = (props) => {
  const { deviceMode } = useAppSettings();

  return deviceMode === 'desktop' ? (
    <ProfileDesktop {...props} />
  ) : (
    <TabletToasterProfile {...props} />
  );
};

export default Profile;
