import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
// @ts-ignore
import config from '../../config/app.json';
import {
  CustomDeckDependencies, CustomValues, ShareNotificationScope,
  ShareType,
} from '@alucio/aws-beacon-amplify/src/models'
import { generateContentShare } from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import { GenerateContentShareMutation } from '@alucio/aws-beacon-amplify/src/API';
import format from 'date-fns/format';
import { store } from 'src/state/redux';
import { currentUserORM } from 'src/state/redux/selector/user';

export interface ShareableLinkResult {
  contentId: string,
  expiresAt: string,
  id: string,
  link: string,
  title: string,
  type: string,
  customValues: CustomValues[],
  notificationScope: ShareNotificationScope,
}

async function getShareableLink(
  contentId: string,
  type: ShareType,
  title: string = '',
  customValues: CustomValues[],
  notificationScope: ShareNotificationScope,
  meetingId?: string,
  customDeckDependencies?: CustomDeckDependencies,
):
  Promise<ShareableLinkResult> {
  const expirationOverride = localStorage.getItem('shareExpirationMins')
  const user = currentUserORM(store.getState())
  const tenantHubShareExpirationDays = user?.meta.relations?.tenant?.config.hubsConfig.shareExpirationDays
  // 30 days is the default not unless configured differently for hubs
  const expirationDays = (type === ShareType.HUB && tenantHubShareExpirationDays) ? tenantHubShareExpirationDays : 30
  const expiration = expirationOverride
    ? parseInt(expirationOverride, 10)
    : expirationDays * 24 * 60;
  const params = {
    type,
    contentId,
    expiresInMins: expiration,
    meetingId,
    customValues,
    customDeckDependencies,
    notificationScope,
  }
  const shareResult: GraphQLResult<GenerateContentShareMutation> =
    await API.graphql(graphqlOperation(generateContentShare, params))! as GraphQLResult<GenerateContentShareMutation>;
  const { expiresAt, id, token } = shareResult.data!.generateContentShare!;
  const expiresAtFormatted = format(new Date(expiresAt), 'yyyy-MM-dd')
  const linkType = type === ShareType.HUB ? 'hubs' : 'share'

  return {
    contentId,
    expiresAt: expiresAtFormatted,
    link: `${config.contentURL}/${linkType}/${token}`,
    id,
    title,
    type,
    customValues,
    notificationScope,
  };
}

export {
  getShareableLink,
}
