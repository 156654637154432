import { Notation, Page } from '@alucio/aws-beacon-amplify/src/models'
import { AssociatedFileORM, DocumentVersionORM } from './orms'
import { PresentableModelORM } from 'src/state/context/ContentProvider/ContentProvider';

// [TODO-297]: We should make extendable interfaces for easier type composition

export enum DOCUMENT_ACTIONS_ENUM {
  present = 'present',
  modify = 'modify',
  download = 'download',
  share = 'share',
  externalNotation = 'externalNotation'
}
export enum VERSION_UPDATE_STATUS {
  NOT_PUBLISHED = 'NOT_PUBLISHED',
  CURRENT = 'CURRENT',
  PENDING_MAJOR = 'MAJOR',
  PENDING_MINOR = 'MINOR',
}
export interface PageExtended extends Page {
  documentVersionORM?: DocumentVersionORM;
  groupId?: string;
}

export interface EntityWithFiles {
  model: {
    id: string,
    documentId?: string
  },
  relations: {
    associatedFiles: AssociatedFileORM[],
  },
}

export interface PresentablePage {
  id: string,
  page: Page,
  presentationPageNumber: number,
  documentVersionORM: DocumentVersionORM,
  presentableGroup: PresentableGroup
}

export interface PresentableGroup {
  id: string,
  documentVersionORM: DocumentVersionORM,
  pages: PresentablePage[]
}

export interface Presentable {
  id: string,
  orm: PresentableModelORM,
  title: string,
  numberOfPages: number,
  presentablePages: PresentablePage[],
  presentableGroups: PresentableGroup[],
  notations?: Notation[],
}

export type PageMapping = {
  documentVersionId: string,
  prevPageIdMappings: Record<string, string>,
  unmatchedCount: number,
  undefinedCount: number,
  nullCount: number,
  missingPage: number,
}

export * from './enums'
export * from './featureFlags'
export * from './orms'
export * from './typeguards'
