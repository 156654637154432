import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { ScrollView } from 'react-native';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import FileUpload from 'src/components/DNA/FileUpload/FileUpload';
import { DNABox, DNAButton, DNADivider, DNAText, Iffy, InformationMessage } from '@alucio/lux-ui';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { usePublisherVersioningState } from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import {
  DocumentVersion,
  CustomFieldUsage,
  CustomFieldDefinition,
  FieldPosition,
  FileType,
  ConversionStatus,
  DocumentStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import {
  documentVersionFields,
  documentVersionConfig,
  getHydratedStaticValues,
  parseFormValuesToModel,
} from 'src/state/forms/versioning/staticFields';

// Components
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail';
import { ComposableForm, useComposableForm, composableVariantStyles } from 'src/components/CustomFields/ComposableForm';
import { ComposableField } from 'src/components/CustomFields/ComposableField';
import { styles } from '@alucio/beacon/src/components/Publishers/Styles';
import { S } from 'src/screens/Publishers/Versioning/VersioningPanel/VersioningPanel';
import DocumentInfoReadonly from './DocumentInfoReadonly';

// Redux
import { usePublisherList } from 'src/state/redux/selector/user';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { multiTypeSort } from 'src/state/redux/selector/common';
import { INFO_MESSAGES } from 'src/state/machines/publisherVersioning/constants';
import { useDNADocumentVersionActions } from 'src/components/DNA/Document/DNADocumentVersion.actions';
import {
  isDuplicatedDocumentTitle,
  useIndexedLunrDocumentList,
} from 'src/state/redux/selector/documentSearch/documentSearch';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';

const ERROR_MAP = {
  invalid_type: 'Missing required fields',
  invalid_date: 'Expiration date is invalid',
  nonempty_array_is_empty: 'Missing required fields',
}

export type DocumentInfoFormRef = {
  getDocumentInfoFormValues: () => Partial<DocumentVersion>,
  submitDraftValues: () => Partial<DocumentVersion> | null,
  submitPublishValues: () => Promise<Partial<DocumentVersion> | null>,
  isDirty: boolean,
}

const MAX_THUMBNAIL_SIZE = 1048576

// [TODO-3073] - Temp Sort, we should use the generic Selector sorter factories in the future
export const useDocumentTenantFields = () => {
  const customFields = useTenantCustomFields({ usages: { internalUsages: [CustomFieldUsage.DOCUMENT] } });

  const fields = useMemo(() => {
    const [leftFields, rightFields] = customFields.reduce<[CustomFieldDefinition[], CustomFieldDefinition[]]>(
      (acc, customField) => {
        const [leftFields, rightFields] = acc

        customField.settings?.fieldPosition === FieldPosition.LEFT
          ? leftFields.push(customField)
          : rightFields.push(customField)

        return acc
      },
      [[], []],
    )

    const leftTenantFields = leftFields.sort((a, b) => multiTypeSort(a.order, b.order, { dir: 'asc' }))
    const rightTenantFields = rightFields.sort((a, b) => multiTypeSort(a.order, b.order, { dir: 'asc' }))

    return { leftTenantFields, rightTenantFields }
  }, [customFields])

  return fields
}

type Error = { message?: string, type: string }

// [NOTE] - This is not the "real" error type
const useDocumentInfoFormatted = (errors: Record<string, Error | Record<string, Error>> = {}) => {
  const { currentDocumentVersionORM } = usePublisherVersioningState()
  const publishers = usePublisherList()

  const customFieldsLabels = Object.entries(currentDocumentVersionORM?.meta.tenantFields.configsMap ?? {})
  const selectedDate = currentDocumentVersionORM?.model.expiresAt
    ? new Date(format(
      parseISO(currentDocumentVersionORM.model.expiresAt),
      'MM/dd/yyyy',
    ))
    : undefined
  const publishersFormatted = publishers.reduce<Record<string, string[]>>(
    (acc, publisher) => {
      if (!acc[publisher.model.email]) {
        acc[publisher.model.email] = [publisher.meta.formattedName ?? '']
      } else {
        acc[publisher.model.email] = []
      }

      return acc
    },
    {},
  )

  // [TODO-2126] - The informational banner error messaging is different than the error messaging
  //          underneath each individual field, consult Thai on what the banner messaging should be
  //        - It's easy enough for required, but for other fields, (Incorrect date), what do we want set the message as?
  //        - Form errors are generally straight forward, but we need to consider other machine errors as well
  const consolidatedFormErrors = [...new Set(
    Object
      .values(errors)
      .map(err => {
        // Non-nested errors
        if (typeof err.type === 'string') {
          const error = err as Error
          const isCustomDateErr = error.type === 'custom' && error.message?.includes('date')

          return [
            isCustomDateErr
              ? 'invalid_date'
              : error.type,
          ]
        }
        // Shallow nested errors
        else {
          const error = err as Record<string, Error>
          return [...new Set(Object.values(error).map(nestedErr => nestedErr.type))]
        }
      })
      .flat(),
  )]

  const formattedFormErrors = [...new Set(consolidatedFormErrors.map(err => ERROR_MAP[err]))].join('\n')

  return {
    customFieldsLabels,
    publishersFormatted,
    selectedDate,
    errorMessages: formattedFormErrors,
  }
}

const useDocumentInfoForm = (ref:
  | ((instance: DocumentInfoFormRef | null) => void)
  | React.MutableRefObject<DocumentInfoFormRef | null>
  | null,
) => {
  const { service, currentDocumentVersionORM } = usePublisherVersioningState()
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.documentInfoIsDirty,
    ),
  )
  const lunrDocumentsIndex = useIndexedLunrDocumentList(true);
  const { rhForm, resetForm } = useComposableForm()
  const { formState: { isDirty } } = rhForm

  useEffect(() => {
    service.onEvent((event) => {
      if (event.type === 'SAVE_DRAFT' && isDirty && !cond.documentInfoIsDirty) {
        rhForm.reset(rhForm.getValues(), { keepDirty: false });
      }
    });
  }, [service, isDirty, rhForm, cond.documentInfoIsDirty]);

  useEffect(() => {
    if (
      (!isDirty && cond.documentInfoIsDirty) ||
      (isDirty && !cond.documentInfoIsDirty)
    ) {
      service.send({ type: 'SET_IS_DIRTY', payload: { type: 'info', isDirty } })
    }
  }, [service.send, isDirty, cond.documentInfoIsDirty])

  // [TODO-2126] - This resets the form values on DocVer switching
  //             - This is probably a better way to do this explicitly rather than useEffect observation
  useEffect(() => {
    if (!currentDocumentVersionORM) return;
    const staticValues = getHydratedStaticValues(currentDocumentVersionORM)
    const values = currentDocumentVersionORM.meta.customValues.configsMap
    resetForm({ ...staticValues, ...values })
  }, [currentDocumentVersionORM?.model.id])

  // [TODO-3073] - Previously, we coalesced NULL values into UNDEFINED -- do we still need to do that?
  const getDocumentInfoFormValues = (): Pick<DocumentVersion, 'title' | 'customValues'> => {
    return parseFormValuesToModel(rhForm.getValues())
  }

  const isDuplicatedTitle = (): boolean => {
    if (!currentDocumentVersionORM) return false;
    const { id } = currentDocumentVersionORM.relations.documentORM.model;
    const title = rhForm.getValues('title')
    const isDuplicated = !!(title &&
      isDuplicatedDocumentTitle(
        lunrDocumentsIndex,
        Array.isArray(title) ? title[0].toString() : title,
        id,
      )
    )
    if (isDuplicated) {
      rhForm.setError('title', { message: 'This title already exists.' })
    }
    return isDuplicated
  };

  const submitDraftValues = (): Partial<DocumentVersion> | null => {
    const values = parseFormValuesToModel(rhForm.getValues());
    const isDuplicated = isDuplicatedTitle();
    return isDuplicated ? null : values;
  }

  const submitPublishValues = async (): Promise<Partial<DocumentVersion> | null> => {
    const isValid = await rhForm.trigger() // Validate form
    const isDuplicated = isDuplicatedTitle()
    if (!isValid || isDuplicated) { return null }
    return parseFormValuesToModel(rhForm.getValues())
  }

  useImperativeHandle(
    ref,
    () => ({
      getDocumentInfoFormValues,
      submitDraftValues,
      submitPublishValues,
      isDirty,
    }),
  )
}

const DocumentInfo: React.FC = () => {
  const {
    service,
    currentDocumentVersionORM,
    documentInfoRef,
    exceedsMaxOfflineSize,
    isPasswordProtected,
    handleSaveDraft,
  } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.isUpdatingThumbnail,
      publisherVersioningSelector.isCancellingNewVersionUpload,
      publisherVersioningSelector.hasOptimizedFinishedThisSession,
      publisherVersioningSelector.hasInfoMessage,
      publisherVersioningSelector.infoMessage,
      publisherVersioningSelector.cancelMessage,
    ),
  )

  useDocumentInfoForm(documentInfoRef);
  const [showContentPreviewWebDocument, setShowContentPreviewWebDocument] = useState(false);
  const [showThumbnailError, setshowThumbnailError] = useState(false);
  const documentActions = useDNADocumentVersionActions();
  const { rhForm } = useComposableForm();
  const formatted = useDocumentInfoFormatted(rhForm.formState.errors as any);
  const { leftTenantFields, rightTenantFields } = useDocumentTenantFields();
  const variantStyle = composableVariantStyles.DEFAULT;
  const enablePowerPointAddin = useFeatureFlags('enablePowerPointAddin');

  const previewWebDocumentDraft = useCallback(() => {
    if (!currentDocumentVersionORM) return;
    setShowContentPreviewWebDocument(false);
    documentActions.preview(currentDocumentVersionORM, undefined, undefined, true)();
  }, [currentDocumentVersionORM, setShowContentPreviewWebDocument, documentActions])

  useEffect(() => {
    if (showContentPreviewWebDocument) previewWebDocumentDraft();
  }, [currentDocumentVersionORM?.model.contentURL])

  const formScrollPanel = React.createRef<typeof DNABox>();
  const docVersion = currentDocumentVersionORM && cond.isUpdatingThumbnail ? {
    ...currentDocumentVersionORM,
    model: {
      ...currentDocumentVersionORM.model,
      conversionStatus: ConversionStatus.PROCESSING,
    },
  } : currentDocumentVersionORM

  if (!currentDocumentVersionORM || !docVersion) return null;
  const isWebDoc = currentDocumentVersionORM.model.type === FileType.WEB;
  const isVideo = currentDocumentVersionORM.model.type === FileType.MP4;
  const isHTMLDoc = currentDocumentVersionORM.model.type === FileType.HTML;
  const isNonDocumentFile = isWebDoc || isVideo || isHTMLDoc;
  // [TODO] Check if current thumbnail is a user upload or default image
  const userUploadThumbnail = currentDocumentVersionORM.model.hasCustomThumbnail!;
  const showPreviewButton = isWebDoc && currentDocumentVersionORM.model.status === DocumentStatus.NOT_PUBLISHED;
  const isSavePreviewButtonDisabled = !!rhForm.formState.errors.contentURL ||
    (!rhForm.getValues().contentURL && !currentDocumentVersionORM.model.contentURL) ||
    !!rhForm.formState.errors.title;

  const saveDraftAndPreview = () => {
    const documentInfoFormData = parseFormValuesToModel(rhForm.getValues()) ?? {}
    handleSaveDraft()
    setShowContentPreviewWebDocument(true)
    if (documentInfoFormData.contentURL === currentDocumentVersionORM.model.contentURL) {
      previewWebDocumentDraft()
    }
  }

  function selectNewThumbnail(files: FileList | null): void {
    setshowThumbnailError(false)
    const file = files?.item(0)
    if (!file) return;
    if (file.size > MAX_THUMBNAIL_SIZE) {
      setshowThumbnailError(true)
      return
    }
    service.send({ type: 'UPLOAD_THUMBNAIL', payload: { file } })
  }

  return (
    <DNABox fill appearance="col" style={S.tabContentContainer}>
      {/* INFORMATIONAL BANNERS */}
      {/* FORM ERRORS */}
      <DNABox appearance="col" style={styles.BannerContainer}>
        <Iffy is={formatted.errorMessages}>
          <InformationMessage
            text={formatted.errorMessages}
            variance="danger"
          />
        </Iffy>
        {/* EDIT READY */}
        <Iffy is={cond.hasOptimizedFinishedThisSession}>
          <InformationMessage
            text={INFO_MESSAGES.UPLOADED.message}
            variance={INFO_MESSAGES.UPLOADED.status}
          />
        </Iffy>
        {/* GENERIC MACHINE STATES */}
        <Iffy is={cond.hasInfoMessage}>
          <InformationMessage
            text={cond.isCancellingNewVersionUpload
              ? cond.cancelMessage?.message
              : cond.infoMessage?.message
            }
            variance={cond.isCancellingNewVersionUpload
              ? cond.cancelMessage?.status
              : cond.infoMessage?.status
            }
          />
        </Iffy>
        <Iffy is={exceedsMaxOfflineSize}>
          <InformationMessage
            text={INFO_MESSAGES.FILE_TOO_BIG.message}
            variance={INFO_MESSAGES.FILE_TOO_BIG.status}
          />
        </Iffy>
        <Iffy is={isPasswordProtected && enablePowerPointAddin}>
          <InformationMessage
            text={INFO_MESSAGES.PASSWORD_PROTECTED.message}
            variance={INFO_MESSAGES.PASSWORD_PROTECTED.status}
          />
        </Iffy>
      </DNABox>
      <ScrollView>
        {/* THUMBNAIL + TITLE */}
        <DNABox spacing="lg" childFill={1} alignY="start" style={{ marginBottom: 10 }}>
          {/* THUMBNAIL */}
          <DNABox appearance="col" spacing="sm">
            <DNAText style={variantStyle.title}>Cover Thumbnail</DNAText>
            <DNABox testID="version-document-thumbnail" style={styles.ThumbnailContainer}>
              <DNADocumentThumbnail
                documentVersionORM={docVersion}
                width={isNonDocumentFile ? 216 : 137}
                height={isNonDocumentFile ? 124 : 75}
                showProcessing={true}
              />
            </DNABox>
            <Iffy is={isNonDocumentFile}>
              <FileUpload.Input
                fileExt={['PNG']}
                onSelect={selectNewThumbnail}
                maxFileSize={MAX_THUMBNAIL_SIZE}
                processOnSelect={false}
                s3PathPrefix="publish_tmp/"
              >
                <DNAButton
                  appearance="outline"
                  status="tertiary"
                  iconLeft="upload"
                  disabled={cond.isUpdatingThumbnail}
                  padding="xs"
                  style={{ width: 216, height: 24 }}
                >
                  Replace thumbnail
                </DNAButton>
              </FileUpload.Input>
              <Iffy is={userUploadThumbnail}>
                <DNAButton
                  appearance="outline"
                  status="tertiary"
                  onPress={() => service.send({ type: 'REMOVE_THUMBNAIL' })}
                  disabled={cond.isUpdatingThumbnail}
                  iconLeft="trash-can-outline"
                  padding="xs"
                  style={{ width: 216, height: 24 }}
                >
                  Remove
                </DNAButton>
              </Iffy>
              <DNABox appearance="col">
                <DNAText p2 status="flatAlt">Recommended dimensions:</DNAText>
                <DNAText p2 status="flatAlt">640x360 pixels</DNAText>
                {showThumbnailError && <DNABox appearance="col" >
                  <DNAText p2 status="danger">This file is too large, the maximum</DNAText>
                  <DNAText p2 status="danger"> supported file size is 1MB.</DNAText>
                </DNABox>}
              </DNABox>
            </Iffy>
          </DNABox>
          {/* TITLE */}
          <DNABox appearance="col" fill alignY="end" spacing="sm">
            <ComposableField field={documentVersionFields.title} />
            <Iffy is={isWebDoc}>
              <ComposableField
                field={{
                  ...documentVersionFields.contentURL,
                  required: true,
                }}
                hintMessage={'You may want to periodically review this link to ensure it is not broken.'}
                placeholder={'https://www.website.com'}
              />
              {showPreviewButton &&
                <DNAButton
                  appearance="outline"
                  padding="xs"
                  onPress={saveDraftAndPreview}
                  disabled={isSavePreviewButtonDisabled}
                  testID="save-draft-preview-link-btn"
                >
                  Save draft & preview link
                </DNAButton>
              }
            </Iffy>
          </DNABox>
        </DNABox>
        <DNABox ref={formScrollPanel}>
          {/* LEFT SIDE */}
          <DNABox appearance="col" fill spacing="lg">
            {
              leftTenantFields.map(leftTenantField => (
                <ComposableField key={leftTenantField.id} field={leftTenantField} />
              ))
            }
            <Iffy is={docVersion.meta.integration.source}>
              <ComposableField
                disabled
                field={documentVersionFields.contentSource}
              />
            </Iffy>
          </DNABox>
          {/* COLUMN DIVIDER */}
          <DNADivider vertical style={styles.ColumnDivider} />
          {/* RIGHT SIDE */}
          <DNABox appearance="col" fill spacing="lg">
            {
              rightTenantFields.map(leftTenantField => (
                <ComposableField key={leftTenantField.id} field={leftTenantField} />
              ))
            }
          </DNABox>
        </DNABox>
      </ScrollView>
    </DNABox>
  )
}

const DocumentInfoFormWrapper: React.FC = () => {
  const { service, currentDocumentVersionORM } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.isInDraftingState,
    ),
  )

  const customFields = useTenantCustomFields({ usages: { internalUsages: [CustomFieldUsage.DOCUMENT] } });
  if (!currentDocumentVersionORM) return null;
  const staticValues = getHydratedStaticValues(currentDocumentVersionORM);
  const values = currentDocumentVersionORM.meta.customValues.configsMap;
  return (
    <ComposableForm
      staticFields={documentVersionConfig(currentDocumentVersionORM)}
      customFields={customFields}
      values={values}
      staticValues={staticValues}
      disabled={!cond.isInDraftingState}
    >
      <DocumentInfo />
    </ComposableForm>
  )
}

const DocumentInfoWrapper: React.FC = () => {
  const { service } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.isInDraftingState,
      publisherVersioningSelector.isProcessingNewVersion,
    ),
  )

  if (!cond.isInDraftingState || cond.isProcessingNewVersion) return <DocumentInfoReadonly />
  else return <DocumentInfoFormWrapper />
}

export default DocumentInfoWrapper
