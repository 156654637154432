import React, { useState } from 'react';
import { LayoutChangeEvent, StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAContextMenu, DNAIcon, DNAText, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

export const S = StyleSheet.create({
  // GENERAL
  buttonIconStyle: {
    marginRight: 4,
  },
  // REMOVE SLIDE BUTTON
  leftRemoveButton: {
    borderRadius: 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  contextMenuItems: {
    top: 4,
  },
  contextMenuButton: {
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: 1,
  },
  // REVIEW REPLACE BUTTON
  updatedIcon: {
    fontSize: 18,
    marginRight: 6,
    color: colors['color-success-500'],
  },
  updatedText: {
    fontWeight: '700',
  },
})

enum ButtonVariantEnum {
  RemoveSlide = 'RemoveSlide',
  ReviewReplace = 'ReviewReplace',
  FindReplacement = 'FindReplacement',
  InsertText = 'InsertText',
}
export type ButtonVariant = keyof typeof ButtonVariantEnum

type ThumbnailFooterButtonProps = {
  variant: ButtonVariant,
  onPress?: () => void,
  onPressSecondaryBtn?: () => void,
}

const ThumbnailFooterButton: React.FC<ThumbnailFooterButtonProps> = ({
  variant,
  onPress,
  onPressSecondaryBtn,
}) => {
  const [removeSlideMenuWidth, setRemoveSlideMenuWidth] = useState<number>(0);

  const handleRemoveSlideContainerLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setRemoveSlideMenuWidth(width); // Add extra padding if necessary
  };

  return (
    <>
      {/* REMOVE SLIDE BUTTON */}
      <Iffy is={variant === ButtonVariantEnum.RemoveSlide}>
        <DNABox fill onLayout={handleRemoveSlideContainerLayout}>
          <DNAButton
            status="warning"
            iconLeft="trash-can-outline"
            iconStyle={S.buttonIconStyle}
            onPress={onPress}
            style={S.leftRemoveButton}
            children="Remove"
            stretch
          />
          <DNAContextMenu
            style={[S.contextMenuItems, { width: removeSlideMenuWidth }]}
            placement="bottom end"
          >
            <DNAContextMenu.Anchor>
              <DNAButton
                onPress={() => { }}
                iconLeft="chevron-down"
                status="warning"
                padding="sm"
                style={S.contextMenuButton}
              />
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              <DNAContextMenu.Item
                collapseOnPress
                delay={100}
                title="Remove all unavailable slides"
                onPress={onPressSecondaryBtn || (() => {})}
              />
            </DNAContextMenu.Items>
          </DNAContextMenu>
        </DNABox>
      </Iffy>
      {/* REVIEW OR REPLACE BUTTON */}
      <Iffy is={variant === ButtonVariantEnum.ReviewReplace}>
        <DNAButton
          appearance="outline"
          status="tertiary"
          testID="review-or-replace-button"
          onPress={onPress}
          children="Review or replace"
          stretch
        />
        <DNABox alignX="center">
          <DNAIcon
            testID="updated_slide_icon"
            name="check-bold"
            size="md"
            style={S.updatedIcon}
          />
          <DNAText
            p1
            status="success"
            style={S.updatedText}
          >
            Updated
          </DNAText>
        </DNABox>
      </Iffy>
      {/* FIND REPLACEMENT BUTTON */}
      <Iffy is={variant === ButtonVariantEnum.FindReplacement}>
        <DNAButton
          testID="find-and-replace-button"
          status="warning"
          iconLeft="sync"
          iconStyle={S.buttonIconStyle}
          onPress={onPress}
          children="Find replacement"
          stretch
        />
      </Iffy>
      {/* INSERT TEXT BUTTON */}
      <Iffy is={variant === ButtonVariantEnum.InsertText}>
        <DNAButton
          testID="insert-text-on-slide-button"
          appearance="outline"
          status="tertiary"
          onPress={onPress}
          children="Insert text on slide"
          stretch
        />
      </Iffy>
    </>
  )
}

export default ThumbnailFooterButton;
