import React, { useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAChip, DNAText, Hoverable, Iffy, luxColors } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles';
import { AssociatedFileORM, DocumentORM, getAssociatedFileTitle } from 'src/types/types';
import { useAppSettings } from 'src/state/context/AppSettings';
import { downloadContentFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset';
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'
import { isAssociatedFileAttachment, isPublishedDocumentAttachment } from 'src/types/typeguards';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useContentPreviewModal } from '../../State/ContentPreviewModalStateProvider';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { useAllDocumentsInstance } from 'src/state/redux/selector/document';
import { tabConfigs } from 'src/components/ContentPreviewModalV2/SideBar/SideBar';
import SidebarContent from 'src/components/ContentPreviewModalV2/SideBar/SideBarContent';

const styles = StyleSheet.create({
  fileRow: {
    paddingBottom: 16,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    maxWidth: 280,
  },
  hoveredRow: {
    backgroundColor: luxColors.mainContainer.primary,
  },
  unavailableContent: {
    opacity: 0.4,
  },
})

interface FileRowProps {
  file: AssociatedFileORM,
}

const AssociatedDocumentRow: React.FC<FileRowProps> = ({ file }) => {
  const { isOnline } = useAppSettings()
  const { addPresentation } = useContent()
  const { setIsAssociatedFile } = useContentPreviewModal()
  const [attachedDocumentORM]:(DocumentORM)[] = useAllDocumentsInstance({
    filter: {
      model: { id: file.file?.id },
    },
  })
  const attachedDocumentVersionORM = attachedDocumentORM.relations.version.latestUsableDocumentVersionORM
  const documentActions = useDNADocumentActions()

  const [isHovered, setIsHovered] = useState<boolean>(false)
  const isWebDoc = attachedDocumentORM.model.type === FileType.WEB
  const unavailableContent =
    (!isOnline && !attachedDocumentVersionORM.meta.assets.isContentCached) ||
    (isWebDoc && !isOnline)

  const toggleHover = () => {
    setIsHovered(currVal => !currVal);
  }

  const onFileSelected = () => {
    setIsAssociatedFile(true)
    addPresentation(attachedDocumentVersionORM)
  }
  return (
    <Pressable onPress={onFileSelected} disabled={unavailableContent}>
      <Hoverable onHoverIn={toggleHover} onHoverOut={toggleHover}>
        <DNABox
          childFill
          appearance="col"
          spacing="md"
          style={mergeStyles(
            undefined,
            styles.fileRow,
            [styles.hoveredRow, isHovered],
            [styles.unavailableContent, unavailableContent],
          )}
        >
          <DNAText testID="cpm-associated-file-text" style={{ maxWidth: 280 }}>{getAssociatedFileTitle(file)}</DNAText>
          <DNABox
            fill
            spacing="between"
            alignY="end"
          >
            <DNABox spacing="xs">
              <CustomFieldBadgeList
                documentVersionORM={attachedDocumentVersionORM}
              />
              <DNAChip appearance="tag">{attachedDocumentORM.model.type}</DNAChip>
              <Iffy is={unavailableContent}>
                <DNAChip
                  appearance="tag"
                  testID={`content-not-available-${attachedDocumentVersionORM.model.title}`}
                >
                  CONTENT NOT AVAILABLE
                </DNAChip>
              </Iffy>
            </DNABox>
            <Iffy is={attachedDocumentORM.meta.permissions.MSLDownload && !isWebDoc}>
              <DNABox alignX="end" alignY="center">
                <DNAButton
                  appearance="ghostLink"
                  status="gray"
                  iconLeft="download"
                  size="sm"
                  onPress={documentActions.download(attachedDocumentORM)}
                  padding="none"
                />
              </DNABox>
            </Iffy>
          </DNABox>
        </DNABox>
      </Hoverable>
    </Pressable>
  )
}

const AttachedFileRow: React.FC<FileRowProps> = (props) => {
  const { isOnline } = useAppSettings()
  const { file } = props
  const attachedFile = file.file
  const [isHovered, setIsHovered] = useState<boolean>(false);
  if (!attachedFile || isPublishedDocumentAttachment(attachedFile)) return null

  // TODO CHECK ID FOR ATTACHED DOCUMENTS
  const onFileSelected = () => {
    downloadContentFromCloudfront(attachedFile.srcFile.key, attachedFile.srcFileName, attachedFile.type)
    analytics?.track('DOCUMENT_AF_DOWNLOAD', {
      action: 'AF_DOWNLOAD',
      attachedContentId: file.model.attachmentId,
      category: 'DOCUMENT',
      documentId: file.relations.latestUsableDocumentVersion?.documentId,
      type: file.model.type,
    });
  }
  return (
    <Pressable onPress={onFileSelected} disabled={!isOnline}>
      <Hoverable
        onHoverIn={() => setIsHovered(currVal => !currVal)}
        onHoverOut={() => setIsHovered(currVal => !currVal)}
      >
        <DNABox
          appearance="col"
          spacing="md"
          style={mergeStyles(
            undefined,
            styles.fileRow,
            [styles.hoveredRow, isHovered],
            [styles.unavailableContent, !isOnline],
          )}
        >
          <DNAText style={{ maxWidth: 280 }}>{attachedFile.title}</DNAText>
          <DNABox
            fill
            spacing="between"
            alignY="end"
          >
            <DNABox spacing="xs">
              <DNAChip
                appearance="tag"
                style={{ alignSelf: 'flex-start' }}
              >
                {attachedFile.type}
              </DNAChip>
              <Iffy is={!isOnline}>
                <DNAChip
                  appearance="tag"
                  testID={`content-not-available-${attachedFile.title}`}
                >
                  CONTENT NOT AVAILABLE
                </DNAChip>
              </Iffy>
            </DNABox>
            <DNABox alignX="end" alignY="end">
              <DNAButton
                appearance="ghostLink"
                status="gray"
                iconLeft="download"
                size="sm"
                onPress={onFileSelected}
                padding="none"
              />
            </DNABox>
          </DNABox>
        </DNABox>
      </Hoverable>
    </Pressable>
  )
}

const AssociatedFiles: React.FC = () => {
  const { activeDocumentVersionORM } = useContentPreviewModal()
  if (!activeDocumentVersionORM) return null

  const versionElements = activeDocumentVersionORM.relations.associatedFiles
    .filter(({ model, file }) => {
      if (isAssociatedFileAttachment(model)) return true
      return isPublishedDocumentAttachment(file)
    })
    .map(assocFile => {
      const { file, model: { id } } = assocFile
      if (isPublishedDocumentAttachment(file)) {
        return (<AssociatedDocumentRow
          file={assocFile}
          key={id}
        />)
      }
      return (<AttachedFileRow
        file={assocFile}
        key={id}
      />)
    })

  return (
    <SidebarContent>
      <SidebarContent.Header
        sidebarHeader={tabConfigs.associatedFilesSidebar.label}
      />
      <SidebarContent.Body>
        <DNABox fill appearance="col" spacing="md">
          {versionElements}
        </DNABox>
      </SidebarContent.Body>
    </SidebarContent>
  )
}

export default AssociatedFiles;
