import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DNAModalProps = {
  isVisible: DNAModalState['isVisible'],
  setModal: (a: DNASetModalArgs) => void,
  closeModal: () => void,
  toggleModal: () => void,
  pushModal: (c: DNAModalComponent) => void,
}

export type DNAModalComponent = (p: DNAModalProps) => React.ReactElement

export type DNAModalState = {
  isVisible: boolean,
  allowBackdropCancel: boolean,
  backdropVisible: boolean,
  // [TODO] Consider supporting Modal stacks
  component: DNAModalComponent[],
}

export type DNASetModalArgs = Pick<
  DNAModalState,
  | 'isVisible'
  | 'allowBackdropCancel'
> & {
  backdropVisible?: boolean,
  component: DNAModalComponent | DNAModalComponent[]
}

export const initialState: DNAModalState = {
  isVisible: false,
  allowBackdropCancel: false,
  backdropVisible: true,
  component: [],
}

const DNAModalSlice = createSlice({
  name: 'DNAModalSlice',
  initialState: initialState,
  reducers: {
    setModal: (
      state,
      { payload }: PayloadAction<DNASetModalArgs>) => {
      const { component, ...restPayload } = payload

      return {
        ...state,
        ...restPayload,
        component: Array.isArray(component) ? component : [component],
      }
    },
    pushModal: (
      state,
      { payload }: PayloadAction<DNAModalComponent>,
    ) => {
      state.isVisible = true
      if (state.component) {
        state.component.push(payload)
      }
      else {
        state.component = [payload]
      }
    },
    closeModal: () => initialState,
    // [TODO] - Better control the DNAModal to not blindly pop items off the top of the component stack
    //          but rather to pop by a given ID that should be assigned to each item in the array instead
    toggleModal: (state) => {
      state.component.pop()

      if (!state.component.length) {
        state.isVisible = false
      }
    },
  },
})

export default DNAModalSlice;
export const DNAModalActions = DNAModalSlice.actions
