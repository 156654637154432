import React from 'react';
import { ScrollView } from 'react-native';
import { DNABox, DNAButton, DNAText, DNASlider, DNACheckbox, DNADivider } from '@alucio/lux-ui';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import { useSlideSettingsState } from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider';
import { getThumbURL } from 'src/utils/thumbnailHelpers';
import { isAllSlidesChecked } from 'src/state/machines/publisherVersioning/SlideSettings/util';
import ModalHeader from '../ModalHeader';
import { styles } from '../SlideSettings';

const SetRequiredSlidesModal: React.FC = () => {
  const {
    service,
    currentDocumentVersionORM,
    thumbnailSizes,
    getSlideTitle,
    getHasSlideTextInsertionEnabled,
    backToIdle,
    saveRequiredSlides,
    selectAllRequiredSlide,
    selectRequiredSlide,
  } = useSlideSettingsState()

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.versionDraft,
      slideSettingsSelector.selectedRequiredSlides,
      slideSettingsSelector.isSetRequiredSlidesMode,
      slideSettingsSelector.canSaveRequiredSlides,
    ),
  )

  const selectedPages = Object.values(cond.selectedRequiredSlides).filter(val => val)
  const selectedText = `${selectedPages.length} of ${cond.versionDraft.pages.length} Selected`
  const allSelected = isAllSlidesChecked(
    cond.versionDraft.pages,
    cond.selectedRequiredSlides,
  )

  return (
    <DNASlider
      visible={cond.isSetRequiredSlidesMode}
      setVisible={backToIdle}
      orientation="horizontal"
    >
      <DNABox
        fill
        appearance="col"
        style={{ backgroundColor: colors['color-text-white'] }}
      >
        <ModalHeader
          title="Which slides are required when building a custom presentation?"
          saveDisabled={!cond.canSaveRequiredSlides}
          onSave={saveRequiredSlides}
          onCancel={backToIdle}
        />

        {/* CONTENT */}
        <DNABox fill>
          <DNABox fill appearance="col">
            <DNABox style={styles.rowWrapper} alignY="center">
              <DNABox spacing="md" alignY="center">
                <DNACheckbox
                  checked={allSelected}
                  onChange={selectAllRequiredSlide}
                  style={{ marginLeft: 5, marginRight: 7 }}
                />
                <DNAText status="flatAlt">Select all</DNAText>
                <DNADivider style={styles.verticalDivider} />
                <DNAText status="flatAlt">
                  {selectedText}
                </DNAText>
              </DNABox>
              <DNABox fill alignX="end">
                <DNAButton
                  appearance="ghostLink"
                  status="tertiary"
                  size="md"
                  padding="none"
                  onPress={thumbnailSizes.cycleThumbnailSize}
                  iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
                />
              </DNABox>
            </DNABox>

            <DNABox fill>
              <ScrollView style={styles.content}>
                <DNABox
                  appearance="row"
                  wrap="start"
                  childStyle={{ marginBottom: 32 }}
                  alignX="center"
                >
                  {
                    cond.versionDraft.pages?.map((page) => {
                      const coverThumbnailPageNumber = cond.versionDraft.selectedThumbnail ?? 1
                      const thumbURL = getThumbURL(currentDocumentVersionORM, page.number)
                      const isRequired = cond.selectedRequiredSlides[page.pageId]
                      const isCover = page.number === coverThumbnailPageNumber

                      return (
                        <DNABox key={thumbURL} style={{ marginRight: 24, marginBottom: 24 }}>
                          <DNAThumbnail
                            s3URL={thumbURL}
                            useLoadingIndicator
                            size={thumbnailSizes.thumbnailSize}
                            mode={DNAThumbnail.Modes.SELECTABLE}
                            variant={DNAThumbnail.Variants.INFO}
                            pageNumber={page.number}
                            checked={isRequired}
                            isCover={isCover}
                            isTextInsertionEnabled={getHasSlideTextInsertionEnabled(page.number)}
                            thumbnailTitle={getSlideTitle(page.number)}
                            isRequired={isRequired}
                            onCheck={() => selectRequiredSlide(page.pageId)}
                          />
                        </DNABox>
                      )
                    })
                  }
                </DNABox>
              </ScrollView>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNASlider>
  )
}

export default SetRequiredSlidesModal
