import React from 'react';
import { StyleSheet } from 'react-native';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  DNABox,
  DNAButton,
  DNAText,
  DatePicker,
  GenericToast,
  Iffy,
  ToastOrientations,
  useToast,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import InputComponent from 'src/components/Publishers/InputComponent';
import { styles } from 'src/components/Publishers/Styles';

const S = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    overflowX: 'auto',
    paddingVertical: 40,
    paddingHorizontal: 32,
  },
  inputContainer: {
    maxWidth: 500,
  },
});

interface ReportsProps {
  tenantId: string;
}

const formSchema = z.object({
  context: z
    .string()
    .min(1, { message: 'This field is required' }),
  date: z
    .string()
    .min(1, { message: 'This field is required' })
    .datetime(),
})

const Reports = (props: ReportsProps) => {
  const { tenantId } = props
  const toast = useToast()

  const form = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: {
      context: '',
      date: '',
    },
  })

  const {
    control, handleSubmit,
    formState: { errors, isSubmitting },
  } = form

  const clearForm = () => form.reset()

  const onSubmit = () => {
    const onValid = (formValue) => {
      analytics?.track('SIGNIFICANT_DATE', {
        category: 'SIGNIFICANT_DATE',
        tenantId,
        context: formValue.context,
        timeStamp: formValue.date,
      });
      toast?.add(
        <GenericToast
          title="Event created."
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
      clearForm()
    }
    handleSubmit(onValid)()
  }

  return (
    <DNABox fill appearance="col" spacing="md" style={S.mainWrapper}>
      <DNAText h3>Event Tracking</DNAText>
      <DNABox fill appearance="col" spacing="md" style={S.inputContainer}>
        <Controller
          name="context"
          control={control}
          render={({ field: { onChange, value } }) => {
            const errorMsg = typeof errors.context?.message === 'string' ? errors.context?.message : undefined
            return (
              <DNABox appearance="col">
                <DNABox>
                  <DNAText style={styles.Required}>*</DNAText>
                  <DNAText style={styles.Title}>Event Description</DNAText>
                </DNABox>
                <InputComponent
                  value={value}
                  onChangeText={onChange}
                  required
                  hideLabel={true}
                  numOfLines={1}
                  characterLimit={300}
                  disabled={false}
                  inputStyle={errors.context && { borderColor: colors['color-danger-500'] }}
                  removeMarginPadding={true}
                />
                <Iffy is={errorMsg}>
                  <DNAText status="danger">{errorMsg}</DNAText>
                </Iffy>
              </DNABox>
            )
          }}
        />
        <Controller
          name="date"
          control={control}
          render={({ field: { onChange, value } }) => {
            const selectedDate = value
              ? new Date(value)
              : undefined
            const errorMsg = typeof errors.date?.message === 'string' ? errors.date?.message : undefined
            return (
              <DNABox appearance="col">
                <DNABox>
                  <DNAText style={styles.Required}>*</DNAText>
                  <DNAText style={styles.Title}>Event Date</DNAText>
                </DNABox>
                <DatePicker
                  selectedDate={selectedDate}
                  onDateSelected={(date) => onChange(date.toISOString())}
                  dateFormat="MM/dd/yy"
                  style={errors.date && { borderColor: colors['color-danger-500'] }}
                />
                <Iffy is={errorMsg}>
                  <DNAText status="danger">{errorMsg}</DNAText>
                </Iffy>
              </DNABox>
            )
          }}
        />
      </DNABox>
      <DNABox fill spacing="sm">
        <DNAButton
          appearance="outline"
          status="tertiary"
          onPress={clearForm}
        >
          Cancel
        </DNAButton>
        <DNAButton
          onPress={onSubmit}
          disabled={isSubmitting}
        >
          Create event
        </DNAButton>
      </DNABox>
    </DNABox>
  );
};

Reports.displayName = 'Reports';

export default Reports;
