import {
  ActionCreatorWithPayload,
  AnyAction,
  createAsyncThunk,
  PayloadAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import createSlice from '../util/createImmerlessSlice';
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast';
import { FolderItemORM, isCustomDeckORM } from 'src/types/types';
import { PresentableModelORM } from 'src/state/context/ContentProvider/ContentProvider';
import { v4 as uuid } from 'uuid';
import { getExtenalDependencies } from './sharedFolder';
import { getRootFolderORM } from '../selector/folder';
import { batch } from 'react-redux';
import authTokenSlice, { AccessTokenDataExtended } from './authToken';
import customDeckSlice from './customDeck';
import documentSlice from './document';
import documentVersionSlice from './documentVersion';
import { WritableDraft } from 'immer/dist/internal';
import { SharedFilesContextType } from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesProvider';
import { SidebarOptions } from 'src/components/ContentPreviewModalV2/SideBar/SideBar';

interface InitialMeetingContent {
  presentableModelORM: PresentableModelORM,
  page: number,
}

export interface ContentPreviewModalSliceType {
  isOpen: Boolean,
  documentVersionId?: string,
  folderItemORM?: FolderItemORM,
  isPublisherMode?: boolean,
  isFullWindow?: boolean,
  toast?: ToastActions,
  fromEllipsis?: boolean,
  documentHistoryVersionNavigation?: string[]
  content?: PresentableModelORM,
  presentationId?: string,
  isLoading?: boolean,
  meetingId?: string,
  initialMeetingContent?: InitialMeetingContent
  isReadOnly?: boolean,
  pageNumber?: number,
  hubId?: string,
  hubShareFileContext?: WritableDraft<SharedFilesContextType>,
  currentActiveNotationId?: string,
  openedTab?: SidebarOptions,
}

export interface ChangeDocumentType {
  documentVersionId?: string,
  addToHistory: boolean
}

export interface setOpenedTabType {
  openedTab?: SidebarOptions,
}

// [TODO-3445] - This is a copy of a Redux action `folder/getFolderExternalDependencies`
async function hasExternalDependencies(
  folderItemORM: FolderItemORM,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) {
  try {
    const rootFolder = folderItemORM.relations.parentORM
      ? getRootFolderORM(folderItemORM.relations.parentORM)
      : folderItemORM.relations.parentORM;

    if (
      rootFolder &&
        rootFolder.meta.hasExternalDependencies &&
        folderItemORM.relations.parentORM?.model.id &&
        isCustomDeckORM(folderItemORM.model)
    ) {
      const {
        customDecks, documents, documentVersions, accessTokens,
      } = await getExtenalDependencies(
        folderItemORM.relations.parentORM.model.id,
        rootFolder.model.id,
      );

      // [NOTE] - These do not insert proper DataStore records!!
      //        - That means you may run into an issue trying to save a record that is not available to you by default!
      batch((): void => {
        customDecks && dispatch(customDeckSlice.actions.insert(customDecks));
        documents && dispatch(documentSlice.actions.insert(documents));
        documentVersions && dispatch(documentVersionSlice.actions.insert(documentVersions));
        accessTokens && dispatch(authTokenSlice.actions.upsert(
          accessTokens?.map(accesToken => new AccessTokenDataExtended(accesToken)),
        ));
      });
    }
  }
  catch (e) {
    console.error('Error while fetching external dependencies', e);
  }
}

const setModalVisibility = createAsyncThunk(
  'contentPreviewModalState/setModalVisibility',
  async (
    payload: ContentPreviewModalSliceType,
    thunkAPI,
  ) => {
    const folderItemORM = payload.folderItemORM
    payload.isOpen &&
    folderItemORM &&
    await hasExternalDependencies(folderItemORM, thunkAPI.dispatch);
    return payload;
  },
)

const initialState: ContentPreviewModalSliceType = {
  isFullWindow: false,
  isOpen: false,
  documentHistoryVersionNavigation: [],
  presentationId: uuid(),
};

const contentPreviewModalSlice = createSlice({
  name: 'contentPreviewModalState',
  initialState: initialState,
  reducers: {
    setOpenedTab: (state, action: PayloadAction<setOpenedTabType>) => {
      const { payload } = action;
      const newState = { ...state }
      newState.openedTab = payload.openedTab;
      return newState;
    },
    changeDocument: (state, action: PayloadAction<ChangeDocumentType>) => {
      const { payload } = action;
      const newState = { ...state }
      if (payload.addToHistory && newState.documentVersionId) {
        newState.documentHistoryVersionNavigation = state.documentHistoryVersionNavigation ? [
          ...state.documentHistoryVersionNavigation,
          newState.documentVersionId,
        ] : [newState.documentVersionId]
      }
      newState.documentVersionId = payload.documentVersionId
      newState.isLoading = false
      return newState;
    },
    setIsFullWindow: (state, action: PayloadAction<boolean>) => {
      const newState = { ...state }
      newState.isFullWindow = action.payload
      newState.isLoading = false
      return newState;
    },
    removeFromHistory : (state, action : PayloadAction<boolean>) => {
      const newState = { ...state }
      // clean history
      if (action.payload) newState.documentHistoryVersionNavigation = [];
      else if (newState.documentHistoryVersionNavigation) {
        newState.documentHistoryVersionNavigation = state.documentHistoryVersionNavigation?.slice(0, -1)
      }
      newState.isLoading = false
      return newState;
    },
    setInitialContent: (state, action: PayloadAction<InitialMeetingContent | undefined>) => {
      const newState = { ...state }
      newState.initialMeetingContent = action.payload;
      return newState;
    },
  },
  extraReducers: {
    [setModalVisibility.pending.toString()]: (state, action) => {
      const newState = { ...state }
      newState.isOpen = true;
      newState.isLoading = true;
      newState.meetingId = action?.meta?.arg?.meetingId;
      newState.hubId = action?.meta?.arg?.hubId;
      return newState;
    },
    [setModalVisibility.rejected.toString()]: (state) => {
      const newState = { ...state }
      newState.isOpen = false;
      newState.isLoading = false;
      newState.meetingId = undefined;
      newState.hubId = undefined;
      return newState;
    },
    [setModalVisibility.fulfilled.toString()]: (state, action: PayloadAction<ContentPreviewModalSliceType>) => {
      const { payload } = action;
      const newState = { ...state }
      if (!payload.documentVersionId || !state.isOpen || !payload.isOpen) {
        newState.documentVersionId = undefined;
        newState.isOpen = false;
        newState.content = undefined;
        newState.documentHistoryVersionNavigation = [];
      } else {
        newState.documentVersionId = payload.documentVersionId;
        newState.isOpen = payload.isOpen;
        newState.isReadOnly = payload.isReadOnly;
        newState.content = payload.content;
        newState.folderItemORM = payload.folderItemORM;
        newState.isFullWindow = !!payload.isFullWindow;
        newState.toast = payload.toast;
        newState.fromEllipsis = !!payload.fromEllipsis;
        newState.meetingId = payload.meetingId;
        newState.hubId = payload.hubId;
        newState.hubShareFileContext = payload.hubShareFileContext;
        newState.pageNumber = payload.pageNumber;
        newState.currentActiveNotationId = payload.currentActiveNotationId;
        newState.openedTab = payload.openedTab;
      }

      newState.isLoading = false;
      return newState;
    },
  },
});

export default contentPreviewModalSlice;
export const contentPreviewModalActions = {
  // [TODO]: https://stackoverflow.com/questions/60049490/argument-of-type-thunkaction-is-not-assignable-to-parameter-of-type-anyaction
  setModalVisibility: setModalVisibility as unknown as ActionCreatorWithPayload<ContentPreviewModalSliceType, string>,
  ...contentPreviewModalSlice.actions,
}
