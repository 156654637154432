import { BroadcastChannel } from 'broadcast-channel';
import { PDFChannelMessage, PlayerMode, PPZTransform } from '@alucio/core'
import { RATIOS } from '@alucio/lux-ui/src/components/layout/DNAAspectRatio/DNAAspectRatio';
import { PresentationBroadcastContent } from '@alucio/core/lib/state/context/PresentationPlayer/types';
import { Coordinate } from '@alucio/aws-beacon-amplify/src/models';
import { NOTATION_TYPE } from '@alucio/aws-beacon-amplify/lib/API';

/**
 * ---------------------
 *  META
 * ---------------------
 */

export enum Tags {
  IS_IDLE = 'IS_IDLE',
  IS_CONTENT_LOADED = 'IS_CONTENT_LOADED',
}

/**
 * ---------------------
 *  STATE MACHINE
 * ---------------------
 */

export type PlayerWrapperContext = {
  aspectRatio: RATIOS,
  frameId: string,
  meetingId: string,
  openedIFrames: {
    // documentVersionId linked to an iframe
    [documentVersionId: string]: {
      iFrameId: string,
      aspectRatio?: number
    }
  }
  presentableState?: PresentationBroadcastContent,
  playerMode: PlayerMode,
  enableNew3PC: boolean,
  playerState?: PresentationBroadcastContent,
  presentationStateChannel: BroadcastChannel<PresentationChannelMessage>,
  presentationStateObserver?: any // [NOTE] - Should type but doesn't matter too much
  playerStateChannel: BroadcastChannel<PDFChannelMessage>,
  playerStateObserver?: any,
  messageNumber: number,
  shouldBroadcastPlayerMessages: boolean,
  lockAspectRatio: boolean,
  lastPresentedIframeId?: string,
}

// [NOTE] - Typed context for each states is a bit much, but doable
export type PlayerWrapperState = {
  value: {
    idle: {},
    presenting: {
      loading,
      loaded
    }
  },
  context: PlayerWrapperContext;
}

// Presentation Broadcast Channel Message Types
export type PresentationChannelMessage = PresentationStateSyncEvent | NavigatePastEvent |
  PPZTransformEvent | PlayerUIEvent | PresentationProgress | PresentationStateIdleEvent |
  SyncPresentableEvent | IframeReady | ToggleNotations | ToggleCoordinateMode | SendNotationCoordinate |
  SyncCurrentActiveNotation | ReloadDocument | SetEditNotation

export type PresentationStateSyncEvent = {
  type: 'PRESENTATION_STATE_SYNC',
  meetingId: string,
  payload: PresentationBroadcastContent,
  messageNumber: number,
  shouldUpdatePlayer?: boolean,
  isFromSameSource?: boolean
}

export type PresentationStateIdleEvent = {
  type: 'PRESENTATION_STATE_IDLE',
  meetingId: string,
}

export type NavigatePastEvent = {
  type: 'NAVIGATE_PAST_FIRST' | 'NAVIGATE_PAST_LAST'
  meetingId: string,
}

export type ReloadDocument = {
  type: 'RELOAD_DOCUMENT'
  meetingId: string,
  documentVersionId: string,
}

export type PlayerUIEvent = {
  type: 'SHOW_SEARCH' | 'SHOW_MY_CONTENT'
  meetingId: string,
}

export type PPZTransformEvent = {
  type: 'PPZ_TRANSFORM',
  meetingId: string,
  presentableGroupId: string,
  payload: PPZTransform
}

export type SyncPresentableEvent = {
  type: 'SYNC_PRESENTABLE',
  meetingId: string,
}

export type IframeReady = {
  type: 'IFRAME_READY',
  meetingId: string,
  messageNumber: number,
}

export type ToggleNotations = {
  type: 'TOGGLE_NOTATIONS',
  notationType?: NOTATION_TYPE,
  meetingId: string,
  toggleOn: boolean,
}

export type ToggleCoordinateMode = {
  type: 'TOGGLE_COORDINATE_MODE',
  meetingId: string,
  toggleOn: boolean,
}

export type SendNotationCoordinate = {
  type: 'SEND_NOTATION_COORDINATE',
  meetingId: string,
  coordinate?: Coordinate,
  pageId: string,
}

export type SyncCurrentActiveNotation = {
  type: 'SYNC_CURRENT_ACTIVE_NOTATION',
  meetingId: string,
  notationId?: string,
}

export type SetEditNotation = {
  type: 'SET_EDIT_NOTATION',
  meetingId: string,
  notationId: string,
}

export type PresentationProgress = {
  type: 'PRESENTATION_PROGRESS',
  meetingId: string,
  messageNumber: number,
  payload: {
    step: number,
    page: number,
    groupId: string,
    totalSteps: number,
  }
}

// State Machine Events
export type EVT_PRESENTATION_STATE_SYNC = {
  isFromSameSource?: boolean,
  type: 'PRESENTATION_STATE_SYNC',
  meetingId: string,
  payload: PresentationBroadcastContent
  messageNumber: number,
  shouldUpdatePlayer?: boolean,
}

export type EVT_PRESENTATION_STATE_IDLE = {
  type: 'PRESENTATION_STATE_IDLE',
  meetingId: string,
  messageNumber?: number,
}

export type EVT_PLAYER_ACTION = {
  type: 'PLAYER_ACTION',
  // [TODO] - Use the actual event names emitted from the player
  payload: PDFChannelMessage,
  messageNumber?: number,
}

export type EVT_RELOAD_WEB_DOC = {
  type: 'RELOAD_WEB_DOC',
  messageNumber?: number,
}

export type TOGGLE_HIGHLIGHTER = {
  type: 'TOGGLE_HIGHLIGHTER',
  highlighterVisible: boolean,
  messageNumber?: number,
}

export type EVT_SEND_IFRAME_READY = {
  type:'SEND_IFRAME_READY'
  messageNumber?: number,
}

export type EVT_TOGGLE_NOTATIONS = {
  type: 'TOGGLE_NOTATIONS',
  toggleOn: boolean,
  notationType?: NOTATION_TYPE,
  messageNumber?: number,
}

export type EVT_TOGGLE_COORDINATE_MODE = {
  type: 'TOGGLE_COORDINATE_MODE',
  toggleOn: boolean,
  messageNumber?: number,
}

export type EVT_SEND_NOTATION_COORDINATE = {
  type: 'SEND_NOTATION_COORDINATE'
  coordinate?: Coordinate,
  pageId: string,
  messageNumber?: number,
  frameId?: string,
}

export type EVT_SYNC_CURRENT_ACTIVE_NOTATION = {
  type: 'SYNC_CURRENT_ACTIVE_NOTATION'
  notationId?: string,
  messageNumber?: number,
  fromPlayer?: boolean,
  frameId?: string,
}

export type EVT_SET_EDIT_NOTATION = {
  type: 'SET_EDIT_NOTATION'
  notationId: string,
  messageNumber?: number,
  fromPlayer?: boolean,
  frameId?: string,
}

export type PlayerWrapperEvents =
  | EVT_PRESENTATION_STATE_SYNC
  | EVT_PLAYER_ACTION
  | EVT_PRESENTATION_STATE_IDLE
  | EVT_RELOAD_WEB_DOC
  | TOGGLE_HIGHLIGHTER
  | EVT_SEND_IFRAME_READY
  | EVT_TOGGLE_NOTATIONS
  | EVT_TOGGLE_COORDINATE_MODE
  | EVT_SEND_NOTATION_COORDINATE
  | EVT_SYNC_CURRENT_ACTIVE_NOTATION
  | EVT_SET_EDIT_NOTATION
